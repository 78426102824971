// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import WeekendIcon from '@mui/icons-material/Weekend';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import StoreIcon from '@mui/icons-material/Store';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NTTextEditor from "components/NTTextEditor";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';

function Dashboard() {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      return navigate('/login');
    }
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<WeekendIcon fontSize="medium" />}
                title="Bookings"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<LeaderboardIcon fontSize="medium" />}
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<StoreIcon fontSize="medium" />}
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<PersonAddIcon fontSize="medium" />}
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  mb: 1,
                  background: "#00000014",
                  boxShadow: 'unset'
                }}
              >
                <Typography variant="body1">
                  {`<Typography variant="NAME BELOW">Text</Typography>`}
                </Typography>

              </Paper>
              <Box sx={{ width: '100%', maxWidth: 500 }}>
                <Typography variant="h1" gutterBottom>
                  h1. Heading
                </Typography>
                <Typography variant="h2" gutterBottom>
                  h2. Heading
                </Typography>
                <Typography variant="h3" gutterBottom>
                  h3. Heading
                </Typography>
                <Typography variant="h4" gutterBottom>
                  h4. Heading
                </Typography>
                <Typography variant="h5" gutterBottom>
                  h5. Heading
                </Typography>
                <Typography variant="h6" gutterBottom>
                  h6. Heading
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="body1" gutterBottom>
                  body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="body2" gutterBottom>
                  body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
                  blanditiis tenetur
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                  button text
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  caption text
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                  overline text
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <MDBox mb={1.5}>
                <Typography variant="h5" mb={3}>Modal, Popup text format:</Typography>
                <Stack direction="row" spacing={2}>
                  <Typography variant="h6">Title: h6</Typography>
                  <Typography variant="body2">{`<Typography variant="h6">Title</Typography>`}</Typography>
                </Stack>
              </MDBox>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <MDBox mb={1.5}>
                <Typography variant="h5" mb={3}>Main Button Type and Color</Typography>
                <Stack direction="column" spacing={2}>
                  <Typography variant="body2" sx={{ color: '#0288d1', fontWeight: 500 }}>Add, Edit, Search (#0288d1)</Typography>
                  <Typography variant="body2" sx={{ color: '#7b809a', fontWeight: 500 }}>Cancel (#7b809a)</Typography>
                  <Typography variant="body2" sx={{ color: '#EF5350', fontWeight: 500 }}>Delete (#EF5350)</Typography>
                </Stack>
                <Stack direction="row" spacing={2} mt={3}>
                  <MDButton variant="contained" color="info">Add, Edit, Search</MDButton>
                  <MDButton variant="contained" color="cancel">Cancel</MDButton>
                  <MDButton variant="contained" color="error">Delete</MDButton>
                </Stack>
                <Stack direction="row" spacing={2} mt={3}>
                  <MDButton variant="outlined" color="info">Add, Edit, Search</MDButton>
                  <MDButton variant="outlined" color="cancel">Cancel</MDButton>
                  <MDButton variant="outlined" color="error">Delete</MDButton>
                </Stack>
              </MDBox>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <Typography variant="h5" mb={2}>Text Editor</Typography>
              <NTTextEditor />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <Typography variant="h5" mb={2}>Button Action Icon & Color</Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="body2">Button icon Delete</Typography>
                <IconButton color="error">
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography variant="body2">Button icon Edit</Typography>
                <IconButton color="info">
                  <FolderIcon />
                </IconButton>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <Typography variant="h5" mb={2}>Status Label</Typography>
              <Stack spacing={1} alignItems="center">
                <Stack direction="row" spacing={1}>
                  <Chip label="Active" color="info" size="small" />
                  <Chip label="Deactive" color="error" size="small" />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Chip label="Active" color="info" variant="outlined" size="small" />
                  <Chip label="Deactive" color="error" variant="outlined" size="small" />
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
