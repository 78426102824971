import Axios from "axios";

const createAxiosInstance = (configHeader) => {
    return Axios.create({
        headers: { ...configHeader }
    });
}

export async function getCheckToken(url, token = {}) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    });
    let request = await axios.get(url, {
        params: token
    });
    return request;
}

export async function getLogout(url) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    return await axios.get(url);
}

export async function post(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    return await axios.post(url, data);
}

export async function postBeforeLogin(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
    });
    return await axios.post(url, data);
}

export async function putUpdatePassword(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    });
    return await axios.put(url, data);
}