import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { getUserLogout } from "modules/master_login/repositories";

function Index() {
    const navigate = useNavigate();
    React.useEffect(() => {
        async function getLogout() {
            try {
                const logout = await getUserLogout();
                if (logout?.data?.response) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    if (!localStorage.getItem('access_token')) { return navigate('/login'); }
                }
            } catch (error) {
                console.log(error);
            }
        }
        getLogout();
    }, [navigate]);
}

export default Index;