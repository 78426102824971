
import * as React from 'react';
import { Paper, Grid, TextField, Box } from '@mui/material';
import MDBox from "components/MDBox";
import ListContact from 'modules/contact_crm/components/listContact';
import ModalContact from 'modules/contact_crm/components/modalDetail';
import MDButton from 'components/MDButton';

function Index() {
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [searchBox, setSearchBox] = React.useState('');

    const handleChangeSearch = event => {
        setSearchBox(event.target.value);
    };

    const handleSubmit = async (e) => {
        // setDisabledBtn(true);
        alert(searchBox)
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3} xl={2}>
                <MDBox>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <ModalContact />
                    </Paper>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={8} lg={9} xl={10} alignItems={'center'}>
                <MDBox>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <Box component="form" role="form">
                            <Grid container spacing={2} height={'100%'} alignItems={'center'}>
                                <Grid item xs={12} md={10} lg={10}>
                                    <TextField
                                        margin="none"
                                        fullWidth
                                        id="search"
                                        name="q"
                                        type="text"
                                        autoComplete="search"
                                        value={searchBox}
                                        onChange={handleChangeSearch}
                                        inputProps={{ style: { padding: "0.6rem 1rem" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <MDButton fullWidth variant="contained" color="info" disabled={disabledBtn} onClick={handleSubmit}>Search</MDButton>
                                </Grid>
                            </Grid>
                        </Box>

                    </Paper>
                </MDBox>
            </Grid>
            {/* Recent List */}
            <Grid item xs={12} md={12} lg={12} >
                <ListContact />
            </Grid>
        </Grid>
    )
}

export default Index;