import Axios from "axios";

const createAxiosInstance = (configHeader) => {
    return Axios.create({
        headers: { ...configHeader }
    });
}

export async function get(url, params = {}) {
    // return "tét";
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.get(url);
    return request;
}

export async function post(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.post(url, data);
    return request;
}

export async function put(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.put(url, data);
    return request;
}

export async function deleteRole(url) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.delete(url);
    return request;
}

export async function postMultipartForm(url, data) {
    const axios = createAxiosInstance({ headers: { "Content-Type": "multipart/form-data;boundary=---------------------------974767299852498929531610575" } });
    return await axios.post(url, data);
}

export async function postAuthenticate(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
    });
    return await axios.post(url, data);
}