

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { transparent, grey, inputBorderColorCustom } = colors;
const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: '#f0f0f09c',

      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: grey[600],
      },
      "& .MuiFormLabel-root.Mui-disabled": {
        color: grey[600]
      },
    },
  },
};

export default textField;
