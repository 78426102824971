import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { postCreateStaff } from 'modules/platform_user/repositories';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Roles from "modules/role_permissions/roles/repositories";

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: '100%',
};

const cssBodyModal = {
    pr: 4,
    pl: 1,
    py: 2,
    m: 0,
    height: {
        xs: '70%',
        md: '30%',
        lg: '30%'
    },
    overflow: 'hidden',
    overflowY: 'scroll'
};

function ModalStaff({ parentCallback }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true); getListRoles(); }
    const handleClose = () => setOpen(false);
    const swalModal = (type, message) => {
        return Swal.fire({
            icon: type,
            text: message,
            showConfirmButton: false,
            timer: 2000,
        });
    }
    const navigate = useNavigate();

    const [firstName,] = React.useState('');
    const [surName,] = React.useState('');
    const [userName,] = React.useState('');
    const [roles, setListRoles] = React.useState([]);
    const [role,] = React.useState(0);
    const [password,] = React.useState('');
    const [passwordConfirm,] = React.useState('');
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };

    async function getListRoles() {
        try {
            let data = await Roles.getListRole();
            const listRoles = [];
            listRoles.push({ id: 0, name: 'Select' });
            data?.data?.response.map(data => {
                return listRoles.push({ id: data.id, name: data.name });
            });
            return setListRoles(listRoles);
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("user");
                return navigate("/login");
            }
        }
    }

    const validate = values => {
        const errors = {};
        if (values.role === "Select role") {
            errors.role = 'Please change the Role';
        }
        if (!values.firstName) {
            errors.firstName = 'Please enter the First Name';
        } else if (values.firstName.length > 15) {
            errors.firstName = 'Must be 15 characters or less';
        } else if (!/^[A-Za-z. -]+$/i.test(values.firstName)) {
            errors.firstName = "Only accept letters, dots, dashes";
        }
        if (!values.surName) {
            errors.surName = 'Please enter the Surname';
        } else if (values.surName.length > 15) {
            errors.surName = 'Must be 15 characters or less';
        } else if (!/^[A-Za-z. -]+$/i.test(values.surName)) {
            errors.surName = "Only accept letters, dots, dashes";
        }
        if (!values.userName) {
            errors.userName = 'Please enter the Username';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userName)) {
            errors.userName = 'Please use your email address as username';
        }
        if (values.role === 0) {
            errors.role = 'Please select the Role';
        }
        if (!values.password) {
            errors.password = 'Please enter the Password';
        } else if (6 > values.password.length > 30) {
            errors.password = 'Must be maximum 30 characters';
        } else if (values.password.length < 6) {
            errors.password = 'Must be minimum 6 characters';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,30}$/.test(values.password)) {
            errors.password = 'Contain at least one uppercase letter, one lowercase letter, numbers, one special character';
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = 'Please enter the Password Confirm';
        } else if (values.passwordConfirm.length > 30) {
            errors.passwordConfirm = 'Must be maximum 30 characters';
        } else if (values.passwordConfirm.length < 6) {
            errors.passwordConfirm = 'Must be minimum 6 characters';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{6,30}$/.test(values.passwordConfirm)) {
            errors.passwordConfirm = 'Contain at least one uppercase letter, one lowercase letter, numbers, one special character';
        }
        if (password !== passwordConfirm) {
            errors.passwordConfirm = 'Password and Password Confirm not match. Please try again';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            firstName: firstName,
            surName: surName,
            userName: userName,
            role: role,
            password: password,
            passwordConfirm: passwordConfirm,
        },
        validate,
        onSubmit: async values => {
            const data = {
                firstname: values.firstName,
                surname: values.surName,
                email: values.userName,
                role: values.role,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            }
            try {
                setDisabledBtn(true);
                const dataResponse = await postCreateStaff(data);
                if (dataResponse?.data?.response) {
                    setDisabledBtn(false);
                    handleClose();
                    parentCallback(dataResponse?.data?.meta?.status);
                    swalModal('success', 'Create user success');
                }
            } catch (error) {
                if (error) {
                    setDisabledBtn(false);
                }
                if (error?.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    return navigate('/login');
                }
            }
        },
    });

    return (
        <div>
            <MDButton fullWidth variant="contained" color="info" sx={{ fontWeight: 500, width: 'max-content' }} onClick={handleOpen}>
                Add User
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ height: '100%', width: '70%', left: 'unset' }}
            >
                <Box sx={style} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2">
                                    User
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={cssBodyModal} justifyContent="space-around">
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="First Name (*)"
                                id="firstName"
                                name="firstName"
                                value={formik.values.firstName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="Surname(*)"
                                id="surName"
                                name="surName"
                                value={formik.values.surName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.surName && Boolean(formik.errors.surName)}
                                helperText={formik.touched.surName && formik.errors.surName}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="Userame(*)"
                                id="userName"
                                name="userName"
                                value={formik.values.userName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.userName && Boolean(formik.errors.userName)}
                                helperText={formik.touched.userName && formik.errors.userName}
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="Role(*)"
                                id="role"
                                name="role"
                                autoComplete="role"
                                select
                                value={formik.values.role}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                                helperText={formik.touched.role && formik.errors.role}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                {roles.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="Password(*)"
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <TextField
                                fullWidth
                                label="Password Confirm(*)"
                                id="passwordConfirm"
                                name="passwordConfirm"
                                type={showPasswordConfirm ? 'text' : 'password'}
                                value={formik.values.passwordConfirm}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                                helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPasswordConfirm}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container px={4} py={3} justifyContent="flex-end">
                        <Grid item xs={12} md={3} lg={2}>
                            <MDButton type="submit" fullWidth variant="contained" color="info" sx={{ fontWeight: 500 }} disabled={disabledBtn}>
                                Save
                            </MDButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default ModalStaff;