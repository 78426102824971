import * as MuiIcons from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useFormik } from "formik";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createSolution, updateSolution } from "../repositories";
import ModalDeletePlan from "./modalDeletePlan";
import ModalDetailPlan from "./modalDetailPlan";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Create Date", accessor: "created_at", align: "left" },
  { Header: "action", accessor: "action", align: "center" },
];

function ModalDetailSolution({ solution, parentCallback, menu = [] }) {
  const [open, setOpen] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const swalModal = (type, message) => {
    return Swal.fire({
      icon: type,
      text: message,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Please enter the name";
    } else if (!/^[A-Za-z0-9 _]+$/i.test(values.name)) {
      errors.name = "Accept numbers and letters";
    }
    if (!values.url) {
      errors.url = "Please enter the URL";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: solution?.name || "",
      url: solution?.url || "",
      features: solution?.features?.map(({ id }) => id) || [],
    },
    validate,
    onSubmit: async (values) => {
      try {
        setDisabledBtn(true);
        if (solution) {
          const dataRes = await updateSolution(solution.id, values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Update solution success");
          }
        } else {
          const dataRes = await createSolution(values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Create solution success");
          }
        }
      } catch (e) {
        setDisabledBtn(false);
        if (e?.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          return navigate("/login");
        }
      }
    },
  });

  const plansDataTable = React.useMemo(
    () =>
      (solution?.plans || []).map((plan) => ({
        ...plan,
        created_at: moment(plan.created_at).format("DD-MMM-YYYY"),
        action: (
          <Grid container spacing={2}>
            <Grid item>
              <ModalDetailPlan
                solution={solution}
                plan={plan}
                menu={menu}
                parentCallback={parentCallback}
              />
            </Grid>
            <Grid item>
              <ModalDeletePlan id={plan.id} parentCallback={parentCallback} />
            </Grid>
          </Grid>
        ),
      })),
    [menu, parentCallback, solution]
  );

  const flatMenuID = React.useCallback((menu, handleCompare) => {
    return menu.reduce((prev, { child, id }) => {
      if (handleCompare(id)) prev.push(id);
      if (child.length) prev.push(...flatMenuID(child, handleCompare));
      return prev;
    }, []);
  }, []);

  const renderMenuItem = React.useCallback(
    (menuItem, lever = 0, parents = []) => {
      return (
        <Box key={menuItem.id}>
          <FormControlLabel
            sx={{
              marginLeft: 4 * lever,
              display: "flex",
              alignItems: "center",
            }}
            control={<Checkbox />}
            labelPlacement="end"
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {MuiIcons[menuItem.icon] && (
                  <Box
                    sx={{
                      mr: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {React.createElement(MuiIcons[menuItem.icon], {})}
                  </Box>
                )}
                <Typography sx={{ flexGrow: 1 }} variant="body2">
                  {menuItem.name}
                </Typography>
              </Box>
            }
            name="features"
            value={menuItem.id}
            checked={!!formik.values.features.includes(menuItem.id)}
            onChange={(_, checked) => {
              if (checked) {
                const menuItemParents = parents.filter(
                  (parent) => !formik.values.features.includes(parent)
                );
                const menuItemChilds = [];

                if (menuItem.child.length) {
                  menuItemChilds.push(
                    ...flatMenuID(
                      menuItem.child,
                      (id) => !formik.values.features.includes(id)
                    )
                  );
                }

                formik.setFieldValue("features", [
                  ...formik.values.features,
                  menuItem.id,
                  ...menuItemParents,
                  ...menuItemChilds,
                ]);
              } else {
                const currentFeatures = formik.values.features;

                const menuItemChilds = [];

                if (menuItem.child.length) {
                  menuItemChilds.push(
                    ...flatMenuID(menuItem.child, (id) =>
                      formik.values.features.includes(id)
                    )
                  );
                }

                [...menuItemChilds, menuItem.id]
                  .map((id) => formik.values.features.indexOf(id))
                  .sort((a, b) => b - a)
                  .forEach((index) => {
                    currentFeatures.splice(index, 1);
                  });

                formik.setFieldValue("features", currentFeatures);
              }
            }}
          />
          {!!menuItem.child.length &&
            menuItem.child.map((menuChildItem) =>
              renderMenuItem(menuChildItem, lever + 1, [
                ...parents,
                menuItem.id,
              ])
            )}
        </Box>
      );
    },
    [flatMenuID, formik]
  );

  return (
    <div>
      {solution ? (
        <IconButton color="info" onClick={handleOpen}>
          <FolderIcon />
        </IconButton>
      ) : (
        <MDButton
          fullWidth
          variant="contained"
          color="info"
          sx={{ fontWeight: 500, width: "max-content" }}
          onClick={handleOpen}
        >
          Add Solution
        </MDButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ height: "100vh", width: "80vw", left: "unset" }}
      >
        <Box
          sx={{
            bgcolor: "#efecec",
            boxShadow: 24,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            px={3}
            py={2}
            bgcolor={"#3c8dbc"}
            justifyContent="space-around"
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  color={"#fff"}
                  component="h2"
                >
                  Solution
                </Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ flex: 1, overflow: "auto", p: 2 }}>
            <Grid item xs={5} sx={{ height: "100%", overflow: "auto" }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: "0.75rem",
                  boxShadow: "unset",
                  display: "flex",
                  // flexDirection: "column",
                  overflow: "hidden",
                  height: "100%",
                }}
              >
                <Grid
                  justifyContent={"center"}
                  sx={{
                    mt: 2,
                    mb: 2,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    fullWidth
                    label="Name(*)"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    label="URL(*)"
                    id="url"
                    name="url"
                    value={formik.values.url}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ mt: 2, mb: 2 }}
                  />
                  <Grid sx={{ flex: 1, overflow: "auto" }}>
                    {menu.map((menuItem) => renderMenuItem(menuItem))}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {solution && (
              <Grid item xs={7}>
                <Paper
                  sx={{ p: 2, borderRadius: "0.75rem", boxShadow: "unset" }}
                >
                  <ModalDetailPlan
                    solution={solution}
                    menu={menu}
                    parentCallback={parentCallback}
                  />
                  <DataTable
                    table={{ columns: COLUMNS, rows: plansDataTable }}
                    isSorted={true}
                    canSearch={true}
                    noEndBorder
                  />
                </Paper>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            px={4}
            py={2}
            justifyContent="flex-end"
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0 0px 5px -2px gray!important",
            }}
          >
            <Grid item xs={12} md={3} lg={2}>
              <MDButton
                type="submit"
                fullWidth
                variant="contained"
                color="info"
                sx={{ fontWeight: 500 }}
                disabled={disabledBtn}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalDetailSolution;
