import * as api from 'modules/platform_user/xhr';
import endPoint from "modules/platform_user/xhr/endPoint";
import { getURL } from "modules/platform_user/xhr/endPoint";

export const getListStaff = async () => {
    return await api.get(getURL(endPoint.PLATFORM_USER));
}

export const postCreateStaff = async data => {
    return await api.post(getURL(endPoint.PLATFORM_USER_STORE), data);
}

export const putUpdateStaff = async (id, data) => {
    return await api.put(getURL(endPoint.CONTACT_UPDATE + id), data);
}

export const putDeactiveStaff = async (id) => {
    return await api.put(getURL(endPoint.PLATFORM_USER_DEACTIVE + id));
}

export const putActiveStaff = async (id) => {
    return await api.put(getURL(endPoint.PLATFORM_USER_ACTIVE + id));
}

export const putChangeRole = async (id, role) => {
    return await api.put(getURL(endPoint.PLATFORM_USER_CHANGE_ROLE + id + '/' + role));
}

export const deleteStaff = async (id) => {
    return await api.deleteStaff(getURL(endPoint.PLATFORM_USER_DELETE + id));
}