import * as MuiIcons from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createPlan, updatePlan } from "../repositories";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
  minWidth: 650,
};

function ModalDetailPlan({ plan, solution, menu, parentCallback }) {
  const [open, setOpen] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const swalModal = (type, message) => {
    return Swal.fire({
      icon: type,
      text: message,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Please enter the name";
    } else if (!/^[A-Za-z0-9 _]+$/i.test(values.name)) {
      errors.name = "Accept numbers and letters";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      solution_id: solution.id,
      name: plan?.name || "",
      features: plan?.features?.map(({ id }) => id) || [],
    },
    validate,
    onSubmit: async (values) => {
      try {
        setDisabledBtn(true);
        if (plan) {
          const dataRes = await updatePlan(plan.id, values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Update plan success");
          }
        } else {
          const dataRes = await createPlan(values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Create plan success");
          }
        }
      } catch (e) {
        setDisabledBtn(false);
        if (e?.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          return navigate("/login");
        }
      }
    },
  });

  const flatMenuID = React.useCallback((menu, handleCompare) => {
    return menu.reduce((prev, { child, id }) => {
      if (handleCompare(id)) prev.push(id);
      if (child.length) prev.push(...flatMenuID(child, handleCompare));
      return prev;
    }, []);
  }, []);

  const renderMenuItem = React.useCallback(
    (menuItem, lever = 0, parents = []) => {
      return (
        <Box key={menuItem.id}>
          <FormControlLabel
            sx={{
              marginLeft: 4 * lever,
              display: "flex",
              alignItems: "center",
            }}
            control={<Checkbox />}
            labelPlacement="end"
            label={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {MuiIcons[menuItem.icon] && (
                  <Box
                    sx={{
                      mr: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {React.createElement(MuiIcons[menuItem.icon], {})}
                  </Box>
                )}
                <Typography sx={{ flexGrow: 1 }} variant="body2">
                  {menuItem.name}
                </Typography>
              </Box>
            }
            name="features"
            value={menuItem.id}
            checked={!!formik.values.features.includes(menuItem.id)}
            onChange={(_, checked) => {
              if (checked) {
                const menuItemParents = parents.filter(
                  (parent) => !formik.values.features.includes(parent)
                );
                const menuItemChilds = [];

                if (menuItem.child.length) {
                  menuItemChilds.push(
                    ...flatMenuID(
                      menuItem.child,
                      (id) => !formik.values.features.includes(id)
                    )
                  );
                }

                formik.setFieldValue("features", [
                  ...formik.values.features,
                  menuItem.id,
                  ...menuItemParents,
                  ...menuItemChilds,
                ]);
              } else {
                const currentFeatures = formik.values.features;

                const menuItemChilds = [];

                if (menuItem.child.length) {
                  menuItemChilds.push(
                    ...flatMenuID(menuItem.child, (id) =>
                      formik.values.features.includes(id)
                    )
                  );
                }

                [...menuItemChilds, menuItem.id]
                  .map((id) => formik.values.features.indexOf(id))
                  .sort((a, b) => b - a)
                  .forEach((index) => {
                    currentFeatures.splice(index, 1);
                  });

                formik.setFieldValue("features", currentFeatures);
              }
            }}
          />
          {!!menuItem.child.length &&
            menuItem.child
              .filter(
                (menuItemChild) =>
                  solution.features.findIndex(
                    (feature) => feature.id === menuItemChild.id
                  ) >= 0
              )
              .map((menuChildItem) =>
                renderMenuItem(menuChildItem, lever + 1, [
                  ...parents,
                  menuItem.id,
                ])
              )}
        </Box>
      );
    },
    [flatMenuID, formik, solution.features]
  );

  return (
    <div>
      {plan ? (
        <IconButton color="info" onClick={handleOpen}>
          <FolderIcon />
        </IconButton>
      ) : (
        <MDButton
          fullWidth
          variant="contained"
          color="info"
          sx={{ fontWeight: 500, width: "max-content" }}
          onClick={handleOpen}
        >
          Add Plan
        </MDButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h4" component="h2">
              Plan
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            justifyContent={"center"}
            sx={{
              mt: 2,
              mb: 2,
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Name(*)"
                id="name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {menu && (
              <Grid sx={{ flex: 1, overflow: "auto" }}>
                {menu
                  .filter(
                    (menuItem) =>
                      solution.features.findIndex(
                        (feature) => feature.id === menuItem.id
                      ) >= 0
                  )
                  .map((menuItem) => renderMenuItem(menuItem))}
              </Grid>
            )}
          </Grid>

          <Grid container pt={3} justifyContent="center">
            <Grid item xs={12} md={3} lg={2}>
              <MDButton
                type="submit"
                fullWidth
                variant="contained"
                color="info"
                sx={{ fontWeight: 500 }}
                disabled={disabledBtn}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalDetailPlan;
