import React from 'react'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Contact from 'modules/company_crm/pages';
import MDBox from "components/MDBox";
import Footer from "examples/Footer";

function Company() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Contact />
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Company