import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MDButton from 'components/MDButton';
import { useParams, useNavigate } from 'react-router-dom';
import { getCheckToken, putUpdatePassword } from 'modules/master_login/repositories';
import Swal from "sweetalert2";
import { Grid, InputAdornment, Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const cssInput = {
    marginTop: 1,
    marginBottom: 1,
    borderRadius: '20px',
    '& > label': {
        top: 0,
        left: 0,
        color: 'gray',
        '&[data-shrink="false"]': {
            top: 5
        }
    },
    '& > div > input': {
        padding: '20px !important'
    },
    '& legend': {
        display: 'none'
    },
    '& fieldset': {
        top: 0
    }
};

function FormForgotPassword() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    // const [errPW, setErrPW] = React.useState(false);
    // const [txtErrPW, setTxtErrPW] = React.useState('');
    // const [errCPW, setErrCPW] = React.useState(false);
    // const [txtErrCPW, setTxtErrCPW] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [nextToLogin, setNextToLogin] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };
    const swalModal = (type, message) => {
        return Swal.fire({
            type: type,
            text: message,
            showConfirmButton: true,
            // timer: 2000
        });
    }

    React.useEffect(() => {
        async function checkToken() {
            try {
                const data = {
                    token: token,
                }
                await getCheckToken(data);
            } catch (error) {
                if (error?.response?.status === 422) {
                    return navigate('/login');
                }
            }
        }
        checkToken();
    }, [navigate, token]);

    const handleBackToLogin = (e) => {
        e.preventDefault();
        return navigate('/login');
    }

    const validate = values => {
        const errors = {};

        if (!values.password) {
            errors.password = 'Required';
        } else if (values.password.length < 8) {
            errors.password = 'Password must be 8 characters long';
        } else if (!/[0-9]/.test(values.password)) {
            errors.password = 'Password requires a number';
        } else if (!/[a-z]/.test(values.password)) {
            errors.password = 'Password requires a lowercase letter';
        } else if (!/[A-Z]/.test(values.password)) {
            errors.password = 'Password requires a uppercase letter';
        } else if (!/[^\w]/.test(values.password)) {
            errors.password = 'Password requires a symbol';
        }
        if (!values.passwordConfirm) {
            errors.passwordConfirm = 'Required';
        } else if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = 'Password confirm not match';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: '',
        },
        validate,
        onSubmit: async values => {
            const data = {
                token: token,
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            }
            try {
                const dataRes = await putUpdatePassword(data);
                if (dataRes?.data?.response) {
                    setDisabledBtn(false);
                    setNextToLogin(true)
                }
            } catch (error) {
                if (error?.response?.status === 500) {
                    setDisabledBtn(false);
                    swalModal('error', 'Internal Server Error');
                }
                setDisabledBtn(false);
            }
        }
    })


    return (
        <>
            {!nextToLogin
                ?
                (
                    <Box component="form" role="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }} width={"100%"}>
                        <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, margin: 'auto' }} justifyContent="space-around">
                            <Grid item xs={12}>
                                <Stack spacing={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#000'
                                        }}
                                        gutterBottom
                                    >
                                        Password*
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    // error={errPW}
                                    // helperText={txtErrPW}
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    // onChange={(e) => setPassword(e.target.value)}
                                    value={formik.values.password}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    sx={cssInput}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={2}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            color: '#000'
                                        }}
                                        gutterBottom
                                    >
                                        Password Confirm*
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    // error={errCPW}
                                    // helperText={txtErrCPW}
                                    margin="normal"
                                    fullWidth
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    // onChange={(e) => setPassword(e.target.value)}
                                    value={formik.values.passwordConfirm}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                                    helperText={formik.touched.passwordConfirm && formik.errors.passwordConfirm}
                                    sx={cssInput}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPasswordConfirm}
                                                    onMouseDown={handleMouseDownPasswordConfirm}
                                                    edge="end"
                                                >
                                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, justifyContent: { xs: 'space-around', md: 'space-around', lg: 'space-around' }, margin: 'auto', mt: 1, mb: 3 }}>
                            <Grid item>
                                <MDButton type="submit" variant="contained" color="success" sx={{ width: '7rem', mb: 1, mr: 2 }} disabled={disabledBtn}>
                                    Submit
                                </MDButton>

                            </Grid>
                            <Grid item alignSelf={'center'}>
                                <MDButton onClick={handleBackToLogin} sx={{ fontSize: '16px', fontWeight: 500 }}>
                                    Back to login
                                </MDButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={openAlert}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mt: 2, fontSize: 14 }}
                                    variant="filled" severity="error"
                                >
                                    Email or password is incorrect, please try again
                                </Alert>
                            </Collapse>
                        </Grid>
                    </Box >
                )
                :
                (
                    <>
                        <Typography mb={2} variant='h5'>You have successfully changed your password !</Typography>
                        <MDButton
                            component="a"
                            variant="contained"
                            color="info"
                            href={'/login'}
                        >Back to login</MDButton>
                    </>

                )
            }

        </>

    );
}
export default FormForgotPassword;