import { Grid} from '@mui/material';
import LayoutLeft from 'modules/master_login/components/layoutLeft';
import LayoutRight from 'modules/master_login/components/layoutRight';

function Index() {
    return (
        <Grid container component="main" sx={{ height: '100vh', backgroundColor: '#246ba2' }}>
            <LayoutLeft />
            <LayoutRight />
        </Grid>
    );
}

export default Index;