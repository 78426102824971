import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteSolution } from "../repositories";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ModalDeleteSolution({ id, parentCallback }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [disabledBtn, setDisabledBtn] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisabledBtn(true);
      const dataRes = await deleteSolution(id);
      if (dataRes?.data?.response) {
        setDisabledBtn(false);
        handleClose();
        parentCallback(dataRes?.data?.meta?.status);
        Swal.fire({
          icon: "success",
          text: "Delete solution success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      setDisabledBtn(false);
      if (e?.response?.status === 401) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        return navigate("/login");
      }
    }
  };

  return (
    <div>
      <IconButton color="error" onClick={handleOpen}>
        <DeleteOutlineIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" role="form" onSubmit={handleSubmit}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Are you sure you want to delete?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            textAlign={"center"}
          >
            WARNING: You won't be able to revert this!
          </Typography>
          <Grid container justifyContent={"center"} spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <MDButton
                type="submit"
                fullWidth
                variant="contained"
                color="error"
                sx={{ fontWeight: 500 }}
                disabled={disabledBtn}
              >
                Yes, Delete it!
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton
                fullWidth
                variant="contained"
                color="cancel"
                sx={{ fontWeight: 500 }}
                onClick={handleClose}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalDeleteSolution;
