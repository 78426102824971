import { Grid } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import ModalDetailSolution from "../components/modalDetailSolution";
import ModalDeleteSolution from "../components/modalDeleteSolution";
import { getSolutions } from "../repositories";
import { getMenu } from "modules/menu_manage/repositories";

const useSolutionPlan = (props) => {
  const [solutions, setSolutions] = useState([]);
  const [menu, setMenu] = useState([]);

  const handleGetSolutions = useCallback(async () => {
    const data = await getSolutions();
    setSolutions(data?.data?.response);
  }, []);

  const handleGetMenu = useCallback(async () => {
    try {
      const data = await getMenu();
      setMenu(Array.isArray(data?.data?.response) ? data?.data?.response : []);
    } catch (error) {}
  }, []);

  const handleReloadSolutions = useCallback(
    (status) => {
      if (status === 200) handleGetSolutions();
    },
    [handleGetSolutions]
  );

  useEffect(() => {
    handleGetSolutions();
    handleGetMenu();
  }, [handleGetMenu, handleGetSolutions]);

  const solutionsDataTable = useMemo(
    () =>
      solutions.map((solution) => ({
        ...solution,
        created_at: moment(solution.created_at).format("DD-MMM-YYYY"),
        action: (
          <Grid container spacing={2}>
            <Grid item>
              <ModalDetailSolution
                solution={solution}
                menu={menu}
                parentCallback={handleReloadSolutions}
              />
            </Grid>
            <Grid item>
              <ModalDeleteSolution
                id={solution.id}
                parentCallback={handleReloadSolutions}
              />
            </Grid>
          </Grid>
        ),
      })),
    [handleReloadSolutions, menu, solutions]
  );

  return {
    ...props,
    menu,
    solutionsDataTable,
    handleReloadSolutions,
  };
};

export default useSolutionPlan;
