import { Grid, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import ModalDetailSolution from "../components/modalDetailSolution";
import useSolutionPlan from "./hook";

const COLUMNS = [
  { Header: "Name", accessor: "name", align: "left" },
  { Header: "Create Date", accessor: "created_at", align: "left" },
  { Header: "action", accessor: "action", align: "center" },
];

const SolutionPlanLayout = ({
  menu,
  solutionsDataTable,
  handleReloadSolutions,
}) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "0.75rem",
                boxShadow: "unset",
              }}
            >
              <ModalDetailSolution
ƒ                menu={menu}
                parentCallback={handleReloadSolutions}
              />
              <DataTable
                table={{ columns: COLUMNS, rows: solutionsDataTable }}
                isSorted={true}
                canSearch={true}
                noEndBorder
              />
            </Paper>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const SolutionPlan = (props) => (
  <SolutionPlanLayout {...useSolutionPlan(props)} />
);

export default SolutionPlan;
