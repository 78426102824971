import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
  MENU: "menu",
  MENU_STORE: "menu/store",
  MENU_UPDATE: "menu/update/",
  MENU_DELETE: "menu/delete/",
  MENU_UPDATE_LIST: "menu/update-list",
  MENU_UPDATE_POSITION: "menu/update-position/",
};

export const getURL = (name, routeParams = {}, queryParam = {}) => {
  const pattern = new UrlPattern(`${name}`);
  let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
  let mappingParams = new URLSearchParams();
  return `${url.origin}${url.pathname}?${mappingParams}`;
};

export default endPoint;
