import { Box } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";

const DropZone = ({ dropZoneData, onDrop, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "menu",
    drop: (item, monitor) => {
      onDrop(dropZoneData, item);
    },
    canDrop: (menuData, monitor) => {
      if (
        menuData.lever < dropZoneData.lever &&
        dropZoneData.parentPath.startsWith(
          menuData.currentPath.slice(0, dropZoneData.parentPath.length)
        )
      ) {
        return false;
      }
      if (
        menuData.lever === dropZoneData.lever &&
        (menuData.index === dropZoneData.index ||
          menuData.index === dropZoneData.index - 1) &&
        dropZoneData.parentPath.startsWith(
          menuData.currentPath.slice(0, dropZoneData.parentPath.length)
        )
      ) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <>
      {children ? (
        <Box
          ref={drop}
          sx={{
            ...(isActive
              ? { background: "#0288d1", borderRadius: 2, transition: 0.2 }
              : {}),
          }}
        >
          {children}
        </Box>
      ) : (
        <Box
          ref={drop}
          sx={{
            height: 10,
            marginLeft: 4 * dropZoneData.lever,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flex: 1,
              height: 4,
              ...(isActive
                ? { background: "#0288d1", borderRadius: 2, transition: 0.2 }
                : {}),
            }}
          ></Box>
        </Box>
      )}
    </>
  );
};
export default DropZone;
