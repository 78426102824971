import React from 'react'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ContactCRM from 'modules/contact_crm/pages';
import MDBox from "components/MDBox";
import Footer from "examples/Footer";

function Contact() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <ContactCRM />
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Contact