import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    'PLATFORM_USER': 'staff',
    'PLATFORM_USER_STORE': 'staff/store',
    'PLATFORM_USER_DEACTIVE': 'staff/deactive/',
    'PLATFORM_USER_ACTIVE': 'staff/active/',
    'PLATFORM_USER_CHANGE_ROLE': 'staff/change-role/',
    'PLATFORM_USER_DELETE': 'staff/delete/',
}

export const getURL = (name, routeParams = {}, queryParam = {}) => {
    const pattern = new UrlPattern(`${name}`);
    let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
    let mappingParams = new URLSearchParams();
    return `${url.origin}${url.pathname}?${mappingParams}`;
}

export default endPoint;
