// eslint-disable-next-line import/no-anonymous-default-export
export default {
  countryPhonePrefixCode: [
    { value: '61', label: '(+61) Australia ' },
    { value: '44', label: '(+44) UK' },
    { value: '1', label: '(+1) USA, Canada' },
    { value: '213', label: '(+213) Algeria' },
    { value: '376', label: '(+376) Andorra' },
    { value: '244', label: '(+244) Angola' },
    { value: '1264', label: '(+1264) Anguilla' },
    { value: '1268', label: '(+1268) Antigua & Barbuda ' },
    { value: '54', label: '(+54) Argentina ' },
    { value: '374', label: '(+374) Armenia ' },
    { value: '297', label: '(+297) Aruba ' },
    { value: '43', label: '(+43) Austria ' },
    { value: '994', label: '(+994) Azerbaijan ' },
    { value: '1242', label: '(+1242) Bahamas ' },
    { value: '973', label: '(+973) Bahrain ' },
    { value: '880', label: '(+880) Bangladesh ' },
    { value: '1246', label: '(+1246) Barbados ' },
    { value: '375', label: '(+375) Belarus ' },
    { value: '32', label: '(+32) Belgium ' },
    { value: '501', label: '(+501) Belize ' },
    { value: '229', label: '(+229) Benin ' },
    { value: '1441', label: '(+1441) Bermuda ' },
    { value: '975', label: '(+975) Bhutan ' },
    { value: '591', label: '(+591) Bolivia ' },
    { value: '387', label: '(+387) Bosnia Herzegovina ' },
    { value: '267', label: '(+267) Botswana ' },
    { value: '55', label: '(+55) Brazil ' },
    { value: '673', label: '(+673) Brunei ' },
    { value: '359', label: '(+359) Bulgaria ' },
    { value: '226', label: '(+226) Burkina Faso ' },
    { value: '257', label: '(+257) Burundi ' },
    { value: '855', label: '(+855) Cambodia ' },
    { value: '237', label: '(+237) Cameroon ' },
    { value: '238', label: '(+238) Cape Verde Islands ' },
    { value: '1345', label: '(+1345) Cayman Islands ' },
    { value: '236', label: '(+236) Central African Republic ' },
    { value: '56', label: '(+56) Chile ' },
    { value: '86', label: '(+86) China ' },
    { value: '57', label: '(+57) Colombia ' },
    { value: '242', label: '(+242) Congo ' },
    { value: '682', label: '(+682) Cook Islands ' },
    { value: '506', label: '(+506) Costa Rica ' },
    { value: '385', label: '(+385) Croatia ' },
    { value: '53', label: '(+53) Cuba ' },
    { value: '90392', label: '(+90392) Cyprus North ' },
    { value: '357', label: '(+357) Cyprus South ' },
    { value: '42', label: '(+42) Czech Republic ' },
    { value: '45', label: '(+45) Denmark ' },
    { value: '253', label: '(+253) Djibouti ' },
    { value: '1809', label: '(+1809) Dominica, Dominican Republic ' },
    { value: '593', label: '(+593) Ecuador ' },
    { value: '20', label: '(+20) Egypt ' },
    { value: '503', label: '(+503) El Salvador ' },
    { value: '240', label: '(+240) Equatorial Guinea ' },
    { value: '291', label: '(+291) Eritrea ' },
    { value: '372', label: '(+372) Estonia ' },
    { value: '251', label: '(+251) Ethiopia ' },
    { value: '500', label: '(+500) Falkland Islands ' },
    { value: '298', label: '(+298) Faroe Islands ' },
    { value: '679', label: '(+679) Fiji ' },
    { value: '358', label: '(+358) Finland ' },
    { value: '33', label: '(+33) France ' },
    { value: '594', label: '(+594) French Guiana ' },
    { value: '689', label: '(+689) French Polynesia ' },
    { value: '241', label: '(+241) Gabon ' },
    { value: '220', label: '(+220) Gambia ' },
    { value: '7880', label: '(+7880) Georgia ' },
    { value: '49', label: '(+49) Germany ' },
    { value: '233', label: '(+233) Ghana ' },
    { value: '350', label: '(+350) Gibraltar ' },
    { value: '30', label: '(+30) Greece ' },
    { value: '299', label: '(+299) Greenland ' },
    { value: '1473', label: '(+1473) Grenada ' },
    { value: '590', label: '(+590) Guadeloupe ' },
    { value: '671', label: '(+671) Guam ' },
    { value: '502', label: '(+502) Guatemala ' },
    { value: '224', label: '(+224) Guinea ' },
    { value: '245', label: '(+245) Guinea - Bissau ' },
    { value: '592', label: '(+592) Guyana ' },
    { value: '509', label: '(+509) Haiti ' },
    { value: '504', label: '(+504) Honduras ' },
    { value: '852', label: '(+852) Hong Kong ' },
    { value: '36', label: '(+36) Hungary ' },
    { value: '354', label: '(+354) Iceland ' },
    { value: '91', label: '(+91) India ' },
    { value: '62', label: '(+62) Indonesia ' },
    { value: '98', label: '(+98) Iran ' },
    { value: '964', label: '(+964) Iraq ' },
    { value: '353', label: '(+353) Ireland ' },
    { value: '972', label: '(+972) Israel ' },
    { value: '39', label: '(+39) Italy ' },
    { value: '1876', label: '(+1876) Jamaica ' },
    { value: '81', label: '(+81) Japan ' },
    { value: '962', label: '(+962) Jordan ' },
    { value: '254', label: '(+254) Kenya ' },
    { value: '686', label: '(+686) Kiribati ' },
    { value: '850', label: '(+850) Korea North ' },
    { value: '82', label: '(+82) Korea South ' },
    { value: '965', label: '(+965) Kuwait ' },
    { value: '996', label: '(+996) Kyrgyzstan ' },
    { value: '856', label: '(+856) Laos ' },
    { value: '371', label: '(+371) Latvia ' },
    { value: '961', label: '(+961) Lebanon ' },
    { value: '266', label: '(+266) Lesotho ' },
    { value: '231', label: '(+231) Liberia ' },
    { value: '218', label: '(+218) Libya ' },
    { value: '417', label: '(+417) Liechtenstein ' },
    { value: '370', label: '(+370) Lithuania ' },
    { value: '352', label: '(+352) Luxembourg ' },
    { value: '853', label: '(+853) Macao ' },
    { value: '389', label: '(+389) Macedonia ' },
    { value: '261', label: '(+261) Madagascar ' },
    { value: '265', label: '(+265) Malawi ' },
    { value: '60', label: '(+60) Malaysia ' },
    { value: '960', label: '(+960) Maldives ' },
    { value: '223', label: '(+223) Mali ' },
    { value: '356', label: '(+356) Malta ' },
    { value: '692', label: '(+692) Marshall Islands ' },
    { value: '596', label: '(+596) Martinique ' },
    { value: '222', label: '(+222) Mauritania ' },
    { value: '269', label: '(+269) Mayotte, Comoros ' },
    { value: '52', label: '(+52) Mexico ' },
    { value: '691', label: '(+691) Micronesia ' },
    { value: '373', label: '(+373) Moldova ' },
    { value: '377', label: '(+377) Monaco ' },
    { value: '976', label: '(+976) Mongolia ' },
    { value: '1664', label: '(+1664) Montserrat ' },
    { value: '212', label: '(+212) Morocco ' },
    { value: '258', label: '(+258) Mozambique ' },
    { value: '95', label: '(+95) Myanmar ' },
    { value: '264', label: '(+264) Namibia ' },
    { value: '674', label: '(+674) Nauru ' },
    { value: '977', label: '(+977) Nepal ' },
    { value: '31', label: '(+31) Netherlands ' },
    { value: '687', label: '(+687) New Caledonia ' },
    { value: '64', label: '(+64) New Zealand ' },
    { value: '505', label: '(+505) Nicaragua ' },
    { value: '227', label: '(+227) Niger ' },
    { value: '234', label: '(+234) Nigeria ' },
    { value: '683', label: '(+683) Niue ' },
    { value: '672', label: '(+672) Norfolk Islands ' },
    { value: '670', label: '(+670) Northern Marianas ' },
    { value: '47', label: '(+47) Norway ' },
    { value: '968', label: '(+968) Oman ' },
    { value: '680', label: '(+680) Palau ' },
    { value: '507', label: '(+507) Panama ' },
    { value: '675', label: '(+675) Papua New Guinea ' },
    { value: '595', label: '(+595) Paraguay ' },
    { value: '51', label: '(+51) Peru ' },
    { value: '63', label: '(+63) Philippines ' },
    { value: '48', label: '(+48) Poland ' },
    { value: '351', label: '(+351) Portugal ' },
    { value: '1787', label: '(+1787) Puerto Rico ' },
    { value: '974', label: '(+974) Qatar ' },
    { value: '262', label: '(+262) Reunion ' },
    { value: '40', label: '(+40) Romania ' },
    { value: '250', label: '(+250) Rwanda ' },
    { value: '378', label: '(+378) San Marino ' },
    { value: '239', label: '(+239) Sao Tome & Principe ' },
    { value: '966', label: '(+966) Saudi Arabia ' },
    { value: '221', label: '(+221) Senegal ' },
    { value: '381', label: '(+381) Serbia ' },
    { value: '248', label: '(+248) Seychelles ' },
    { value: '232', label: '(+232) Sierra Leone ' },
    { value: '65', label: '(+65) Singapore ' },
    { value: '421', label: '(+421) Slovak Republic ' },
    { value: '386', label: '(+386) Slovenia ' },
    { value: '677', label: '(+677) Solomon Islands ' },
    { value: '252', label: '(+252) Somalia ' },
    { value: '27', label: '(+27) South Africa ' },
    { value: '34', label: '(+34) Spain ' },
    { value: '94', label: '(+94) Sri Lanka ' },
    { value: '290', label: '(+290) St. Helena ' },
    { value: '1869', label: '(+1869) St. Kitts ' },
    { value: '1758', label: '(+1758) St. Lucia ' },
    { value: '249', label: '(+249) Sudan ' },
    { value: '597', label: '(+597) Suriname ' },
    { value: '268', label: '(+268) Swaziland ' },
    { value: '46', label: '(+46) Sweden ' },
    { value: '41', label: '(+41) Switzerland ' },
    { value: '963', label: '(+963) Syria ' },
    { value: '886', label: '(+886) Taiwan ' },
    { value: '7', label: '(+7) Tajikstan, Uzbekistan, Turkmenistan, Kazakhstan, Russia ' },
    { value: '66', label: '(+66) Thailand ' },
    { value: '228', label: '(+228) Togo ' },
    { value: '676', label: '(+676) Tonga ' },
    { value: '1868', label: '(+1868) Trinidad & Tobago ' },
    { value: '216', label: '(+216) Tunisia ' },
    { value: '90', label: '(+90) Turkey ' },
    { value: '993', label: '(+993) Turkmenistan ' },
    { value: '1649', label: '(+1649) Turks & Caicos Islands ' },
    { value: '688', label: '(+688) Tuvalu ' },
    { value: '256', label: '(+256) Uganda ' },
    { value: '380', label: '(+380) Ukraine ' },
    { value: '971', label: '(+971) United Arab Emirates ' },
    { value: '598', label: '(+598) Uruguay ' },
    { value: '678', label: '(+678) Vanuatu ' },
    { value: '379', label: '(+379) Vatican City ' },
    { value: '58', label: '(+58) Venezuela ' },
    { value: '84', label: '(+84) Vietnam ' },
    { value: '1284', label: '(+1284) Virgin Islands - British ' },
    { value: '1340', label: '(+1340) Virgin Islands - US ' },
    { value: '681', label: '(+681) Wallis & Futuna ' },
    { value: '969', label: '(+969) Yemen (North)' },
    { value: '967', label: '(+967) Yemen (South)' },
    { value: '260', label: '(+260) Zambia ' },
    { value: '263', label: '(+263) Zimbabwe ' },
  ],
  countries: [
    { value: "Afghanistan", label: "Afghanistan" },
    { value: "Albania", label: "Albania" },
    { value: "Algeria", label: "Algeria" },
    { value: "American Samoa", label: "American Samoa" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguilla", label: "Anguilla" },
    { value: "Antartica", label: "Antarctica" },
    { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaijan", label: "Azerbaijan" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Belgium", label: "Belgium" },
    { value: "Belize", label: "Belize" },
    { value: "Benin", label: "Benin" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bhutan", label: "Bhutan" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Bosnia and Herzegowina", label: "Bosnia and Herzegowina" },
    { value: "Botswana", label: "Botswana" },
    { value: "Bouvet Island", label: "Bouvet Island" },
    { value: "Brazil", label: "Brazil" },
    { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
    { value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Cambodia", label: "Cambodia" },
    { value: "Cameroon", label: "Cameroon" },
    { value: "Canada", label: "Canada" },
    { value: "Cape Verde", label: "Cape Verde" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Central African Republic", label: "Central African Republic" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Christmas Island", label: "Christmas Island" },
    { value: "Cocos Islands", label: "Cocos (Keeling) Islands" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoros", label: "Comoros" },
    { value: "Congo", label: "Congo, the Democratic Republic of the" },
    { value: "Cook Islands", label: "Cook Islands" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Cota D'Ivoire", label: "Cote d'Ivoire" },
    { value: "Croatia", label: "Croatia (Hrvatska)" },
    { value: "Cuba", label: "Cuba" },
    { value: "Cyprus", label: "Cyprus" },
    { value: "Czech Republic", label: "Czech Republic" },
    { value: "Denmark", label: "Denmark" },
    { value: "Djibouti", label: "Djibouti" },
    { value: "Dominica", label: "Dominica" },
    { value: "Dominican Republic", label: "Dominican Republic" },
    { value: "East Timor", label: "East Timor" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egypt", label: "Egypt" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Ethiopia", label: "Ethiopia" },
    { value: "Falkland Islands", label: "Falkland Islands (Malvinas)" },
    { value: "Faroe Islands", label: "Faroe Islands" },
    { value: "Fiji", label: "Fiji" },
    { value: "Finland", label: "Finland" },
    { value: "France", label: "France" },
    { value: "France Metropolitan", label: "France, Metropolitan" },
    { value: "French Guiana", label: "French Guiana" },
    { value: "French Polynesia", label: "French Polynesia" },
    { value: "French Southern Territories", label: "French Southern Territories" },
    { value: "Gabon", label: "Gabon" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Germany", label: "Germany" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Greece", label: "Greece" },
    { value: "Greenland", label: "Greenland" },
    { value: "Grenada", label: "Grenada" },
    { value: "Guadeloupe", label: "Guadeloupe" },
    { value: "Guam", label: "Guam" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haiti", label: "Haiti" },
    { value: "Heard and McDonald Islands", label: "Heard and Mc Donald Islands" },
    { value: "Holy See", label: "Holy See (Vatican City State)" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungary", label: "Hungary" },
    { value: "Iceland", label: "Iceland" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Iran", label: "Iran (Islamic Republic of)" },
    { value: "Iraq", label: "Iraq" },
    { value: "Ireland", label: "Ireland" },
    { value: "Israel", label: "Israel" },
    { value: "Italy", label: "Italy" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japan", label: "Japan" },
    { value: "Jordan", label: "Jordan" },
    { value: "Kazakhstan", label: "Kazakhstan" },
    { value: "Kenya", label: "Kenya" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Democratic People's Republic of Korea", label: "Korea, Democratic People's Republic of" },
    { value: "Korea", label: "Korea, Republic of" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { value: "Lao", label: "Lao People's Democratic Republic" },
    { value: "Latvia", label: "Latvia" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Lesotho", label: "Lesotho" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lithuania", label: "Lithuania" },
    { value: "Luxembourg", label: "Luxembourg" },
    { value: "Macau", label: "Macau" },
    { value: "Macedonia", label: "Macedonia, The Former Yugoslav Republic of" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malawi", label: "Malawi" },
    { value: "Malaysia", label: "Malaysia" },
    { value: "Maldives", label: "Maldives" },
    { value: "Mali", label: "Mali" },
    { value: "Malta", label: "Malta" },
    { value: "Marshall Islands", label: "Marshall Islands" },
    { value: "Martinique", label: "Martinique" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Mauritius", label: "Mauritius" },
    { value: "Mayotte", label: "Mayotte" },
    { value: "Mexico", label: "Mexico" },
    { value: "Micronesia", label: "Micronesia, Federated States of" },
    { value: "Moldova", label: "Moldova, Republic of" },
    { value: "Monaco", label: "Monaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Morocco", label: "Morocco" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Myanmar", label: "Myanmar" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Netherlands", label: "Netherlands" },
    { value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { value: "New Caledonia", label: "New Caledonia" },
    { value: "New Zealand", label: "New Zealand" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Niger", label: "Niger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Niue", label: "Niue" },
    { value: "Norfolk Island", label: "Norfolk Island" },
    { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { value: "Norway", label: "Norway" },
    { value: "Oman", label: "Oman" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Palau", label: "Palau" },
    { value: "Panama", label: "Panama" },
    { value: "Papua New Guinea", label: "Papua New Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Peru", label: "Peru" },
    { value: "Philippines", label: "Philippines" },
    { value: "Pitcairn", label: "Pitcairn" },
    { value: "Poland", label: "Poland" },
    { value: "Portugal", label: "Portugal" },
    { value: "Puerto Rico", label: "Puerto Rico" },
    { value: "Qatar", label: "Qatar" },
    { value: "Reunion", label: "Reunion" },
    { value: "Romania", label: "Romania" },
    { value: "Russia", label: "Russian Federation" },
    { value: "Rwanda", label: "Rwanda" },
    { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { value: "Saint LUCIA", label: "Saint LUCIA" },
    { value: "Saint Vincent", label: "Saint Vincent and the Grenadines" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "Senegal", label: "Senegal" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra", label: "Sierra Leone" },
    { value: "Singapore", label: "Singapore" },
    { value: "Slovakia", label: "Slovakia (Slovak Republic)" },
    { value: "Slovenia", label: "Slovenia" },
    { value: "Solomon Islands", label: "Solomon Islands" },
    { value: "Somalia", label: "Somalia" },
    { value: "South Africa", label: "South Africa" },
    { value: "South Georgia", label: "South Georgia and the South Sandwich Islands" },
    { value: "Spain", label: "Spain" },
    { value: "SriLanka", label: "Sri Lanka" },
    { value: "St. Helena", label: "St. Helena" },
    { value: "St. Pierre and Miguelon", label: "St. Pierre and Miquelon" },
    { value: "Sudan", label: "Sudan" },
    { value: "Suriname", label: "Suriname" },
    { value: "Svalbard", label: "Svalbard and Jan Mayen Islands" },
    { value: "Swaziland", label: "Swaziland" },
    { value: "Sweden", label: "Sweden" },
    { value: "Switzerland", label: "Switzerland" },
    { value: "Syria", label: "Syrian Arab Republic" },
    { value: "Taiwan", label: "Taiwan, Province of China" },
    { value: "Tajikistan", label: "Tajikistan" },
    { value: "Tanzania", label: "Tanzania, United Republic of" },
    { value: "Thailand", label: "Thailand" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Turkey", label: "Turkey" },
    { value: "Turkmenistan", label: "Turkmenistan" },
    { value: "Turks and Caicos", label: "Turks and Caicos Islands" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States", label: "United States" },
    { value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistan", label: "Uzbekistan" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Viet Nam" },
    { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
    { value: "Virgin Islands (U.S)", label: "Virgin Islands (U.S.)" },
    { value: "Wallis and Futana Islands", label: "Wallis and Futuna Islands" },
    { value: "Western Sahara", label: "Western Sahara" },
    { value: "Yemen", label: "Yemen" },
    { value: "Serbia", label: "Serbia" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabwe", label: "Zimbabwe" },
  ],
  phoneTypes: [
    { value: "", label: 'Select' },
    { value: "1", label: 'Reservation' },
    { value: "2", label: 'General Enquiries' },
    { value: "3", label: 'Sales & Marketing Department' },
    { value: "4", label: 'Account & Finance Department' },
    { value: "5", label: 'Product & Commercial Department' },
    { value: "6", label: 'FIT Department' },
    { value: "7", label: 'Group Department' },
    { value: "8", label: 'Retail Department' },
    { value: "9", label: 'Wholesale Department' },
    { value: "10", label: 'Cruise Department' },
    { value: "11", label: 'Tour Department' },
    { value: "12", label: 'MICE Department' },
    { value: "13", label: 'Human Resource Department' },
    { value: "14", label: 'Inbound Department' },
    { value: "15", label: 'Outbound Department' },
    { value: "16", label: 'Operations Department' },
    { value: "17", label: 'Emergency Department' },
    { value: "18", label: 'IT and Support Department Other' },
  ],
  emailTypes: [
    { value: "", label: 'Select' },
    { value: "1", label: 'Reservation' },
    { value: "2", label: 'General Enquiries' },
    { value: "3", label: 'Sales & Marketing Department' },
    { value: "4", label: 'Account & Finance Department' },
    { value: "5", label: 'Product & Commercial Department' },
    { value: "6", label: 'FIT Department' },
    { value: "7", label: 'Group Department' },
    { value: "8", label: 'Retail Department' },
    { value: "9", label: 'Wholesale Department' },
    { value: "10", label: 'Cruise Department' },
    { value: "11", label: 'Tour Department' },
    { value: "12", label: 'MICE Department' },
    { value: "13", label: 'Human Resource Department' },
    { value: "14", label: 'Inbound Department' },
    { value: "15", label: 'Outbound Department' },
    { value: "16", label: 'Operations Department' },
    { value: "17", label: 'Emergency Department' },
    { value: "18", label: 'IT and Support Department Other' },
  ]
};

