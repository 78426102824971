import api from "../xhr";
import endPoint, { getURL } from "../xhr/endPoint";

export const getMenu = async () => {
  return await api.getMethod(getURL(endPoint.MENU));
};

export const createMenu = async (data) => {
  return await api.postMethod(getURL(endPoint.MENU_STORE), data);
};

export const updateMenu = async (id, data) => {
  return await api.putMethod(getURL(endPoint.MENU_UPDATE + id), data);
};

export const deleteMenu = async (id) => {
  return await api.deleteMethod(getURL(endPoint.MENU_DELETE + id));
};

export const updateMenuList = async (data) => {
  return await api.putMethod(getURL(endPoint.MENU_UPDATE_LIST), data);
};

export const updateMenuPosition = async (id, data) => {
  return await api.putMethod(getURL(endPoint.MENU_UPDATE_POSITION + id), data);
};
