import * as React from "react";
import { Grid, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as Roles from "modules/role_permissions/roles/repositories";
import { useNavigate } from "react-router-dom";
import ModalRole from "modules/role_permissions/roles/components/modalRole";
import DataTable from "examples/Tables/DataTable";
import ModalDelete from "modules/role_permissions/roles/components/modalDelete";
import moment from "moment/moment";
// import * as PermissionData from "modules/role_permissions/permissions/repositories";

function Index() {
  const navigate = useNavigate();
  const [listData, setListData] = React.useState([]);
  // const [permissions, setPermissions] = React.useState([]);

  async function getProducts() {
    try {
      let data = await Roles.getListRole();
      return setListData(data?.data?.response);
    } catch (e) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        return navigate("/login");
      }
    }
  }

  // const getPermissions = React.useCallback(async () => {
  //   try {
  //     let data = await PermissionData.getListPermission();
  //     return setPermissions(data?.data?.response);
  //   } catch (e) {
  //     if (e?.response?.status === 401) {
  //       localStorage.removeItem("access_token");
  //       localStorage.removeItem("user");
  //       return navigate("/login");
  //     }
  //   }
  // }, [navigate]);

  const handleCallback = (status) => {
    if (status === 200) getProducts();
  };

  const columns = [
    { Header: "#", accessor: "no", align: "center" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Slug", accessor: "slug", align: "left" },
    { Header: "Create Date", accessor: "created_at", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const rows = [];

  listData.map((data, index) => {
    return rows.push({
      no: index + 1,
      name: data?.name,
      slug: data?.slug,
      created_at: moment(data?.created_at).format("DD-MMM-YYYY hh:mm a"),
      action: (
        <Grid container spacing={2}>
          <Grid item>
            <ModalRole
              role={data}
              parentCallback={handleCallback}
              // permissions={permissions}
            />
          </Grid>
          <Grid item>
            <ModalDelete id={data.id} parentCallback={handleCallback} />
          </Grid>
        </Grid>
      ),
    });
  });

  React.useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      return navigate("/login");
    }
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   if (!localStorage.getItem("access_token")) {
  //     return navigate("/login");
  //   }

  //   getPermissions();
  // }, [getPermissions, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "0.75rem",
                boxShadow: "unset",
              }}
            >
              <ModalRole
                parentCallback={handleCallback}
                // permissions={permissions}
              />
              <DataTable
                table={{ columns: columns, rows: rows }}
                isSorted={true}
                canSearch={true}
                noEndBorder
              />
            </Paper>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Index;
