/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import ModalCompany from 'modules/company_crm/components/modalDetail';
import ModalActive from 'modules/company_crm/components/modalActive';
import DataTable from 'examples/Tables/DataTable';
import MDBox from 'components/MDBox';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Paper, Skeleton, TextField, MenuItem } from '@mui/material';
import * as Company from 'modules/company_crm/repositories';
import moment from 'moment';
import MDButton from 'components/MDButton';
import dataSelect from 'modules/company_crm/data';
import * as Companies from 'modules/company_crm/repositories';
const { countries } = dataSelect;

function ListCompany() {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);
    const [loadingTable, setLoadingTable] = React.useState(true);
    const [subscribedsolution, setSolutionComArr] = React.useState([]);
    const [subscribedPlan, setPlanComArr] = React.useState([]);
    const [searchCountry, setSearchCountry] = React.useState('');
    const [searchCompanyName, setSearchCompanyName] = React.useState('');
    const [searchSolution, setSearchSolution] = React.useState('');
    const [searchPlan, setSearchPlan] = React.useState('');
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const clearSearch = (e) => {
        e.preventDefault();
        setSearchCountry('');
        setSearchCompanyName('');
        setSearchSolution('');
        setSearchPlan('');
        fetchData('clear');
    }

    const showSolutionPlan = React.useCallback(async () => {
        try {
            const soplRes = await Companies.getSolutionPlan();
            if (soplRes?.data?.response) {
                setSolutionComArr(soplRes?.data?.response);
                setPlanComArr(soplRes?.data?.response[0]?.plans);
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    }, []);

    const fetchData = async (event = '') => {
        try {
            let data = {
                country: searchCountry,
                name: searchCompanyName,
                subscribe_solution: searchSolution,
                subscribe_plan: searchPlan,
            }
            setDisabledBtn(true);
            if (event === 'clear') {
                data = {
                    country: '',
                    name: '',
                    subscribe_solution: '',
                    subscribe_plan: '',
                };
            }
            let comRes = await Company.getListCompany(data);
            if (comRes?.data?.response) {
                setLoadingTable(false);
                setDisabledBtn(false);
                return setListData(comRes?.data?.response);
            }
        } catch (e) {
            setDisabledBtn(false);
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    const dataCallback = (status) => {
        if (status === 200) fetchData();
    }

    const columns = [
        { Header: "#", accessor: "no", align: "center" },
        { Header: "Country", accessor: "country", align: "left" },
        { Header: "Company Name", accessor: "companyName", align: "left" },
        { Header: "List Contacts", accessor: "listContacts", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Phone", accessor: "phone", align: "left" },
        { Header: "Subscribed Solution", accessor: "subscribedSolution", align: "left" },
        { Header: "Subscribed Plan", accessor: "subscribedPlan", align: "left" },
        { Header: "Create Date", accessor: "createDate", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];

    const rows = [];

    listData.map((data, index) => {
        return rows.push(
            {
                no: (index + 1),
                country: data.country,
                companyName: data.name,
                listContacts: 'abc',
                email: data.first_email,
                phone: '+' + data.country_code + data.phone_number,
                subscribedSolution: data.subscribed_solution,
                subscribedPlan: data.subscribed_plan,
                createDate: moment(data?.created_at).format('DD-MMM-YYYY'),
                action: (
                    <Grid container spacing={2}>
                        <Grid item>
                            <ModalCompany dataCompany={data} dataCallback={dataCallback} />
                        </Grid>
                        <Grid item>
                            <ModalActive id={data?.id} active={data?.is_active} parentCallback={dataCallback} />
                        </Grid>
                    </Grid>
                ),
            }
        );
    });

    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
        showSolutionPlan();
        fetchData();
    }, [showSolutionPlan]);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3} xl={2} height={100}>
                <MDBox>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset',
                            height: { xs: '100%', md: '12rem', lg: '12rem' },
                            justifyContent: 'center',
                        }}
                    >
                        <ModalCompany dataCallback={dataCallback} />
                    </Paper>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={8} lg={9} xl={10} alignItems={'center'}>
                <MDBox component="form" role="form">
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    label="Country"
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={searchCountry}
                                    onChange={(e) => setSearchCountry(e.target.value)}
                                >
                                    <MenuItem value=''>Select</MenuItem>
                                    {countries.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    label="Company Name"
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    autoComplete="companyName"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={searchCompanyName}
                                    onChange={(e) => setSearchCompanyName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    label="Subscribed Solution"
                                    id="subscribedSolution"
                                    name="subscribedSolution"
                                    autoComplete="subscribedSolution"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={searchSolution}
                                    onChange={(e) => setSearchSolution(e.target.value)}
                                >
                                    <MenuItem value=''>Select</MenuItem>
                                    {/*{subscribedsolution.map((option) => (*/}
                                    {/*    <MenuItem key={option.id} value={option.id}>*/}
                                    {/*        {option.name}*/}
                                    {/*    </MenuItem>*/}
                                    {/*))}*/}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    label="Subscribed Plan"
                                    id="subscribedPlan"
                                    name="subscribedPlan"
                                    autoComplete="subscribedPlan"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={searchPlan}
                                    onChange={(e) => setSearchPlan(e.target.value)}
                                >
                                    <MenuItem value=''>Select</MenuItem>
                                    {/*{subscribedPlan.map((option) => (*/}
                                    {/*    <MenuItem key={option.id} value={option.id}>*/}
                                    {/*        {option.name}*/}
                                    {/*    </MenuItem>*/}
                                    {/*))}*/}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} textAlign={'center'}>
                                <MDButton variant="contained" color="dark" sx={{ fontWeight: 500, mr: 4 }} onClick={clearSearch} disabled={disabledBtn}>Clear</MDButton>
                                <MDButton variant="contained" color="info" sx={{ fontWeight: 500 }} onClick={fetchData} disabled={disabledBtn}>Search</MDButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </MDBox>
            </Grid>
            {/* Recent List */}
            <Grid item xs={12} md={12} lg={12} >
                {!loadingTable ?
                    <MDBox>
                        <DataTable
                            table={{ columns: columns, rows: rows }}
                            isSorted={false}
                            noEndBorder
                        />
                    </MDBox>
                    :
                    <Paper
                        sx={{
                            p: 3,
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <Box>

                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                        </Box>
                    </Paper>
                }
            </Grid>
        </Grid>
    )
}

export default ListCompany;
