import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
  LIST_TEMPLATE_MASTER: "email-template/index-master-template",
  STORE_TEMPLATE_MASTER: "email-template/store-master-template",
  UPDATE_TEMPLATE_MASTER: "email-template/update-master-template/",
  LIST_TEMPLATE_BODY: "email-template/index-template-body",
  STORE_TEMPLATE_BODY: "email-template/store-template-body",
  UPDATE_TEMPLATE_BODY: "email-template/update-template-body/",
  DELETE_TEMPLATE_BODY: "email-template/delete-template-body/",
  PREVIEW_TEMPLATE_BODY: "email-template/preview-template-body/",
  PREVIEW_TEMPLATE_MASTER: "email-template/preview-template-master/",

  UPLOAD_IMAGE_MASTER: 'upload/image',
};

export const getURL = (name, routeParams = {}, queryParam = {}) => {
  const pattern = new UrlPattern(`${name}`);
  let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
  let mappingParams = new URLSearchParams();
  return `${url.origin}${url.pathname}?${mappingParams}`;
};

export default endPoint;
