import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import * as Companies from 'modules/company_crm/repositories';
import { Grid, IconButton, Skeleton } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import ModalViewFile from 'modules/company_crm/components/modalViewFile';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: { xs: '90%', md: '40%', lg: '40%' },
};

function ModalContract({ id }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true); showContract(); }
    const handleClose = () => setOpen(false);
    const [contract, setContract] = React.useState([]);
    const [loadingList, setLoadingList] = React.useState(true);
    const showContract = async () => {
        try {
            const comRes = await Companies.getShowCompany(id);
            if (comRes?.data?.response) {
                setLoadingList(false);
                setContract(comRes?.data?.response?.contracts);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteContract = (e, dt) => {
        e.preventDefault();
        try {
            Swal.fire({
                icon: 'warning',
                text: `WARNING: Delete this contract won't be revert`,
                showConfirmButton: false,
                showDenyButton: true,
                showCancelButton: true,
                denyButtonText: 'Yes, Delete it!',
            }).then(async (result) => {
                if (result.isDenied) {
                    const data = {
                        id: dt.id,
                        company_id: dt.company_id,
                    }
                    const comRes = await Companies.deleteCompanyContract(data);
                    if (comRes?.data?.response) {
                        showContract();
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Typography sx={{ float: 'right' }}>
            <Button onClick={handleOpen}>View Contract</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2">
                                    List Contract
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <Close sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        maxHeight: 'calc(100vh - 9.5rem)',
                        overflow: 'hidden',
                        overflowY: 'overlay',
                    }}>
                        {!loadingList ?
                            contract.map((data, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={10} sx={{ px: 3, py: 2 }}>
                                        <ModalViewFile dataFile={data} index={index} />
                                    </Grid>
                                    <Grid item xs={2} sx={{ px: 3, py: 2, textAlign: 'center' }}>
                                        <Delete color="error" onClick={(e) => { deleteContract(e, data) }} sx={{ cursor: 'pointer' }} />
                                    </Grid>
                                </Grid>
                            ))
                            :
                            <Box sx={{ width: '100%' }}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                            </Box>
                        }
                    </Grid>
                </Box>
            </Modal>
        </Typography>
    );
}
export default ModalContract;