import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
  SOLUTION_PLAN_FEATURES: "solution-plan-access/features",
  SOLUTION_PLAN_STORE_SOLUTION: "solution-plan-access/store-solution",
  SOLUTION_PLAN_UPDATE_SOLUTION: "solution-plan-access/update-solution/",
  SOLUTION_PLAN_DELETE_SOLUTION: "solution-plan-access/delete-solution/",
  SOLUTION_PLAN_SOLUTIONS: "solution-plan-access/solutions",
  SOLUTION_PLAN_SOLUTION_DETAIL: "solution-plan-access/solution-detail/",

  SOLUTION_PLAN_STORE_PLAN: "solution-plan-access/store-plan",
  SOLUTION_PLAN_UPDATE_PLAN: "solution-plan-access/update-plan/",
  SOLUTION_PLAN_DELETE_PLAN: "solution-plan-access/delete-plan/",
};

export const getURL = (name, routeParams = {}, queryParam = {}) => {
  const pattern = new UrlPattern(`${name}`);
  let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
  let mappingParams = new URLSearchParams();
  return `${url.origin}${url.pathname}?${mappingParams}`;
};

export default endPoint;
