import { Link, Grid, Stack } from '@mui/material';
import Cloud from 'assets/images/logos/cloud.png';
import Banner from 'assets/images/logos/TKG.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function LayoutLeft() {
    return (
        <Grid
            item
            xs={false}
            sm={false}
            md={7}
            sx={{
                alignSelf: 'end',
                display: { xs: 'none', md: 'block', lg: 'block' },
                height: '100vh'
            }}
        >
            <Grid item sx={{ m: 0, height: '33.33vh' }}>
                <Stack
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                    sx={{
                        padding: '0 1rem',
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        top: '10vh'
                    }}
                >
                    <ArrowBackIcon />
                    <Link
                        href="https://www.tkgplatform.com/"
                        underline="hover"
                        sx={{
                            color: '#fff',
                            padding: '0 5px',
                            marginTop: "0 !important"
                        }}
                        variant={'h6'}
                    >
                        Back to site
                    </Link>
                </Stack>
            </Grid>
            <Grid item sx={{ m: 0, height: '33.33vh' }}>
                <img src={Banner} alt="" style={{ width: '100%', height: '100%' }} />
            </Grid>
            <Grid item sx={{ m: 0, height: '33.33vh' }}>
                <img src={Cloud} alt="" style={{ width: '80%', height: '100%', float: 'right' }} />
            </Grid>
        </Grid>
    )
}
export default LayoutLeft;