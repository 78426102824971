import * as React from 'react';
import ModalContact from 'modules/contact_crm/components/modalDetail';
import ModalDelete from 'modules/contact_crm/components/modalDelete';
import DataTable from 'examples/Tables/DataTable';
import MDBox from 'components/MDBox';
import { Box, Grid, Paper, Skeleton } from '@mui/material';
import * as Contact from 'modules/contact_crm/repositories';
import { useNavigate } from 'react-router-dom';
// import Skeleton from '@mui/material/Skeleton';
// import Stack from '@mui/material/Stack';

function ListContact() {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);
    const [loadingTable, setLoadingTable] = React.useState(true);

    async function fetchData() {
        try {
            let data = await Contact.getListContact();

            if (data?.data?.response) {
                setLoadingTable(false);
                setListData(
                    data?.data?.response.map((data, index) => {
                      return {
                        no: index + 1,
                        name: data?.first_name + ' ' + data?.surname,
                        email: data?.email,
                        company: data?.company?.name,
                        phone: data?.phone_number,
                        position: data?.position,
                        action: (
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ModalContact
                                        contact={data}
                                        dataCallback={handleCallback}
                                        />
                                </Grid>
                                <Grid item>
                                    <ModalDelete
                                        id={data.id}
                                        dataCallback={handleCallback}
                                        />
                                </Grid>
                            </Grid>
                        ),
                    }
                    })
                  );
          
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    }
    const handleCallback = (status) => {
        if (status === 200) fetchData();
    }

    const columns = [
        { Header: "#", accessor: "no", align: "center" },
        { Header: "Name", accessor: "name", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Company", accessor: "company", align: "left" },
        { Header: "Phone", accessor: "phone", align: "left" },
        { Header: "Position", accessor: "position", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];
    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {
                !loadingTable
                    ?
                    <MDBox>
                        <DataTable
                            table={{ columns: columns, rows: listData }}
                            isSorted={true}
                            canSearch={true}
                            noEndBorder
                        />
                    </MDBox>
                    :
                    <Paper
                        sx={{
                            p: 3,
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <Box>

                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                        </Box>
                    </Paper>
            }


        </>



    )
}

export default ListContact;