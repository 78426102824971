

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';
import { Grid, IconButton, Stack, TextField } from '@mui/material';
import { postSendMailForgotPassword } from '../repositories';
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: {
        xs: '95%',
        md: '40%',
        lg: '40%'
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

const cssInput = {
    marginTop: 1,
    marginBottom: 1,
    borderRadius: '20px',
    '& > label': {
        top: 0,
        left: 0,
        color: 'gray',
        '&[data-shrink="false"]': {
            top: 5
        }
    },
    '& > div > input': {
        padding: '20px !important'
    },
    '& legend': {
        display: 'none'
    },
    '& fieldset': {
        top: 0
    }
};

function ModalForgotPassword() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setErrEmail(false);
        setTxtErrEmail('');
    }
    const [emailFg, setEmailFg] = React.useState('');
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [errEmail, setErrEmail] = React.useState(false);
    const [txtErrEmail, setTxtErrEmail] = React.useState('');
    const swalModal = (type, message) => {
        return Swal.fire({
            icon: type,
            text: message,
            showConfirmButton: true,
            // timer: 2000
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (emailFg === '') {
            // swalModal('error', 'Please enter the email');
            setErrEmail(true);
            setTxtErrEmail('Please enter the email');
        } else if (!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(emailFg)) {
            // swalModal('error', 'Incorrect email format');
            setErrEmail(true);
            setTxtErrEmail('Incorrect email format');
        } else {
            try {
                const data = {
                    email: emailFg,
                }
                setDisabledBtn(true);
                const dataRes = await postSendMailForgotPassword(data);
                console.log(dataRes);
                if (dataRes?.data?.meta?.status === 200) {
                    setDisabledBtn(false);
                    setOpen(false);
                    setEmailFg('');
                    swalModal('success', 'Password reset link has been sent to your email. Please check your email for the reset link');
                }
            } catch (error) {
                if (error?.response?.status === 500) {
                    setDisabledBtn(false);
                    setErrEmail(true);
                    setTxtErrEmail(error?.response.data.meta.message);
                    // swalModal('error', error?.response.data.meta.message);
                }
                if (error?.response?.status === 422) {
                    setDisabledBtn(false);
                    setErrEmail(true);
                    // setTxtErrEmail('Please enter a valid email address');
                    swalModal('error', error?.response.data.meta.message);
                }
            }
        }
    };

    return (
        <div>
            <MDButton onClick={handleOpen} sx={{ mb: 1, fontSize: '14px' }}>
                Forgot password?
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box noValidate component="form" sx={style}>
                    <IconButton aria-label="close" onClick={handleClose} sx={{ float: 'right' }}>
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" textAlign={'center'} variant="h4" component="h2" pt={5}>
                        Forgot password
                    </Typography>
                    <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, margin: 'auto' }} justifyContent="space-around">
                        <Grid item xs={12} pt={3}>
                            <Stack spacing={2}>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        color: '#000'
                                    }}
                                    gutterBottom
                                >
                                    Enter your email
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={errEmail}
                                helperText={txtErrEmail}
                                margin="normal"
                                fullWidth
                                id="emailFg"
                                name="emailFg"
                                type="email"
                                autoComplete="emailFg"
                                onChange={(event) => setEmailFg(event.target.value)}
                                sx={cssInput}
                            />
                        </Grid>
                        <MDButton onClick={handleSubmit} variant="contained" color="success" sx={{ width: '7rem', mt: 3, mb: 5 }} disabled={disabledBtn}>
                            Submit
                        </MDButton>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default ModalForgotPassword;