import React from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { Grid, Paper } from '@mui/material';
import MDBox from 'components/MDBox';


const Invoice = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '0.75rem',
                boxShadow: 'unset'
              }}
            >
              <MDTypography>Invoice</MDTypography>
            </Paper>
          </MDBox>
        </Grid>
      </Grid>

    </DashboardLayout>
  )
}

export default Invoice