import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';
import { Avatar, FormHelperText, Grid, IconButton, Input, MenuItem, Paper, Tooltip, Zoom } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import * as Master from 'modules/standard_template/repositories';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import dataSelect from 'modules/standard_template/data';

const { listFunctions } = dataSelect;

const style = {
    bgcolor: '#efecec',
    boxShadow: 24,
    height: '100%',
};

const cssBodyModal = {
    pr: 2,
    m: 0,
    height: 'calc(100vh - 9.5rem)',
    overflow: 'hidden',
    overflowY: 'scroll',
};

const URL_FILE = process.env.REACT_APP_AWS_URL;

function ModalTemplate({ dataBody, parentCallback }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [coverImage, setCoverImage] = React.useState('');
    const [errContent, setErrContent] = React.useState('');
    const [listData, setListData] = React.useState([]);
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const handleOpen = () => { setOpen(true); fetchData(); }
    const handleClose = () => setOpen(false);
    const [contentTemplate, setContentTemplate] = React.useState(dataBody?.content ? dataBody?.content : '');

    const fetchData = async () => {
        try {
            let masterRes = await Master.getListMaster();
            if (masterRes?.data?.response) {
                const arrMaster = [];
                arrMaster.push({ id: 0, name: 'Select' });
                masterRes?.data?.response?.map((e) => {
                    return arrMaster.push({ id: e.id, name: e.name });
                })
                setListData(arrMaster);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    const uploadImage = async (e, sub) => {
        e.preventDefault();
        try {
            const data = {
                sub_folder: 'header-footer',
                image: e.target.files[0]
            }
            setDisabledBtn(true);
            const logoRes = await Master.postUploadImage(data);
            if (logoRes?.data?.response) {
                setCoverImage(logoRes?.data?.response);
                setDisabledBtn(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validate = ({
        name,
        type,
        subject,
        master_email_template_id,
    }) => {
        const errors = {};
        if (!name) {
            errors.name = 'Please enter the Template Name';
        }
        if (type === "select") {
            errors.type = 'Please select the Group';
        }
        if (!subject) {
            errors.subject = 'Please enter the Subject';
        }
        if (master_email_template_id === 0) {
            errors.master_email_template_id = 'Please select the Template Master';
        }
        if (contentTemplate === '') {
            setErrContent('Please enter the Body Content');
        } else if (contentTemplate.length < 30) {
            setErrContent('Please enter at least 30 characters');
        } else if (contentTemplate.length > 10000) {
            setErrContent('Please enter less than 10,000 characters');
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: dataBody?.name ? dataBody?.name : '',
            type: dataBody?.type ? dataBody?.type : 'select',
            description: dataBody?.description ? dataBody?.description : '',
            subject: dataBody?.subject ? dataBody?.subject : '',
            content: dataBody?.content ? dataBody?.content : '',
            cover_image_url: dataBody?.cover_image_url ? dataBody?.cover_image_url : '',
            cover_image_link: dataBody?.cover_image_link ? dataBody?.cover_image_link : '',
            master_email_template_id: dataBody?.master_email_template_id ? dataBody?.master_email_template_id : 0,
            functions: dataBody?.functions ? dataBody?.functions : 'select',
        },
        validate,
        onSubmit: async ({
            name,
            type,
            description,
            subject,
            cover_image_link,
            master_email_template_id,
            functions,
        }) => {
            const data = {
                name: name,
                type: type,
                description: description,
                subject: subject,
                content: contentTemplate,
                cover_image_url: coverImage,
                cover_image_link: cover_image_link,
                master_email_template_id: master_email_template_id,
                functions: functions,
            }
            try {
                setErrContent('');
                setDisabledBtn(true);
                if (dataBody) {
                    const dataRes = await Master.putUpdateBody(dataBody?.id, data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        parentCallback(dataRes?.data?.meta?.status);
                        Swal.fire({
                            icon: 'success',
                            text: `Update Template: "${data.name}" success`,
                        });
                    }
                } else {
                    const dataRes = await Master.postCreateBody(data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        handleClose();
                        parentCallback(dataRes?.data?.meta?.status);
                        Swal.fire({
                            icon: 'success',
                            text: 'Create Template success',
                        });
                    }
                }
            } catch (e) {
                setDisabledBtn(false);
                if (e?.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    return navigate('/login');
                }
            }
        },
    });

    return (
        <div>
            {dataBody ? (
                <Tooltip
                    title={<p style={{ fontSize: '0.7rem' }}>Edit</p>}
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                >
                    <IconButton color="info" onClick={handleOpen}>
                        <FolderIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <MDButton fullWidth variant="contained" color="info" onClick={handleOpen}>
                    New Email Template
                </MDButton>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ height: '100%', width: { xs: '100%', md: '80%', lg: '80%' }, left: 'unset' }}
            >
                <Box sx={style} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2">
                                    Email Template Body
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={cssBodyModal} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset' }}>
                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Template Master(*)"
                                            id="master_email_template_id"
                                            name="master_email_template_id"
                                            select
                                            value={formik.values.master_email_template_id}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.master_email_template_id && Boolean(formik.errors.master_email_template_id)}
                                            helperText={formik.touched.master_email_template_id && formik.errors.master_email_template_id}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {listData ? listData.map((e) => (
                                                <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                                            )) : ''}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Group(*)"
                                            id="type"
                                            name="type"
                                            select
                                            value={formik.values.type}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.type && Boolean(formik.errors.type)}
                                            helperText={formik.touched.type && formik.errors.type}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem value="select">Select</MenuItem>
                                            <MenuItem value="email">Email</MenuItem>
                                            <MenuItem value="invoice">Invoice</MenuItem>
                                            <MenuItem value="form/document">Form/Document</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Template Name(*)"
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={formik.values.name}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Functions"
                                            id="functions"
                                            name="functions"
                                            select
                                            value={formik.values.functions}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {listFunctions ? listFunctions.map((e) => (
                                                <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                                            )) : ''}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            id="description"
                                            name="description"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Cover Image Link"
                                            id="cover_image_link"
                                            name="cover_image_link"
                                            type="text"
                                            InputLabelProps={{ shrink: true, }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Cover Image"
                                                id="cover_image"
                                                name="cover_image"
                                                type="text"
                                                autoComplete="cover_image"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ readOnly: true, }}
                                                sx={{ '& .MuiInputBase-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                                            />
                                            <label htmlFor="contained-button-file" style={{ margin: 0 }}>
                                                <Input accept="image/*" id="contained-button-file" type="file" name="cover_image" onChange={(e) => uploadImage(e)} sx={{ display: 'none' }} />
                                                <MDButton fullWidth type="button" variant="contained" color="success" component="span" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minHeight: '2.7rem' }}>
                                                    Browse
                                                </MDButton>
                                            </label>
                                        </Stack>
                                        {coverImage ? <Avatar
                                            alt={URL_FILE + coverImage}
                                            src={URL_FILE + coverImage}
                                            sx={{ width: 100, height: 56, margin: 'auto', marginTop: '1rem', borderRadius: 0 }}
                                        /> : ''}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Subject(*)"
                                            id="subject"
                                            name="subject"
                                            type="text"
                                            value={formik.values.subject}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.subject && Boolean(formik.errors.subject)}
                                            helperText={formik.touched.subject && formik.errors.subject}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <ReactQuill theme="snow" placeholder="Body Content here..." value={contentTemplate} onChange={setContentTemplate} />
                                        {errContent ? <FormHelperText error={true} sx={{ margin: '3px 14px 0px' }}>{errContent}</FormHelperText> : ''}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid container px={4} py={2} mt={1} justifyContent="flex-end" sx={{ backgroundColor: '#fff', boxShadow: '0 0px 5px -2px gray!important' }}>
                        <Grid item xs={12} md={3} lg={2}>
                            <MDButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="info"
                                sx={{ fontWeight: 500 }}
                                disabled={disabledBtn}
                            >
                                Save
                            </MDButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default ModalTemplate;