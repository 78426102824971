import React from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Iframe from 'react-iframe'

const UIDoc = () => {
    return (
        <DashboardLayout>
            <Iframe url="http://uidoc.tkgplatform.com.au"
                width="100%"
                allow="fullscreen"
                display="block"
                position="relative"
                styles={{
                    border: 'none',
                    height: '96vh'
                }} />
        </DashboardLayout>
    )
}

export default UIDoc