/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Contact from "layouts/Contact";
import Company from "layouts/Company";
import Login from "modules/master_login/pages";
import Signout from "modules/master_login/pages/signOut";
import Invoice from "modules/invoice";
// @mui icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Logout } from "@mui/icons-material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// import Permissions from "modules/role_permissions/permissions/pages";
import Roles from "modules/role_permissions/roles/pages";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import PlatformUser from "modules/platform_user/pages";
import BookingManage from "modules/booking_manage";
import SolutionPlan from "modules/solution_plan/pages";
import MenuManage from "modules/menu_manage/pages";
import StandardTemplates from "modules/standard_template/pages";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import UIDoc from "layouts/uidoc";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />,
    route: "/dashboard",
    component: <Dashboard />,
    child: null,
  },
  {
    type: "collapse",
    name: "CRM",
    key: "crm",
    icon: <ManageAccountsIcon />,
    route: null,
    component: null,
    child: [
      {
        type: "collapse",
        name: "Contact",
        key: "contact",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/contact",
        child: null,
      },
      {
        type: "collapse",
        name: "Company",
        key: "company",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/company",
        child: null,
      },
      // {
      //   type: "collapse",
      //   name: "Child 1",
      //   key: "child1",
      //   icon: <SettingsIcon fontSize="small" />,
      //   route: null,
      //   component: null,
      //   child: [
      //     {
      //       type: "collapse",
      //       name: "Child 2",
      //       key: "child2",
      //       icon: <ReceiptIcon fontSize="small" />,
      //       route: "/child2",
      //       component: <Dashboard />,
      //       child: null,
      //     },
      //     {
      //       type: "collapse",
      //       name: "Child 3",
      //       key: "child3",
      //       icon: <PermContactCalendarIcon fontSize="small" />,
      //       route: "/child3",
      //       component: <Dashboard />,
      //       child: null,
      //     },
      //   ],
      // },
    ],
  },
  {
    type: "collapse",
    name: "Invoice",
    key: "invoice",
    icon: <ReceiptIcon fontSize="small" />,
    route: "/invoice",
    component: <Invoice />,
    child: null,
  },
  {
    type: "collapse",
    name: "Platform User",
    key: "user",
    icon: <PermContactCalendarIcon fontSize="small" />,
    route: "/user",
    component: <PlatformUser />,
    child: null,
  },
  {
    type: "collapse",
    name: "Booking Management",
    key: "booking",
    icon: <LocalGroceryStoreIcon fontSize="small" />,
    route: "/booking",
    component: <BookingManage />,
    child: null,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsIcon fontSize="small" />,
    route: null,
    component: null,
    child: [
      {
        type: "collapse",
        name: "Menu Manage",
        key: "menu",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/menu",
        component: null,
      },
      {
        type: "collapse",
        name: "Role & Permissions",
        key: "roles",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/roles",
        component: null,
      },
      {
        type: "collapse",
        name: "Solution & Plan Access",
        key: "solution",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/solution",
        component: null,
      },
      {
        type: "collapse",
        name: "Standard Templates",
        key: "standardtemplates",
        icon: <FiberManualRecordOutlinedIcon fontSize="small" />,
        route: "/standard-templates",
        component: null,
      }
    ],
  },
  {
    type: "collapse",
    name: "UI Documents",
    key: "uidoc",
    icon: <ReceiptLongIcon fontSize="small" />,
    route: "/uidoc",
    component: <UIDoc />,
    child: null,
  },
  {
    type: "route",
    name: "Login",
    key: "login",
    route: "/login",
    component: <Login />,
    child: null,
  },
  {
    type: "route",
    name: "Sign Out",
    key: "signout",
    icon: <Logout fontSize="small" />,
    route: "/signout",
    component: <Signout />,
    child: null,
  },
  {
    type: "route",
    key: "contact",
    route: "/contact",
    component: <Contact />,
    child: null,
  },
  {
    type: "route",
    key: "company",
    route: "/company",
    component: <Company />,
    child: null,
  },
  {
    type: "route",
    key: "roles",
    route: "/roles",
    component: <Roles />,
    child: null,
  },
  {
    type: "route",
    key: "menu",
    route: "/menu",
    component: <MenuManage />,
    child: null,
  },
  {
    type: "route",
    key: "solution",
    route: "/solution",
    component: <SolutionPlan />,
    child: null,
  },
  {
    type: "route",
    key: "standardtemplates",
    route: "/standard-templates",
    component: <StandardTemplates />,
    child: null,
  },
  {
    type: "route",
    key: "forget-password",
    route: "/forget-password/:token",
    component: <Login />,
    child: null,
  },
];

export default routes;
