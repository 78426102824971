import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    'ROLES_LIST': 'role-and-permission/roles',
    'ROLES_STORE': 'role-and-permission/store-role',
    'ROLES_UPDATE': 'role-and-permission/update-role/',
    'ROLES_SHOW': 'role-and-permission/show-role/',
    'ROLES_DELETE': 'role-and-permission/delete-role/',
}

export const getURL = (name, routeParams = {}, queryParam = {}) => {
    const pattern = new UrlPattern(`${name}`);
    let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
    let mappingParams = new URLSearchParams();
    return `${url.origin}${url.pathname}?${mappingParams}`;
}

export default endPoint;
