/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FolderIcon from '@mui/icons-material/Folder';
import { Grid, InputAdornment, MenuItem, Stack, TextField, Input, Divider, Paper, Chip, Checkbox, TableContainer, Table, TableBody, TableRow, TableCell, FormControlLabel, List, ListItemButton, Collapse, Avatar, Tooltip, Pagination, FormHelperText } from '@mui/material';
import MDButton from 'components/MDButton';
import CloseIcon from '@mui/icons-material/Close';
import { Add, Close, Create, Email, NoteAlt, Phone } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import * as Companies from 'modules/company_crm/repositories';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import dataSelect from 'modules/company_crm/data';
import moment from 'moment';
import ModalContract from 'modules/company_crm/components/modalContract';
import parse from 'html-react-parser';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ],
};

const { countryPhonePrefixCode, countries, phoneTypes, emailTypes } = dataSelect;

const URL_FILE = process.env.REACT_APP_AWS_URL;

const style = {
    bgcolor: '#efecec',
    boxShadow: 24,
    height: '100%',
};


const cssBodyModal = {
    pr: 2,
    // pl: 1,
    // py: 2,
    m: 0,
    // height: '80%',
    height: 'calc(100vh - 9.5rem)',
    overflow: 'hidden',
    overflowY: 'scroll',
    // background: '#fff',
};

function ModalDetail({ dataCompany, dataCallback, dataCallback1 }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        showSolutionPlan();
        if (dataCompany) {
            fetchLogCompany();
            showComment();
            getFetchEmail();
        }
    }
    const handleClose = () => { setOpen(false); setDisabledFld(true); setLoading(false); }
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [disabledFld, setDisabledFld] = React.useState(true);
    const [txtComment, setTxtComment] = React.useState('');
    const [arrComment, setArrComment] = React.useState([]);
    const [arrCompanyShow, setArrCompanyShow] = React.useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [openFetchEmail, setOpenFetchEmail] = React.useState(-1);
    const [companyCode,] = React.useState(dataCompany?.code ? dataCompany?.code : '');
    const [logoUrl, setLogoUrl] = React.useState(dataCompany?.logo_url ? dataCompany?.logo_url : '');
    const [solutionComArr, setSolutionComArr] = React.useState([]);
    const [planComArr, setPlanComArr] = React.useState([]);
    const [fetchEmail, setFetchEmail] = React.useState([]);
    const [subjectEmail, setSubjectEmail] = React.useState('');
    const [messageEmail, setMessageEmail] = React.useState('');
    const [valueTab, setValueTab] = React.useState('notes');
    const [valueTabEmail, setValueTabEmail] = React.useState('email');
    const [page, setPage] = React.useState(1);
    const [logCompany, setLogCompany] = React.useState([]);
    const [errorCheckBox, setErrorCheckBox] = React.useState('');
    const [errSubjectEmail, setErrSubjectEmail] = React.useState(false);
    const [errTxtSubjectEmail, setErrTxtSubjectEmail] = React.useState('');
    const [errMessageEmail, setErrMessageEmail] = React.useState(false);
    const [errTxtMessageEmail, setErrTxtMessageEmail] = React.useState('');
    const [errNote, setErrNote] = React.useState(false);
    const [txtErrNote, setTxtErrNote] = React.useState('');

    function handleClickEdit() {
        setLoading(true);
        setTimeout(() => {
            setDisabledFld(false)
        }, 1000);
    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleChangeTabEmail = (event, newValue) => {
        setValueTabEmail(newValue);
    };

    const showComment = async () => {
        try {
            const comRes = await Companies.getShowCompany(dataCompany?.id);
            if (comRes?.data?.response) {
                setArrComment(comRes?.data?.response?.comments);
                setArrCompanyShow(comRes?.data?.response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const showSolutionPlan = async () => {
        try {
            const soplRes = await Companies.getSolutionPlan();
            if (soplRes?.data?.response) {
                setSolutionComArr(soplRes?.data?.response);
                setPlanComArr(soplRes?.data?.response[0]?.plans);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchLogCompany(pageLog) {
        const dataPage = {
            page: pageLog
        }
        try {
            let data = await Companies.getLogDetails(dataCompany?.id, dataPage);
            if (data?.data?.response)
                setLogCompany(data?.data?.response);

        } catch (err) {
            console.log(err)
        }
    }

    const handleChangePagiNote = (event, value) => {
        setPage(value);
        fetchLogCompany(value);
    };

    const saveNote = async (e) => {
        if (txtComment === '') {
            setErrNote(true);
            setTxtErrNote('Please enter the note');
            return false;
        }
        e.preventDefault();
        try {
            const data = {
                company_id: dataCompany?.id,
                content: txtComment
            }
            setDisabledBtn(true);
            const noteRes = await Companies.postCreateCompanyComment(data);
            if (noteRes?.data?.meta?.status === 200) {
                setDisabledBtn(false);
                setTxtComment('');
                showComment();
                Swal.fire({
                    icon: 'success',
                    text: 'Create Note success',
                });
            }
        } catch (error) {
            console.log(error);
            setDisabledBtn(false);
        }
    }

    const getFetchEmail = async (e) => {
        try {
            const emailFetchRes = await Companies.fetchEmail(dataCompany?.id);
            if (emailFetchRes?.data?.meta?.status === 200) {
                setFetchEmail(emailFetchRes?.data?.response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const uploadContract = async (e) => {
        e.preventDefault();
        try {
            const data = {
                company_id: dataCompany?.id,
                contract: e.target.files[0]
            }
            setDisabledBtn(true);
            const contractRes = await Companies.postUploadContract(data);
            if (contractRes?.data?.response) {
                Swal.fire({
                    icon: 'success',
                    text: 'Upload contract success',
                });
                setDisabledBtn(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const uploadLogo = async (e) => {
        e.preventDefault();
        try {
            const data = {
                sub_folder: 'company-' + dataCompany?.id,
                image: e.target.files[0]
            }
            setDisabledBtn(true);
            const logoRes = await Companies.postUploadImage(data);
            console.log(URL_FILE + logoRes?.data?.response);
            if (logoRes?.data?.response) {
                setLogoUrl(URL_FILE + logoRes?.data?.response);
                setDisabledBtn(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        if (subjectEmail === '') {
            setErrSubjectEmail(true);
            setErrTxtSubjectEmail('Please enter the subject');
            return false;
        }
        if (messageEmail === '') {
            setErrMessageEmail(true);
            setErrTxtMessageEmail('Please enter the message');
            return false;
        }
        try {
            const data = {
                company_id: dataCompany?.id,
                subject: subjectEmail,
                body: messageEmail
            }
            setDisabledBtn(true);
            const contractRes = await Companies.postSendEmail(data);
            if (contractRes?.data?.response) {
                setDisabledBtn(false);
                setSubjectEmail('');
                setMessageEmail('');
                getFetchEmail();
                setErrSubjectEmail(false);
                setErrTxtSubjectEmail('');
                setErrMessageEmail(false);
                setErrTxtMessageEmail('');
                Swal.fire({
                    icon: 'success',
                    text: 'Send email success',
                });
            }
        } catch (error) {
            console.log(error);
            setDisabledBtn(false);
        }
    }

    const validate = ({
        companyName,
        urlName,
        firstEmail,
        secondEmail,
        phoneNumber,
        address,
        state,
        postcode,
        country,
        mainContact,
        city,
        admins
    }) => {
        const errors = {};
        if (!companyName) {
            errors.companyName = 'Please enter the company name';
        } else if (companyName.length > 50) {
            errors.companyName = 'Must be 50 characters or less';
        } else if (!/^[A-Za-z ]+$/i.test(companyName)) {
            errors.companyName = 'Accept only letter';
        }
        if (urlName && !/^[A-Za-z-]+$/i.test(urlName)) {
            errors.urlName = 'Accept only letter';
        }
        if (!firstEmail) {
            errors.firstEmail = 'Please enter the email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(firstEmail)) {
            errors.firstEmail = 'Please enter a valid email address';
        }
        if (secondEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(secondEmail)) {
            errors.secondEmail = 'Please enter a valid email address';
        }
        if (!phoneNumber) {
            errors.phoneNumber = 'Please enter the phone number';
        } else if (/^[A-Za-z]+$/i.test(phoneNumber)) {
            errors.phoneNumber = 'Only accept number';
        }
        if (address && !/^[A-Za-z0-9 -'/#.,]+$/i.test(address)) {
            errors.address = `Accept alphanumeric and special characters ( . , - ' / # )`;
        }
        if (city && !/^[A-Za-z -'/#.,]+$/i.test(city)) {
            errors.city = `Accept only letter and special characters ( . , - ' / # )`;
        }
        if (state && !/^[A-Za-z -'/#.,]+$/i.test(state)) {
            errors.state = `Accept only letter and special characters ( . , - ' / # )`;
        }
        if (postcode && !/^[A-Za-z0-9 /#-]+$/i.test(postcode)) {
            errors.postcode = `Accept alphanumeric and special characters ( - / # )`;
        }
        if (!country || country === 'Select') {
            errors.country = 'Please select the country';
        }
        if (dataCompany && !mainContact) {
            errors.mainContact = 'Please select the main contact';
        }
        if (admins.length > 2) {
            setErrorCheckBox('A company can have up to a maximum of 2 Administrators');
        } else {
            setErrorCheckBox('');
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            companyName: dataCompany?.name || '',
            urlName: dataCompany?.url_name || '',
            firstEmail: dataCompany?.first_email || '',
            firstEmailType: dataCompany?.first_email_type || '',
            secondEmail: dataCompany?.second_email || '',
            secondEmailType: dataCompany?.second_email_type || '',
            countryCode: dataCompany?.country_code || '',
            phoneNumber: dataCompany?.phone_number || '',
            phoneType: dataCompany?.phone_type || '',
            address: dataCompany?.address || '',
            state: dataCompany?.state || '',
            postcode: dataCompany?.postcode || '',
            country: dataCompany?.country || '',
            mainContact: dataCompany?.main_contact || '',
            city: dataCompany?.city || '',
            subscribedSolution: dataCompany?.subscribe_solution || '',
            subscribedPlan: dataCompany?.subscribe_plan || '',
            admins: dataCompany?.administrator_id ? JSON.parse(dataCompany?.administrator_id) : [],
        },
        validate,
        onSubmit: async ({
            companyName,
            urlName,
            firstEmail,
            firstEmailType,
            secondEmail,
            secondEmailType,
            countryCode,
            phoneNumber,
            phoneType,
            address,
            state,
            postcode,
            country,
            mainContact,
            city,
            subscribedSolution,
            subscribedPlan,
            admins
        }) => {
            const data = {
                name: companyName,
                main_contact: mainContact,
                logo_url: logoUrl,
                url_name: urlName,
                first_email: firstEmail,
                first_email_type: firstEmailType,
                second_email: secondEmail,
                second_email_type: secondEmailType,
                country_code: countryCode,
                phone_number: phoneNumber,
                phone_type: phoneType,
                address: address,
                state: state,
                country: country,
                city: city,
                subscribe_solution: subscribedSolution,
                subscribe_plan: subscribedPlan,
                postcode: postcode,
                admins: admins
            }
            try {
                if (errorCheckBox === '') {
                    setDisabledBtn(true);
                    if (dataCompany) {
                        const dataRes = await Companies.putUpdateCompany(dataCompany?.id, data);
                        if (dataRes?.data?.response) {
                            setDisabledBtn(false);
                            fetchLogCompany(page);
                            dataCallback(dataRes?.data?.meta?.status);
                            Swal.fire({
                                icon: 'success',
                                text: `Update Company ${data.name} success`,
                            });
                        }
                    } else {
                        const dataRes = await Companies.postCreateCompany(data);
                        if (dataRes?.data?.response) {
                            setDisabledBtn(false);
                            handleClose();
                            dataCallback(dataRes?.data?.meta?.status);
                            Swal.fire({
                                icon: 'success',
                                text: 'Create Company success',
                            });
                        }
                    }
                }
            } catch (e) {
                setDisabledBtn(false);
                if (e?.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    return navigate('/login');
                }
            }
        },
    });

    function CompanyShow() {
        if (arrCompanyShow?.contacts?.length > 0)
            return (
                <>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography component={'h5'}>EMPLOYEES</Typography>
                        <Divider sx={{ height: '0.1rem', backgroundColor: '#000', margin: 0 }} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TableContainer sx={{ boxShadow: 'unset' }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 600 }}>Name</TableCell>
                                        <TableCell sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 600 }}>Position</TableCell>
                                        <TableCell sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 600 }}>Administrator</TableCell>
                                    </TableRow>
                                    {arrCompanyShow?.contacts.map(({ id, first_name, surname, position, user }, index) => (
                                        <TableRow
                                            key={id}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 400 }}
                                            >
                                                {first_name} {surname}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 400 }}
                                            >
                                                {position}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title={!user ? 'Must have a user account' : ''}>
                                                    <FormControlLabel
                                                        control={<Checkbox />}
                                                        name="admins"
                                                        value={id}
                                                        checked={!!formik.values.admins.includes(`${id}`)}
                                                        onChange={formik.handleChange}
                                                        sx={{ fontSize: '0.75rem', textAlign: 'center', fontWeight: 400 }}
                                                        disabled={user ? disabledFld : true}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {errorCheckBox ? <FormHelperText error={true}>{errorCheckBox}</FormHelperText> : ''}
                    </Grid>
                </>
            );
        return '';
    }

    function ContentLog() {
        return (
            <>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {(logCompany?.data ?? []).map((item, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot color="info">
                                    <Create />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant='body2' sx={{ fontWeight: 500, fontSize: '0.75rem' }}>{item.staff.firstname} {item.staff.surname} - {item.type}</Typography>
                                    <Chip label={moment(item.created_at).format('DD-MMM-YYYY, H:m A')} variant="outlined" size="small" />
                                </Stack>
                                <Paper
                                    elevation={1}
                                    sx={{
                                        p: 1,
                                        mt: 1,
                                        borderRadius: '0.75rem',
                                        background: '#f0f2fc'
                                    }}
                                >
                                    <Box>
                                        {
                                            item.event.map((itemNote, index) => (
                                                <Typography sx={{ fontSize: '0.75rem', color: '#6c757d', fontWeight: 300 }} key={index} >{parse(itemNote)}</Typography>
                                            ))
                                        }
                                    </Box>
                                </Paper>

                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
                <Stack spacing={2} justifyContent="center" alignItems="center" m={3} pb={3}>
                    <Pagination count={logCompany.last_page} page={page} onChange={handleChangePagiNote} />
                </Stack>
            </>

        );
    }

    function EmailContent() {
        return fetchEmail.map((data, index) => (
            <List key={index} sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav" >
                <ListItemButton onClick={() => setOpenFetchEmail(openFetchEmail === index ? -1 : index)} sx={{ borderTop: '3px solid #2fc6f8', borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}>
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ fontSize: '1rem', width: '100%', color: '#2fc6f8' }}>
                        <Typography variant='body2' sx={{ fontSize: '0.75rem', fontWeight: 500 }}>{data?.created_by?.firstname} {data?.created_by?.surname}</Typography>
                        <Chip sx={{ fontSize: '0.75rem' }} label={moment(data?.created_at).format('DD-MMM-YYYY H:m a')} variant="outlined" size="small" />
                    </Stack>
                    {openFetchEmail === index ? <Close /> : <Add />}
                </ListItemButton>
                <Collapse in={openFetchEmail === index} timeout="auto" unmountOnExit>
                    <List component="div" sx={{ py: 1, px: 2 }}>
                        <Typography sx={{ fontSize: '0.75rem' }}><b>Subject:</b> {data?.subject}</Typography>
                        <Typography sx={{ fontSize: '0.75rem' }}><b>Message:</b> {parse(data?.body)}</Typography>
                    </List>
                </Collapse>
            </List >
        ));
    }

    return (
        <div>
            {dataCompany ? (
                <IconButton color="info" onClick={handleOpen}>
                    <FolderIcon />
                </IconButton>
            ) : (
                <MDButton fullWidth variant="contained" color="info" sx={{ fontWeight: 500 }} onClick={handleOpen}>
                    New Company
                </MDButton>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ height: '100%', width: { xs: '100%', md: '80%', lg: '80%' }, left: 'unset' }}
            >
                <Box sx={style} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2">
                                    Company
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={cssBodyModal} justifyContent="space-around">
                        <Grid item xs={12} md={dataCompany ? 7 : 12} lg={dataCompany ? 7 : 12}>
                            <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset' }}>
                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    {dataCompany ?
                                        <Grid item xs={12} md={6} lg={6}>
                                            <TextField
                                                fullWidth
                                                label="Company Code"
                                                id="companyCode"
                                                name="companyCode"
                                                value={companyCode}
                                                type="text"
                                                InputLabelProps={{ shrink: true, }}
                                                disabled={true}
                                            />
                                        </Grid>
                                        : <Grid item xs={12} md={6} lg={6}></Grid>}
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Logo"
                                                id="logoUrl"
                                                name="logoUrl"
                                                type="text"
                                                autoComplete="logoUrl"
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ readOnly: true, }}
                                                sx={{ '& .MuiInputBase-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                                                disabled={dataCompany ? disabledFld : false}
                                                value={logoUrl}
                                            />
                                            <label htmlFor="contained-button-file" style={{ margin: 0 }}>
                                                <Input accept="image/*" id="contained-button-file" type="file" name="logoUrl" onChange={(e) => uploadLogo(e)} sx={{ display: 'none' }} disabled={dataCompany ? disabledFld : false} />
                                                <MDButton fullWidth type="button" variant="contained" color="success" component="span" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minHeight: '2.7rem' }} disabled={dataCompany ? disabledFld : false}>
                                                    Browse
                                                </MDButton>
                                            </label>
                                        </Stack>
                                        {logoUrl ? <Avatar
                                            alt={logoUrl}
                                            src={logoUrl}
                                            sx={{ width: 56, height: 56, margin: 'auto', marginTop: '1rem' }}
                                        /> : ''}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Company Name(*)"
                                            id="companyName"
                                            name="companyName"
                                            value={formik.values.companyName}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                            helperText={formik.touched.companyName && formik.errors.companyName}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    {dataCompany ? <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Subscribed Solution"
                                            id="subscribedSolution"
                                            name="subscribedSolution"
                                            autoComplete="subscribedSolution"
                                            select
                                            value={formik.values.subscribedSolution}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputLabelProps={{ shrink: true, }}
                                            disabled={dataCompany ? disabledFld : false}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {/*{solutionComArr.map((option) => (*/}
                                            {/*    <MenuItem key={option.id} value={option.id}>*/}
                                            {/*        {option.name}*/}
                                            {/*    </MenuItem>*/}
                                            {/*))}*/}
                                        </TextField>
                                    </Grid> : ''}
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="URL"
                                            id="urlName"
                                            name="urlName"
                                            value={formik.values.urlName}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.urlName && Boolean(formik.errors.urlName)}
                                            helperText={formik.touched.urlName && formik.errors.urlName}
                                            type="text"
                                            InputLabelProps={{ shrink: true, }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    {dataCompany ? <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Subscribed Plan"
                                            id="subscribedPlan"
                                            name="subscribedPlan"
                                            autoComplete="subscribedPlan"
                                            select
                                            value={formik.values.subscribedPlan}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputLabelProps={{ shrink: true, }}
                                            disabled={dataCompany ? disabledFld : false}
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            {/*{planComArr.map((option) => (*/}
                                            {/*    <MenuItem key={option.id} value={option.id}>*/}
                                            {/*        {option.name}*/}
                                            {/*    </MenuItem>*/}
                                            {/*))}*/}
                                        </TextField>
                                    </Grid> : ''}
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Email 1(*)"
                                            id="firstEmail"
                                            name="firstEmail"
                                            value={formik.values.firstEmail}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstEmail && Boolean(formik.errors.firstEmail)}
                                            helperText={formik.touched.firstEmail && formik.errors.firstEmail}
                                            type="text"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            id="firstEmailType"
                                            name="firstEmailType"
                                            autoComplete="firstEmailType"
                                            select
                                            value={formik.values.firstEmailType}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            disabled={dataCompany ? disabledFld : false}
                                        >

                                            {emailTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Email 2"
                                            id="secondEmail"
                                            name="secondEmail"
                                            value={formik.values.secondEmail}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.secondEmail && Boolean(formik.errors.secondEmail)}
                                            helperText={formik.touched.secondEmail && formik.errors.secondEmail}
                                            type="text"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            id="secondEmailType"
                                            name="secondEmailType"
                                            autoComplete="secondEmailType"
                                            select
                                            value={formik.values.secondEmailType}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            disabled={dataCompany ? disabledFld : false}
                                        >

                                            {emailTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <TextField
                                            fullWidth
                                            label="Country Code"
                                            id="countryCode"
                                            name="countryCode"
                                            autoComplete="countryCode"
                                            select
                                            value={formik.values.countryCode}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputLabelProps={{ shrink: true, }}
                                            disabled={dataCompany ? disabledFld : false}
                                        >
                                            {countryPhonePrefixCode.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <TextField
                                            fullWidth
                                            label="Phone(*)"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="number"
                                            value={formik.values.phoneNumber}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <TextField
                                            fullWidth
                                            id="phoneType"
                                            name="phoneType"
                                            autoComplete="phoneType"
                                            select
                                            value={formik.values.phoneType}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            disabled={dataCompany ? disabledFld : false}
                                        >
                                            {phoneTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        {dataCompany ?
                                            <TextField
                                                fullWidth
                                                label="Main Contact for this company(*)"
                                                id="mainContact"
                                                name="mainContact"
                                                autoComplete="mainContact"
                                                select
                                                value={formik.values.mainContact}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                error={formik.touched.mainContact && Boolean(formik.errors.mainContact)}
                                                helperText={formik.touched.mainContact && formik.errors.mainContact}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={dataCompany ? disabledFld : false}
                                            >
                                                <MenuItem value="">Select</MenuItem>
                                                {dataCompany?.contacts.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.first_name} {option.surname}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            : ''}
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography component={'h5'}>BILLING ADDRESS</Typography>
                                        <Divider sx={{ height: '0.1rem', backgroundColor: '#000', margin: 0 }} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Address"
                                            id="address"
                                            name="address"
                                            value={formik.values.address}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.address && Boolean(formik.errors.address)}
                                            helperText={formik.touched.address && formik.errors.address}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Suburb / City"
                                            id="city"
                                            name="city"
                                            value={formik.values.city}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.city && Boolean(formik.errors.city)}
                                            helperText={formik.touched.city && formik.errors.city}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Postcode"
                                            id="postcode"
                                            name="postcode"
                                            value={formik.values.postcode}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                                            helperText={formik.touched.postcode && formik.errors.postcode}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="State"
                                            id="state"
                                            name="state"
                                            value={formik.values.state}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.state && Boolean(formik.errors.state)}
                                            helperText={formik.touched.state && formik.errors.state}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Country(*)"
                                            id="country"
                                            name="country"
                                            autoComplete="country"
                                            select
                                            value={formik.values.country}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.country && Boolean(formik.errors.country)}
                                            helperText={formik.touched.country && formik.errors.country}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={dataCompany ? disabledFld : false}
                                        >
                                            {countries.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <CompanyShow />
                                </Grid>
                            </Paper>
                        </Grid>

                        {dataCompany ?
                            <Grid item xs={12} md={5} lg={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset', justifyContent: 'space-between' }}>
                                            <label htmlFor="contained-button-file-contract" style={{ margin: 0 }}>
                                                <Input accept="*/*" id="contained-button-file-contract" type="file" name="contract" onChange={(e) => uploadContract(e)} sx={{ display: 'none' }} />
                                                <MDButton type="button" variant="contained" color="light" component="span">
                                                    Upload Contract
                                                </MDButton>
                                            </label>
                                            <ModalContract id={dataCompany?.id} data={dataCompany.contracts} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset' }}>
                                            <TabContext value={valueTabEmail}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleChangeTabEmail} sx={{ '& .Mui-selected': { color: '#fff !important', backgroundColor: '#0288D1' } }}>
                                                        <Tab sx={{ fontSize: '0.75rem' }} label="Email" value="email" />
                                                        <Tab sx={{ fontSize: '0.75rem' }} label="Check Emails" value="check" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="email" sx={{ fontSize: '1rem', padding: '1rem 0.5rem' }}>
                                                    <TextField
                                                        id="outlined-multiline-subject"
                                                        label="Subject"
                                                        value={subjectEmail}
                                                        onChange={(e) => setSubjectEmail(e.target.value)}
                                                        error={errSubjectEmail}
                                                        helperText={errTxtSubjectEmail}
                                                        multiline
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                    />
                                                    <ReactQuill modules={modules} theme="snow" placeholder="Message here..." value={messageEmail} onChange={setMessageEmail} />
                                                    {errMessageEmail ? <FormHelperText error={true}>{errTxtMessageEmail}</FormHelperText> : ''}
                                                    <MDButton variant="outlined" color="info" sx={{ mt: 2 }} size="small" onClick={sendEmail} disabled={disabledBtn}>Send Email</MDButton>
                                                </TabPanel>
                                                <TabPanel value="check" sx={{ padding: '1rem 0.5rem' }}>
                                                    <EmailContent />
                                                </TabPanel>
                                            </TabContext>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset' }}>
                                            <TabContext value={valueTab}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleChangeTab} sx={{ '& .Mui-selected': { color: '#fff !important', backgroundColor: '#0288D1' } }}>
                                                        <Tab sx={{ fontSize: '0.75rem' }} label="Notes" value="notes" />
                                                        <Tab sx={{ fontSize: '0.75rem' }} label="Activity" value="activity" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value="notes" sx={{ fontSize: '1rem', padding: '1rem 0.5rem' }}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="Note"
                                                        placeholder="Note here"
                                                        value={txtComment}
                                                        onChange={(e) => setTxtComment(e.target.value)}
                                                        error={errNote}
                                                        helperText={txtErrNote}
                                                        multiline
                                                        fullWidth
                                                        rows={4}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <MDButton variant="outlined" color="info" sx={{ mt: 2 }} size="small" onClick={saveNote} disabled={disabledBtn}>Save Note</MDButton>
                                                    <Timeline sx={{ p: 0, pt: 4, '& .MuiTimelineItem-root:before': { content: 'unset' } }}>
                                                        {arrComment ? arrComment.map((commentDetail, index) => (
                                                            <TimelineItem key={index}>
                                                                <TimelineSeparator>
                                                                    <TimelineDot color="info">
                                                                        <NoteAlt />
                                                                    </TimelineDot>
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent>
                                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                                        <Typography variant='body2' sx={{ fontSize: '0.75rem', fontWeight: 500 }}>{commentDetail?.comment_by?.firstname} {commentDetail?.comment_by?.surname}</Typography>
                                                                        <Chip sx={{ fontSize: '0.75rem' }} label={moment(commentDetail?.created_at).format('DD-MMM-YYYY, H:m A')} variant="outlined" size="small" />
                                                                    </Stack>
                                                                    <Paper
                                                                        elevation={1}
                                                                        sx={{
                                                                            p: 1,
                                                                            mt: 1,
                                                                            borderRadius: '0.75rem',
                                                                            background: '#f0f2fc'
                                                                        }}
                                                                    >
                                                                        <Typography variant='body2' sx={{ fontSize: '0.75rem', }}>{commentDetail?.content}</Typography>
                                                                    </Paper>

                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        )) : ''}
                                                    </Timeline>
                                                </TabPanel>
                                                <TabPanel value="activity" sx={{ fontSize: '1rem', padding: '1rem 0.5rem' }}>
                                                    <Paper
                                                        sx={{
                                                            p: 0,
                                                            mt: 2,
                                                            borderRadius: '0.75rem',
                                                            boxShadow: 'unset'
                                                        }}
                                                    >
                                                        {logCompany.total > 0
                                                            ?
                                                            <ContentLog></ContentLog>
                                                            :
                                                            <Typography variant='body2' align='center'>No Activities</Typography>
                                                        }
                                                    </Paper>
                                                </TabPanel>
                                            </TabContext>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''}
                    </Grid>

                    <Grid container px={4} py={2} mt={1} justifyContent="flex-end" sx={{ backgroundColor: '#fff', boxShadow: '0 0px 5px -2px gray!important' }}>
                        {
                            dataCompany
                                ?
                                <Grid item xs={12} md={3} lg={2} textAlign={'right'}>
                                    {
                                        disabledFld
                                            ?
                                            <LoadingButton
                                                onClick={handleClickEdit}
                                                loading={loading}
                                                variant="contained"
                                                sx={{ width: '50%', color: '#fff' }}
                                            // onClick={(e) => { setDisabledFld(false) }}
                                            >
                                                Edit
                                            </LoadingButton>
                                            :
                                            <Stack direction="row" spacing={2}>
                                                <MDButton type="submit" fullWidth variant="contained" color="info" disabled={disabledBtn}>
                                                    Save
                                                </MDButton>
                                                <MDButton type="button" fullWidth variant="contained" color="cancel" onClick={(e) => {
                                                    setLoading(false);
                                                    setDisabledFld(true);
                                                }}>
                                                    Cancel
                                                </MDButton>
                                            </Stack>
                                    }
                                </Grid>
                                :
                                <Grid item xs={12} md={3} lg={2}>
                                    <MDButton type="submit" fullWidth variant="contained" color="info" disabled={disabledBtn}>
                                        Save
                                    </MDButton>
                                </Grid>
                        }
                    </Grid>
                </Box>
            </Modal >
        </div >
    );
}
export default ModalDetail;
