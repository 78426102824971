
import * as React from 'react';
import ListCompany from 'modules/company_crm/components/listCompany';
import { useNavigate } from 'react-router-dom';

function Index() {
    const navigate = useNavigate();
    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
    }, [navigate]);


    return (
        <ListCompany />
    )
}

export default Index;