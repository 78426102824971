import * as api from 'modules/contact_crm/xhr';
import endPoint from "modules/contact_crm/xhr/endPoint";
import { getURL } from "modules/contact_crm/xhr/endPoint";

export const getListContact = async () => {
    return await api.get(getURL(endPoint.CONTACT_LIST));
}

export const postCreateContact = async data => {
    return await api.post(getURL(endPoint.CONTACT_STORE), data);
}

export const putUpdateContact = async (id, data) => {
    return await api.put(getURL(endPoint.CONTACT_UPDATE + id), data);
}

export const deleteUpdateContactList = async id => {
    return await api.deleteContact(getURL(endPoint.CONTACT_DELETE + id));
}

export const postNoteContact = async (id, data) => {
    return await api.post(getURL(endPoint.CONTACT_NOTE + id), data);
}

export const getContactDetails = async (id) => {
    return await api.get(getURL(endPoint.CONTACT_SHOW + id));
}

export const getLogDetails = async (id, params) => {
    return await api.getLog(getURL(endPoint.CONTACT_LOG + id), params);
}

export const getDataCompanySelect = async () => {
    return await api.get(getURL(endPoint.CONTACT_DATA_COMPANY_SELECT));
}

export const postSendEmail = async (id, data) => {
    return await api.post(getURL(endPoint.CONTACT_SEND_EMAIL + id), data);
}

export const fetchEmail = async (id) => {
    return await api.get(getURL(endPoint.CONTACT_FETCH_EMAIL + id));
}

export const createUser = async (data) => {
    return await api.post(getURL(endPoint.CONTACT_CREATE_USER), data);
}