import { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { getMenu, updateMenuPosition } from "../repositories";

const byString = (o, s) => {
  if (s === "") return o;
  s = s.replace(/\[(\w+)\]/g, ".$1");
  s = s.replace(/^\./, "");
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

// const moveMenu = (arr, old_index, new_index) => {
//   if (new_index >= arr.length) {
//     var k = new_index - arr.length + 1;
//     while (k--) {
//       arr.push(undefined);
//     }
//   }
//   arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
// };

const useMenuManage = (props) => {
  const [menu, setMenu] = useState([]);

  const handleGetMenu = useCallback(async () => {
    try {
      const data = await getMenu();
      setMenu(Array.isArray(data?.data?.response) ? data?.data?.response : []);
    } catch (error) {}
  }, []);

  const flatMenu = useCallback((menu, lever = 0) => {
    return menu.reduce((prev, { id, name, child }) => {
      prev.push({ value: id, label: `${"     ".repeat(lever)}${name}` });
      if (child.length) prev.push(...flatMenu(child, lever + 1));
      return prev;
    }, []);
  }, []);

  const parrentOptions = useMemo(() => flatMenu(menu), [flatMenu, menu]);

  const handleDrop = useCallback(
    async (dropZoneData, menuData) => {
      try {
        const currentDropzone = byString(
          menu,
          dropZoneData.parentPath.slice(0, dropZoneData.parentPath.length - 6)
        );
        const currentMenu = byString(menu, menuData.currentPath);

        await updateMenuPosition(currentMenu.id, {
          parent_id: currentDropzone.id,
          order: dropZoneData.index + 1,
        });
        await handleGetMenu();
        Swal.fire({
          icon: "success",
          text: "Update menu success",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {}

      // setMenu((prevMenu) => {
      //   let tempMenu = [...prevMenu];

      //   if (
      //     menuData.lever === dropZoneData.lever &&
      //     dropZoneData.parentPath.startsWith(
      //       menuData.currentPath.slice(0, dropZoneData.parentPath.length)
      //     )
      //   ) {
      //     moveMenu(
      //       byString(tempMenu, menuData.parentPath),
      //       menuData.index,
      //       dropZoneData.index - (menuData.index < dropZoneData.index ? 1 : 0)
      //     );
      //   } else {
      //     const dropZoneParrent = byString(tempMenu, dropZoneData.parentPath);
      //     const menuParent = byString(tempMenu, menuData.parentPath);
      //     dropZoneParrent.splice(dropZoneData.index, 0, menuData.item);
      //     menuParent.splice(menuData.index, 1);
      //   }

      //   return tempMenu;
      // });
    },
    [handleGetMenu, menu]
  );

  useEffect(() => {
    handleGetMenu();
  }, [handleGetMenu]);

  return {
    ...props,
    menu,
    parrentOptions,
    handleGetMenu,
    handleDrop,
  };
};

export default useMenuManage;
