import * as React from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

import { useMaterialUIController } from "context";
import { Collapse, List } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// react-router-dom components
import { NavLink } from "react-router-dom";

function SidenavCollapse({ icon, name, active, myKey, child, route, collapseName, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const [open, setOpen] = React.useState(false);
  const [openSecondLevel, setOpenSecondLevel] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickSecondLevel = () => {
    setOpenSecondLevel(!openSecondLevel);
  };

  function checkActive(k, coll) {
    if (k === coll) {
      return true;
    }
    return false;
  }

  return (
    <>
      <NavLink key={myKey} to={route}>
        <ListItem component="li" onClick={handleClick}>
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active: checkActive(myKey, collapseName),
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: checkActive(myKey, collapseName) })
              }
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active: checkActive(myKey, collapseName) })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>

            <ListItemText
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active: checkActive(myKey, collapseName),
                })
              }
            />
            {child ? open ? <ExpandLess /> : <ExpandMore /> : ''}
          </MDBox>
        </ListItem>
      </NavLink>
      {child ? child.map((data, ind) => {
        return <Collapse key={ind} in={open} timeout="auto" unmountOnExit>
          <List component="div">
            <NavLink to={data.route}>
              <ListItem component="li" onClick={data.child ? handleClickSecondLevel : null}>
                <MDBox
                  {...rest}
                  sx={(theme) =>
                    collapseItem(theme, {
                      active: checkActive(data.key, collapseName),
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      sidenavColor,
                    })
                  }
                >
                  <ListItemIcon
                    sx={(theme) =>
                      collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: checkActive(data.key, collapseName) })
                    }
                  >
                    {typeof data.icon === "string" ? (
                      <Icon sx={(theme) => collapseIcon(theme, { active: checkActive(data.key, collapseName) })}>{data.icon}</Icon>
                    ) : (
                      data.icon
                    )}
                  </ListItemIcon>

                  <ListItemText
                    primary={data.name}
                    sx={(theme) =>
                      collapseText(theme, {
                        miniSidenav,
                        transparentSidenav,
                        whiteSidenav,
                        active: checkActive(data.key, collapseName),
                      })
                    }
                  />
                  {data.child ? openSecondLevel ? <ExpandLess /> : <ExpandMore /> : ''}
                </MDBox>
              </ListItem>
            </NavLink>
            {data.child ? data.child.map((dataSec, inde) => {
              return <Collapse key={inde} in={openSecondLevel} timeout="auto" unmountOnExit>
                <List component="div">
                  <NavLink to={dataSec.route}>
                    <ListItem component="li">
                      <MDBox
                        {...rest}
                        sx={(theme) =>
                          collapseItem(theme, {
                            active: checkActive(dataSec.key, collapseName),
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                            sidenavColor,
                          })
                        }
                      >
                        <ListItemIcon
                          sx={(theme) =>
                            collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active: checkActive(dataSec.key, collapseName) })
                          }
                        >
                          {typeof dataSec.icon === "string" ? (
                            <Icon sx={(theme) => collapseIcon(theme, { active: checkActive(dataSec.key, collapseName) })}>{dataSec.icon}</Icon>
                          ) : (
                            dataSec.icon
                          )}
                        </ListItemIcon>

                        <ListItemText
                          primary={dataSec.name}
                          sx={(theme) =>
                            collapseText(theme, {
                              miniSidenav,
                              transparentSidenav,
                              whiteSidenav,
                              active: checkActive(dataSec.key, collapseName),
                            })
                          }
                        />
                      </MDBox>
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse>
            }) : ''}
          </List>
        </Collapse>
      }) : ''}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
