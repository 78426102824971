import Axios from "axios";

const createAxiosInstance = (configHeader) => {
  return Axios.create({
    headers: { ...configHeader },
  });
};

async function getMethod(url, params = {}) {
  const axios = createAxiosInstance({
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  });
  let request = await axios.get(url);
  return request;
}

async function postMethod(url, data) {
  const axios = createAxiosInstance({
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  });
  let request = await axios.post(url, data);
  return request;
}

async function putMethod(url, data) {
  const axios = createAxiosInstance({
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  });
  let request = await axios.put(url, data);
  return request;
}

async function deleteMethod(url) {
  const axios = createAxiosInstance({
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  });
  let request = await axios.delete(url);
  return request;
}

const api = { getMethod, postMethod, putMethod, deleteMethod };

export default api;
