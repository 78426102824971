import * as React from 'react';
import { Divider, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HeaderFooterTemplate from 'modules/standard_template/components/headerFooterTemplate';
import BodyTemplate from 'modules/standard_template/components/bodyTemplate';

function Index() {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <HeaderFooterTemplate />
                </Grid>
                <Divider />
                <Grid item xs={12} md={12} lg={12}>
                    <BodyTemplate />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Index;