import * as MuiIcons from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import { Grid, ListItemIcon, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FixedSizeList } from "react-window";
import Swal from "sweetalert2";
import { createMenu, updateMenu } from "../repositories";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
  minWidth: 650,
};

const KEY_MUI_ICONS = Object.keys(MuiIcons);
const ICON_OPTION_HEIGHT = 35;

function ModalDetailMenu({ parrentOptions, menu, parentCallback }) {
  const [open, setOpen] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [filterIcon, setFilterIcon] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const swalModal = (type, message) => {
    return Swal.fire({
      icon: type,
      text: message,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const keyMuiIcons = React.useMemo(
    () =>
      KEY_MUI_ICONS.filter((item) =>
        item.toLowerCase().includes(filterIcon.toLowerCase())
      ),
    [filterIcon]
  );

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Please enter the name";
    } else if (!/^[A-Za-z0-9 _]+$/i.test(values.name)) {
      errors.name = "Accept numbers and letters";
    }

    if (!values.url) {
      errors.url = "Please enter the URL";
    }

    if (!values.icon) {
      errors.icon = "Please enter the icon";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      parent_id: menu?.parent_id || 0,
      icon: menu?.icon || "",
      url: menu?.url || "",
      name: menu?.name || "",
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      try {
        setDisabledBtn(true);
        if (menu) {
          const dataRes = await updateMenu(menu.id, values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Update menu success");
          }
        } else {
          const dataRes = await createMenu(values);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            resetForm();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Create menu success");
          }
        }
      } catch (e) {
        setDisabledBtn(false);
        if (e?.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          return navigate("/login");
        }
      }
    },
  });

  return (
    <div>
      {menu ? (
        <IconButton color="info" onClick={handleOpen}>
          <FolderIcon />
        </IconButton>
      ) : (
        <MDButton
          fullWidth
          variant="contained"
          color="info"
          sx={{ fontWeight: 500, width: "max-content" }}
          onClick={handleOpen}
        >
          Add Menu
        </MDButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h4" component="h2">
              Menu
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            justifyContent={"center"}
            sx={{
              mt: 2,
              mb: 2,
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Parent(*)"
                id="parent_id"
                name="parent_id"
                select
                value={formik.values.parent_id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.parent_id && Boolean(formik.errors.parent_id)
                }
                helperText={formik.touched.parent_id && formik.errors.parent_id}
                InputLabelProps={{
                  shrink: true,
                }}
                // SelectProps={{ multiple: true }}
              >
                <MenuItem key={0} value={0}>
                  All
                </MenuItem>
                {parrentOptions.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    sx={{ whiteSpace: "pre" }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Icon(*)"
                id="icon"
                name="icon"
                select
                value={formik.values.icon}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.icon && Boolean(formik.errors.icon)}
                helperText={formik.touched.icon && formik.errors.icon}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value={formik.values.icon} sx={{ display: "none" }}>
                  {formik.values.icon}
                </MenuItem>

                <Box sx={{ my: 2 }}>
                  <TextField
                    fullWidth
                    label="Filter"
                    id="filter"
                    name="filter"
                    onChange={(e) => {
                      setFilterIcon(e.target.value);
                    }}
                    value={filterIcon}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                <Box>
                  <FixedSizeList
                    height={ICON_OPTION_HEIGHT * 9}
                    itemSize={ICON_OPTION_HEIGHT}
                    overscanCount={5}
                    itemCount={keyMuiIcons.length}
                    initialScrollOffset={
                      keyMuiIcons.findIndex(
                        (item) => formik.values.icon === item
                      ) *
                        ICON_OPTION_HEIGHT -
                      4 * ICON_OPTION_HEIGHT
                    }
                  >
                    {({ index, style }) => {
                      return (
                        <MenuItem
                          key={keyMuiIcons[index]}
                          value={keyMuiIcons[index]}
                          sx={{ whiteSpace: "pre" }}
                          style={style}
                          onClick={(e) => {
                            formik.setFieldValue("icon", keyMuiIcons[index]);
                          }}
                          selected={formik.values.icon === keyMuiIcons[index]}
                        >
                          <ListItemIcon>
                            {React.createElement(MuiIcons[keyMuiIcons[index]])}
                          </ListItemIcon>
                          {keyMuiIcons[index]}
                        </MenuItem>
                      );
                    }}
                  </FixedSizeList>
                </Box>
              </TextField>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="URL(*)"
                id="url"
                name="url"
                value={formik.values.url}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Name(*)"
                id="name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container pt={3} justifyContent="center">
            <Grid item xs={12} md={3} lg={2}>
              <MDButton
                type="submit"
                fullWidth
                variant="contained"
                color="info"
                sx={{ fontWeight: 500 }}
                disabled={disabledBtn}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalDetailMenu;
