/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Grid, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Skeleton } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MDBox from 'components/MDBox';
import ModalTemplate from './modalTemplate';
import { useNavigate } from 'react-router-dom';
import * as Master from 'modules/standard_template/repositories';
import moment from 'moment';
import ModalDelete from 'modules/standard_template/components/modalDelete';
import ModalPreview from 'modules/standard_template/components/modalPreview';

function BodyTemplate() {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);
    const [loadingTable, setLoadingTable] = React.useState(true);

    const fetchData = async () => {
        try {
            let bodyRes = await Master.getListBody();
            if (bodyRes?.data?.response) {
                setLoadingTable(false);
                return setListData(bodyRes?.data?.response);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    const handleCallback = (status) => {
        if (status === 200) fetchData();
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <MDBox>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset',
                            width: 'max-content'
                        }}
                    >
                        <ModalTemplate parentCallback={handleCallback} />
                    </Paper>
                </MDBox>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange}>
                                <Tab sx={{ fontSize: '0.75rem', fontWeight: 500 }} label="Email Template" value="1" />
                                <Tab sx={{ fontSize: '0.75rem', fontWeight: 500 }} label="Invoice Template" value="2" />
                                <Tab sx={{ fontSize: '0.75rem', fontWeight: 500 }} label="Form/Document Template" value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
                            {!loadingTable ?
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>#</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Name</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Group Type</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Master</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Created At</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500, width: '10rem' }}>Action</TableCell>
                                            </TableRow>
                                            {listData?.map((e, i) => (
                                                e?.type === 'email' ?
                                                    <TableRow key={i}>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{i + 1}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.type === 'email' ? 'Email' : e?.type === 'invoice' ? "Invoice" : 'Form/Document'}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.master_email_template?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{moment(e?.created_at).format('DD-MMM-YYYY')}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <ModalTemplate dataBody={e} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalDelete id={e?.id} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalPreview id={e?.id} formPreview={'body'} />
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow> : ''
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Paper
                                    sx={{
                                        p: 3,
                                        borderRadius: '0.75rem',
                                        boxShadow: 'unset'
                                    }}
                                >
                                    <Box>

                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                    </Box>
                                </Paper>
                            }
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
                            {!loadingTable ?
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>#</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Name</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Group Type</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Master</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Created At</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500, width: '10rem' }}>Action</TableCell>
                                            </TableRow>
                                            {listData?.map((e, i) => (
                                                e?.type === 'invoice' ?
                                                    <TableRow key={i}>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{i + 1}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.type === 'email' ? 'Email' : e?.type === 'invoice' ? "Invoice" : 'Form/Document'}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.master_email_template?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{moment(e?.created_at).format('DD-MMM-YYYY')}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <ModalTemplate dataBody={e} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalDelete id={e?.id} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalPreview id={e?.id} formPreview={'body'} />
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow> : ''
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Paper
                                    sx={{
                                        p: 3,
                                        borderRadius: '0.75rem',
                                        boxShadow: 'unset'
                                    }}
                                >
                                    <Box>

                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                    </Box>
                                </Paper>
                            }
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0, pt: 1 }}>
                            {!loadingTable ?
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>#</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Name</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Group Type</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Template Master</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Created At</TableCell>
                                                <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500, width: '10rem' }}>Action</TableCell>
                                            </TableRow>
                                            {listData?.map((e, i) => (
                                                e?.type === 'form/document' ?
                                                    <TableRow key={i}>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{i + 1}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.type === 'email' ? 'Email' : e?.type === 'invoice' ? "Invoice" : 'Form/Document'}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e?.master_email_template?.name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{moment(e?.created_at).format('DD-MMM-YYYY')}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    <ModalTemplate dataBody={e} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalDelete id={e?.id} parentCallback={handleCallback} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <ModalPreview id={e?.id} formPreview={'body'} />
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow> : ''
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <Paper
                                    sx={{
                                        p: 3,
                                        borderRadius: '0.75rem',
                                        boxShadow: 'unset'
                                    }}
                                >
                                    <Box>

                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                        <Skeleton animation="wave" height={60} />
                                    </Box>
                                </Paper>
                            }
                        </TabPanel>
                    </TabContext>
                </Box>
            </Grid>
        </Grid>
    );
}

export default BodyTemplate;