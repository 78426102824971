/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Grid, TableContainer, Table, TableRow, TableCell, TableBody, Paper, Box, Skeleton } from "@mui/material";
import ModalMaster from 'modules/standard_template/components/modalMaster';
import MDBox from 'components/MDBox';
import { useNavigate } from 'react-router-dom';
import * as Master from 'modules/standard_template/repositories';
import ModalPreview from 'modules/standard_template/components/modalPreview';

function HeaderFooterTemplate() {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);
    const [loadingTable, setLoadingTable] = React.useState(true);

    const fetchData = async () => {
        try {
            let masterRes = await Master.getListMaster();
            if (masterRes?.data?.response) {
                setLoadingTable(false);
                return setListData(masterRes?.data?.response);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    const handleCallback = (status) => {
        if (status === 200) fetchData();
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <MDBox>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            borderRadius: '0.75rem',
                            boxShadow: 'unset',
                            width: 'max-content'
                        }}
                    >
                        <ModalMaster parentCallback={handleCallback} />
                    </Paper>
                </MDBox>
            </Grid>
            <Grid item xs={12}>
                {!loadingTable ?
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>#</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Name</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500 }}>Description</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.75rem', fontWeight: 500, width: '10rem' }}>Action</TableCell>
                                </TableRow>
                                {listData?.map((e, i) => (
                                    <TableRow key={i}>
                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{i + 1}</TableCell>
                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e.name}</TableCell>
                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>{e.description}</TableCell>
                                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <ModalMaster dataMaster={e} header={JSON.parse(e?.data_header)} footer={JSON.parse(e?.data_footer)} parentCallback={handleCallback} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ModalPreview id={e?.id} formPreview={'master'} />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Paper
                        sx={{
                            p: 3,
                            borderRadius: '0.75rem',
                            boxShadow: 'unset'
                        }}
                    >
                        <Box>

                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                            <Skeleton animation="wave" height={60} />
                        </Box>
                    </Paper>
                }
            </Grid>
        </Grid>
    );
}

export default HeaderFooterTemplate;