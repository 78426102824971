import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from 'react-router-dom';
import DataTable from 'examples/Tables/DataTable';
import moment from 'moment/moment';
import * as StaffData from 'modules/platform_user/repositories';
import ModalActive from 'modules/platform_user/components/modalActive';
import ModalStaff from 'modules/platform_user/components/modalStaff';
import UpdateRoles from 'modules/platform_user/components/updateRoles';
import ModalDelete from 'modules/platform_user/components/modalDelete';

function Index() {
    const navigate = useNavigate();
    const [listData, setListData] = React.useState([]);

    async function getStaffUsers() {
        try {
            let data = await StaffData.getListStaff();
            return setListData(data?.data?.response);
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    }

    const handleCallback = (status) => {
        if (status === 200) getStaffUsers();
    }

    const columns = [
        { Header: "#", accessor: "no", align: "center" },
        { Header: "Name", accessor: "name", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Create Date", accessor: "created_at", align: "left" },
        { Header: "Role", accessor: "role", align: "left" },
        { Header: "action", accessor: "action", align: "center" },
    ];
    const rows = [];

    listData.map((data, index) => {
        return rows.push(
            {
                no: index + 1,
                name: data?.firstname + ' ' + data?.surname,
                email: data?.email,
                created_at: moment(data?.created_at).format('DD-MMM-YYYY'),
                role: <UpdateRoles id={data?.id} roleUser={data?.roles} parentCallback={handleCallback} />,
                action: (
                    <Grid container spacing={2}>
                        <Grid item>
                            <ModalDelete id={data?.id} parentCallback={handleCallback} />
                        </Grid>
                        <Grid item>
                            <ModalActive id={data?.id} active={data?.is_active} parentCallback={handleCallback} />
                        </Grid>
                    </Grid>
                ),
            }
        );
    });

    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
        getStaffUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MDBox>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                borderRadius: '0.75rem',
                                boxShadow: 'unset'
                            }}
                        >
                            <ModalStaff parentCallback={handleCallback} />
                            <DataTable
                                table={{ columns: columns, rows: rows }}
                                isSorted={true}
                                canSearch={true}
                                noEndBorder
                            />
                        </Paper>
                    </MDBox>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Index;