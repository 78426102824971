import { Button, Paper, Grid, Typography, Box } from '@mui/material';
import FormLogin from './formLogin';
import ForgotPassword from './formForgotPassword';
import { useLocation } from 'react-router-dom'

function LayoutRight() {
    const location = useLocation();
    return (
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square style={{ height: '100vh' }}>
            <Grid item sx={{ m: 0, height: '20vh' }}></Grid>
            <Grid item sx={{ m: 0, height: '70vh' }}>
                <Box
                    sx={{
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{
                            fontSize: '25px', fontWeight: 600, pb: 5
                        }}
                    >
                        {location.pathname === '/login' ? 'TKG Platform Login' : 'Reset Password'}
                    </Typography>
                    {location.pathname === '/login' ? <FormLogin /> : <ForgotPassword />}
                </Box>
            </Grid>
            <Grid item sx={{ m: 0, height: '10vh' }}>
                <Grid container justifyContent={"center"} width={"-webkit-fill-available"}>
                    <Grid item px={3}>
                        <Typography
                            sx={{
                                textDecoration: 'none',
                                fontWeight: 600,
                                fontSize: '22px',
                                color: '#000'
                            }}
                            gutterBottom
                        >
                            Need help?
                        </Typography>
                    </Grid>
                    <Grid item alignSelf={'center'} px={3}>
                        <Button
                            sx={{ borderRadius: '22px', fontSize: '18px', border: '1px solid black', color: 'black !important', px: 2, py: 0.5 }}
                        >
                            +61 8 7082 5321
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default LayoutRight;