import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import Iframe from 'react-iframe'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: '90%'
};

function ModalViewFile({ dataFile, index }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true); }
    const handleClose = () => setOpen(false);

    return (
        <Typography sx={{ float: 'left' }}>
            <Button onClick={handleOpen} sx={{ fontSize: '0.75rem', lineBreak: 'anywhere', padding: 0 }}>{index + 1 + '. ' + dataFile.file_name}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2" sx={{ lineBreak: 'anywhere' }}>
                                    {dataFile?.file_name + '-' + moment(dataFile?.created_at).format('DD-MMM-YYYY H:m')}
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <Close sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        maxHeight: 'calc(100vh - 9.5rem)',
                        overflow: 'hidden',
                    }}>
                        <Iframe
                            title={dataFile?.file_name + '-' + moment(dataFile?.created_at).format('DD-MMM-YYYY')}
                            url={dataFile?.file_url}
                            styles={{ height: '100vh', width: '100%' }}
                        />
                    </Grid>
                </Box>
            </Modal>
        </Typography >
    );
}

export default ModalViewFile