import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    'COMPANY_LIST': 'company',
    'COMPANY_STORE': 'company/store',
    'COMPANY_UPDATE': 'company/update/',
    'COMPANY_SHOW': 'company/show/',
    'COMPANY_DELETE': 'company/delete/',
    'COMPANY_STORE_COMMENT': 'company/store-comment',
    'COMPANY_UPLOAD_CONTRACT': 'company/upload-contract',
    'COMPANY_SEND_EMAIL': 'company/send-email',
    'COMPANY_FETCH_EMAIL': 'company/fetch-email/',
    'COMPANY_UPLOAD_IMAGE': 'upload/image',
    'COMPANY_DELETE_CONTRACT': 'company/delete-contract/',
    'COMPANY_SOLUTION_PLAN': 'solution-plan-access/solutions',
    'COMPANY_LOG': 'company/logs/',
    'COMPANY_DEACTIVE': 'company/deactivate/',
    'COMPANY_ACTIVE': 'company/active/',
}

export const getURL = (name, routeParams = {}, queryParam = {}) => {
    const pattern = new UrlPattern(`${name}`);
    let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
    let mappingParams = new URLSearchParams();
    return `${url.origin}${url.pathname}?${mappingParams}`;
}

export default endPoint;
