import * as api from 'modules/company_crm/xhr';
import endPoint from "modules/company_crm/xhr/endPoint";
import { getURL } from "modules/company_crm/xhr/endPoint";

export const getListCompany = async (data) => {
    return await api.getListData(getURL(endPoint.COMPANY_LIST), data);
}

export const getShowCompany = async (id) => {
    return await api.get(getURL(endPoint.COMPANY_SHOW + id));
}

export const getSolutionPlan = async (id) => {
    return await api.get(getURL(endPoint.COMPANY_SOLUTION_PLAN));
}

export const fetchEmail = async (id) => {
    return await api.get(getURL(endPoint.COMPANY_FETCH_EMAIL + id));
}

export const postCreateCompany = async data => {
    return await api.post(getURL(endPoint.COMPANY_STORE), data);
}

export const postCreateCompanyComment = async data => {
    return await api.post(getURL(endPoint.COMPANY_STORE_COMMENT), data);
}

export const postSendEmail = async data => {
    return await api.post(getURL(endPoint.COMPANY_SEND_EMAIL), data);
}

export const postUploadContract = async data => {
    return await api.postUpload(getURL(endPoint.COMPANY_UPLOAD_CONTRACT), data);
}

export const postUploadImage = async data => {
    return await api.postUpload(getURL(endPoint.COMPANY_UPLOAD_IMAGE), data);
}

export const putUpdateCompany = async (id, data) => {
    return await api.put(getURL(endPoint.COMPANY_UPDATE + id), data);
}

export const deleteUpdateCompanyList = async id => {
    return await api.deleteCompany(getURL(endPoint.COMPANY_DELETE + id));
}

export const deleteCompanyContract = async data => {
    return await api.deleteCompany(getURL(endPoint.COMPANY_DELETE_CONTRACT + data.company_id + '/' + data.id));
}

export const getLogDetails = async (id, params) => {
    return await api.getLog(getURL(endPoint.COMPANY_LOG + id), params);
}

export const postDeactiveCompany = async (id) => {
    return await api.post(getURL(endPoint.COMPANY_DEACTIVE + id));
}

export const postActiveCompany = async (id) => {
    return await api.post(getURL(endPoint.COMPANY_ACTIVE + id));
}