import * as api from "modules/standard_template/xhr";
import endPoint, { getURL } from "modules/standard_template/xhr/endPoint";

export const getListMaster = async () => {
  return await api.get(getURL(endPoint.LIST_TEMPLATE_MASTER));
};

export const getViewMaster = async (id) => {
  return await api.get(getURL(endPoint.PREVIEW_TEMPLATE_MASTER + id));
};

export const getViewBody = async (id) => {
  return await api.get(getURL(endPoint.PREVIEW_TEMPLATE_BODY + id));
};

export const postCreateMaster = async (data) => {
  return await api.post(getURL(endPoint.STORE_TEMPLATE_MASTER), data);
};

export const putUpdateMaster = async (id, data) => {
  return await api.put(getURL(endPoint.UPDATE_TEMPLATE_MASTER + id), data);
};

export const getListBody = async () => {
  return await api.get(getURL(endPoint.LIST_TEMPLATE_BODY));
};

export const postCreateBody = async (data) => {
  return await api.post(getURL(endPoint.STORE_TEMPLATE_BODY), data);
};

export const putUpdateBody = async (id, data) => {
  return await api.put(getURL(endPoint.UPDATE_TEMPLATE_BODY + id), data);
};

export const deleteDataBody = async (id) => {
  return await api.deleteData(getURL(endPoint.DELETE_TEMPLATE_BODY + id));
};

export const postUploadImage = async data => {
  return await api.postUpload(getURL(endPoint.UPLOAD_IMAGE_MASTER), data);
}