import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    'USER_LOGIN': 'authenticate/sign-in',
    'USER_LOGIN_GET_CODE': 'authenticate/send-sign-in-code',
    'USER_FORGET_PASSWORD_SEND_MAIL': 'authenticate/forget-password/send-mail',
    'USER_FORGET_PASSWORD_CHECK_TOKEN': 'authenticate/forget-password/check-token',
    'USER_FORGET_PASSWORD_UPDATE': 'authenticate/forget-password/update',
    'USER_LOGOUT': 'logout',
}

export const getURL = (name, routeParams = {}, queryParam = {}) => {
    const pattern = new UrlPattern(`${name}`);
    let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
    let mappingParams = new URLSearchParams();
    return `${url.origin}${url.pathname}?${mappingParams}`;
}

export default endPoint;
