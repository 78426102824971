import * as api from "modules/role_permissions/roles/xhr";
import endPoint from "modules/role_permissions/roles/xhr/endPoint";
import { getURL } from "modules/role_permissions/roles/xhr/endPoint";

export const getListRole = async () => {
  return await api.get(getURL(endPoint.ROLES_LIST));
};

export const postCreateRole = async (data) => {
  return await api.post(getURL(endPoint.ROLES_STORE), data);
};

export const putUpdateRole = async (id, data) => {
  return await api.post(getURL(endPoint.ROLES_UPDATE + id), data);
};

export const deleteUpdateRole = async (id) => {
  return await api.deleteRole(getURL(endPoint.ROLES_DELETE + id));
};

export const getDetailRole = async (id) => {
  return await api.get(getURL(endPoint.ROLES_SHOW + id));
};
