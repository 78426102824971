import * as React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Staff from 'modules/platform_user/repositories';
import * as Roles from "modules/role_permissions/roles/repositories";

function UpdateRoles({ id, roleUser, parentCallback }) {
    const navigate = useNavigate();
    const [roles, setListRoles] = React.useState([]);
    React.useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            return navigate('/login');
        }
        getListRoles();
    }, []);

    async function getListRoles() {
        try {
            let data = await Roles.getListRole();
            return setListRoles(data?.data?.response);
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.removeItem("access_token");
                localStorage.removeItem("user");
                return navigate("/login");
            }
        }
    }
    const handleChangeRole = async (event) => {
        event.preventDefault();
        setDisabledBtn(true);
        try {
            const dataChangeRole = await Staff.putChangeRole(id, event.target.value);
            if (dataChangeRole?.data?.response) {
                setDisabledBtn(false);
                parentCallback(dataChangeRole?.data?.meta?.status);
                Swal.fire({
                    icon: 'success',
                    text: 'Update user role success',
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        } catch (error) {
            setDisabledBtn(false);
            if (error?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    return (
        <TextField
            fullWidth
            id="role"
            name="role"
            autoComplete="role"
            select
            value={roleUser}
            onChange={handleChangeRole}
            InputLabelProps={{
                shrink: true,
            }}
            sx={{ width: '8rem' }}
            disabled={disabledBtn}
        >
            {roles.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
export default UpdateRoles;