import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FolderIcon from '@mui/icons-material/Folder';
import { Chip, FormHelperText, Grid, InputAdornment, MenuItem, Pagination, Paper, TextField, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import { Apartment, Email, Phone } from '@mui/icons-material';
import * as Contact from 'modules/contact_crm/repositories';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
// import Autocomplete from '@mui/material/Autocomplete';
import dataSelect from 'modules/contact_crm/data/dataSelect';
import { useFormik } from 'formik';
import { Stack } from '@mui/system';
// import NTTextEditor from 'components/NTTextEditor';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import moment from 'moment/moment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton } from '@mui/lab';
import Zoom from '@mui/material/Zoom';
import CreateIcon from '@mui/icons-material/Create';
import parse from 'html-react-parser';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const style = {
    bgcolor: '#efecec',
    boxShadow: 24,
    height: '100%',
};

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ],
};

const cssBodyModal = {
    pr: 2,
    // pl: 1,
    // py: 2,
    m: 0,
    // height: '80%',
    height: 'calc(100vh - 9.5rem)',
    overflow: 'hidden',
    overflowY: 'scroll',
    // background: '#fff',
};


const { titles, genders, countryPhonePrefixCode } = dataSelect;

function ModalDetail({ contact, dataCallback }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        fetchListCompany();
        fetchLogContact();
        getFetchEmail()

    }
    const handleClose = () => { setOpen(false); setDisabledFld(true); };
    const navigate = useNavigate();

    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [disabledFld, setDisabledFld] = React.useState(true);
    const [notesSave, setNoteSave] = React.useState('')
    const [errNote, setErrNote] = React.useState(false);
    const [txtErrNote, setTxtErrNote] = React.useState('');
    const [notes, setNotes] = React.useState(contact?.notes ? contact?.notes : '');
    const [valueTabs, setValueTabs] = React.useState('1');
    const [valueTabsEmail, setValueTabsEmail] = React.useState('1');
    const [loading, setLoading] = React.useState(false);
    const [listCompanySelect, setListCompanySelect] = React.useState([]);
    const [logContact, setLogContact] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [subjectEmail, setSubjectEmail] = React.useState('');
    const [messageEmail, setMessageEmail] = React.useState('');
    const [fetchEmail, setFetchEmail] = React.useState([]);
    const [openCreateUser, setOpenCreateUser] = React.useState(false);
    const [errMessageEmail, setErrMessageEmail] = React.useState(false);
    const [errTxtMessageEmail, setErrTxtMessageEmail] = React.useState('');
    const [errSubjectEmail, setErrSubjectEmail] = React.useState(false);
    const [errTxtSubjectEmail, setErrTxtSubjectEmail] = React.useState('');
    const [viewEmail, setViewEmail] = React.useState('')
    const [openViewEmail, setOpenViewEmail] = React.useState(false)
    const [disabledBtnCreate, setDisabledBtnCreate] = React.useState(false);
    const [errGenerate, setErrGenerate] = React.useState(false);
    const [generatePassword, setGeneratePassword] = React.useState('')

    async function fetchListCompany() {
        try {
            let data = await Contact.getDataCompanySelect();
            if (data?.data?.response)
                setListCompanySelect(data?.data?.response);
        } catch (err) {
            console.log(err)
        }
    }
    async function fetchLogContact(pageLog) {
        const dataPage = {
            page: pageLog
        }
        try {
            let data = await Contact.getLogDetails(contact?.id, dataPage);
            if (data?.data?.response)
                setLogContact(data?.data?.response);

        } catch (err) {
            console.log(err)
        }
    }



    function handleClickEdit() {
        setLoading(true);
        setTimeout(() => {
            setDisabledFld(false)
        }, 1000);
    }

    function handleClickCancel() {
        setLoading(false);
        setDisabledFld(true)
    }


    const handleChangePagiNote = (event, value) => {
        setPage(value);
        fetchLogContact(value);
    };

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    };

    const handleChangeTabsEmail = (event, newValue) => {
        setValueTabsEmail(newValue);
    };

    const handleCreateUser = () => {
        setOpenCreateUser(true);
    };
    const handleCloseEmail = () => {
        setOpenViewEmail(false);
    };


    const handleCloseCreateUser = () => {
        setOpenCreateUser(false);
    };

    const validate = values => {
        const errors = {};
        if (values.title === "Select") {
            errors.title = 'Required';
        }
        if (values.company === "Select") {
            errors.company = 'Required';
        }
        if (!values.firstName) {
            errors.firstName = 'Required';
        } else if (values.firstName.length > 15) {
            errors.firstName = 'Must be 15 characters or less';
        } else if (!/^[A-Za-z]+$/i.test(values.firstName)) {
            errors.firstName = 'Accept only letter';
        }
        if (!values.surName) {
            errors.surName = 'Required';
        } else if (values.surName.length > 15) {
            errors.surName = 'Must be 15 characters or less';
        } else if (!/^[A-Za-z]+$/i.test(values.surName)) {
            errors.surName = 'Accept only letter';
        }
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!values.phone) {
            errors.phone = 'Required';
        } else if (/^[A-Za-z]+$/i.test(values.phone)) {
            errors.phone = 'Only accept number';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            title: contact?.title ? contact?.title : titles[0].value,
            firstName: contact?.first_name ? contact?.first_name : '',
            surName: contact?.surname ? contact?.surname : '',
            middleName: contact?.middle_name ? contact?.middle_name : '',
            position: contact?.position ? contact?.position : '',
            department: contact?.department ? contact?.department : '',
            email: contact?.email ? contact?.email : '',
            gender: contact?.gender ? contact?.gender : genders[0].value,
            dateOfBirth: contact?.birthday ? contact?.birthday : '',
            countrycode: contact?.country_code ? contact?.country_code : countryPhonePrefixCode[0].value,
            phone: contact?.phone_number ? contact?.phone_number : '',
            company: contact?.company_id ? contact?.company_id : 'Select',
        },
        validate,
        onSubmit: async values => {
            const data = {
                title: values.title,
                first_name: values.firstName,
                surname: values.surName,
                middle_name: values.middleName,
                position: values.position,
                department: values.department,
                email: values.email,
                gender: values.gender,
                birthday: values.dateOfBirth,
                country_code: values.countrycode,
                phone_number: values.phone,
                company_id: values.company,
            }
            // console.log(data);
            try {
                setDisabledBtn(true);
                if (contact) {
                    const dataRes = await Contact.putUpdateContact(contact?.id, data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        setDisabledFld(true)
                        setLoading(false);
                        // handleClose();
                        fetchLogContact(page);
                        dataCallback(dataRes?.data?.meta?.status);
                        Swal.fire({
                            icon: 'success',
                            text: `Update success`,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                } else {
                    const dataRes = await Contact.postCreateContact(data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        setLoading(false);
                        handleClose();
                        Swal.fire({
                            icon: 'success',
                            text: 'Create success',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        dataCallback(dataRes?.data?.meta?.status);
                    }
                }
            } catch (e) {
                setDisabledBtn(false);
                if (e?.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    return navigate('/login');
                }
            }
        },
    });

    const saveContactNote = async (e) => {
        e.preventDefault();
        if (notesSave === '') {
            setErrNote(true);
            setTxtErrNote('Please enter the note');
            return false;
        }
        const data = {
            note: notesSave
        }
        try {
            setDisabledBtn(true);
            const dataRes = await Contact.postNoteContact(contact?.id, data);
            if (dataRes?.data?.response) {
                Swal.fire({
                    icon: 'success',
                    text: `Note success`,
                    showConfirmButton: false,
                    timer: 3000
                });
                setNoteSave("");
                setErrNote(false);
                setTxtErrNote('');
                setDisabledBtn(false);
                try {
                    const dataNotes = await Contact.getContactDetails(contact?.id, data);

                    setNotes(dataNotes.data.response.notes);


                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                text: `Note fail`,
                showConfirmButton: false,
                timer: 3000
            });
        }

    }

    const getFetchEmail = async (e) => {
        try {
            const emailFetchRes = await Contact.fetchEmail(contact?.id);
            if (emailFetchRes?.data?.meta?.status === 200) {
                setFetchEmail(emailFetchRes?.data?.response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        if (subjectEmail === '') {
            setErrSubjectEmail(true);
            setErrTxtSubjectEmail('Please enter the subject');
            return false;
        }
        if (messageEmail === '') {
            setErrMessageEmail(true);
            setErrTxtMessageEmail('Please enter the message');
            return false;
        }

        try {
            const dataEmail = {
                contact_id: contact?.id,
                subject: subjectEmail,
                body: messageEmail
            }
            setDisabledBtn(true);
            const apiSendMail = await Contact.postSendEmail(contact?.id, dataEmail);
            if (apiSendMail?.data?.response) {
                setDisabledBtn(false);
                setErrMessageEmail(false);
                setErrTxtMessageEmail('');
                setMessageEmail('');
                setErrSubjectEmail(false);
                setErrTxtSubjectEmail('');
                setSubjectEmail('');
                getFetchEmail();
                Swal.fire({
                    icon: 'success',
                    text: 'Send email success',
                });
            }
        } catch (error) {
            console.log(error);
            setDisabledBtn(false);
        }
    }

    function ContentNotes() {
        return (
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
            >
                {notes.map((item, index) => (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                            <TimelineDot color="info">
                                <NoteAltIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography sx={{ fontSize: '0.9rem' }}>{item.created_by}</Typography>
                                <Chip label={moment(item.created_at).format('DD-MMM-YYYY, H:m A')} variant="outlined" size="small" />
                            </Stack>
                            <Paper
                                elevation={1}
                                sx={{
                                    p: 1,
                                    mt: 1,
                                    borderRadius: '0.75rem',
                                    background: '#f0f2fc'
                                }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', color: '#6c757d' }}>{item.content}</Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        );
    }

    function ContentLog() {
        return (
            <>
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {logContact.data.map((item, index) => (
                        <TimelineItem key={index}>
                            <TimelineSeparator>
                                <TimelineDot color="info">
                                    <CreateIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography sx={{ fontSize: '0.9rem' }}>{item.staff.firstname} {item.staff.surname} - {item.type}</Typography>
                                    <Chip label={moment(item.created_at).format('DD-MMM-YYYY, H:m A')} variant="outlined" size="small" />
                                </Stack>
                                <Paper
                                    elevation={1}
                                    sx={{
                                        p: 1,
                                        mt: 1,
                                        borderRadius: '0.75rem',
                                        background: '#f0f2fc'
                                    }}
                                >
                                    <Box>
                                        {
                                            item.event.map((itemNote, index) => (
                                                <Typography sx={{ fontSize: '0.8rem', color: '#6c757d', fontWeight: 300 }} key={index} >{parse(itemNote)}</Typography>
                                            ))
                                        }
                                    </Box>
                                </Paper>

                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
                <Stack spacing={2} justifyContent="center" alignItems="center" m={3} pb={3}>
                    <Pagination count={logContact.last_page} page={page} onChange={handleChangePagiNote} />
                </Stack>
            </>

        );
    }
    const submitGeneratePassword = (e) => {
        e.preventDefault();
        setErrGenerate(false);
        const randomPassword = Math.random().toString(36).slice(2);
        setGeneratePassword(randomPassword);
    }
    const submitCreateUser = async (e) => {
        if (generatePassword === '') {
            setErrGenerate(true);
            return false;
        }
        const data = {
            contact_id: contact?.id,
            username: contact?.email,
            password: generatePassword
        }

        setDisabledBtnCreate(true);
        try {
            const apiSendMail = await Contact.createUser(data);
            if (apiSendMail?.data?.response) {
                setOpenCreateUser(false);
                setErrGenerate(false);
                Swal.fire({
                    icon: 'success',
                    text: 'Create User Success',
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            {contact ? (
                <Tooltip
                    title={<p style={{ fontSize: '0.7rem' }}>Edit</p>}
                    placement="left"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                >
                    <IconButton color="info" onClick={handleOpen}>
                        <FolderIcon />
                    </IconButton>
                </Tooltip>

            ) : (
                <MDButton fullWidth variant="contained" color="info" onClick={handleOpen}>
                    New Contact
                </MDButton>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ height: '100%', width: '80%', left: 'unset' }}
            >
                <Box sx={style} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'}>
                                    Contact
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={cssBodyModal} justifyContent="space-around">
                        <Grid item xs={12} md={contact ? 7 : 12} >
                            <Paper
                                sx={{
                                    p: 2,
                                    borderRadius: '0.75rem',
                                    boxShadow: 'unset'
                                }}
                            >
                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Title (*)"
                                            id="title"
                                            name="title"
                                            select
                                            value={formik.values.title}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.title && Boolean(formik.errors.title)}
                                            helperText={formik.touched.title && formik.errors.title}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        >
                                            {titles.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            fullWidth
                                            label="Company (*)"
                                            id="company"
                                            name="company"
                                            select
                                            defaultValue="Select"
                                            value={formik.values.company}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.company && Boolean(formik.errors.company)}
                                            helperText={formik.touched.company && formik.errors.company}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Apartment fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        >
                                            {listCompanySelect.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="First Name (*)"
                                            id="firstName"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Surname (*)"
                                            id="surName"
                                            name="surName"
                                            value={formik.values.surName}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.surName && Boolean(formik.errors.surName)}
                                            helperText={formik.touched.surName && formik.errors.surName}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Middle Name"
                                            id="middleName"
                                            name="middleName"
                                            value={formik.values.middleName}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Gender"
                                            id="gender"
                                            name="gender"
                                            select
                                            value={formik.values.gender}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            // value={gender}
                                            // onChange={handleChangeGender}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        >
                                            {genders.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Date of birth"
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            type="date"
                                            value={formik.values.dateOfBirth}
                                            onChange={formik.handleChange}
                                            autoComplete='dateOfBirth'
                                            error={false}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Position"
                                            id="position"
                                            name="position"
                                            value={formik.values.position}
                                            onChange={formik.handleChange}
                                            type="text"
                                            autoComplete='position'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                            onKeyDown={(event) => {
                                                if (!/^[a-zA-Z]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Department"
                                            id="department"
                                            name="department"
                                            value={formik.values.department}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type="text"
                                            autoComplete='department'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                            onKeyDown={(event) => {
                                                if (!/^[a-zA-Z]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            fullWidth
                                            label="Email (*)"
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formik.values.email}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Country Code"
                                            id="countrycode"
                                            name="countrycode"
                                            select
                                            value={formik.values.countrycode}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        >
                                            {countryPhonePrefixCode.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Phone (*)"
                                            id="phone"
                                            name="phone"
                                            type="number"
                                            value={formik.values.phone}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                                            helperText={formik.touched.phone && formik.errors.phone}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled={contact ? disabledFld : false}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            {contact ?
                                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    <Grid item xs={12} md={6}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                mt: 1,
                                                borderRadius: '0.75rem',
                                                boxShadow: 'unset'
                                            }}
                                        >
                                            {!contact?.user ? <MDButton type="button" fullWidth variant="contained" color="success" onClick={handleCreateUser} disabled={disabledBtnCreate}>
                                                Create Subscriber User
                                            </MDButton> : <MDButton type="button" fullWidth variant="contained" color="white" disabled>
                                                User was created
                                            </MDButton>}

                                        </Paper>
                                        <Dialog
                                            open={openCreateUser}
                                            onClose={handleCloseCreateUser}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Create a Master System administrator for subscribers"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <Box mt={2}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        id="emailCreate"
                                                        name="emailCreate"
                                                        type="text"
                                                        value={contact?.email}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        sx={{ mb: 2 }}
                                                        disabled
                                                    />
                                                    <Box
                                                        sx={{
                                                            display: 'grid',
                                                            gridTemplateColumns: { sm: '2fr 1fr' },
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Password"
                                                            id="generatePassword"
                                                            name="generatePassword"
                                                            type="text"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            sx={{ mb: 2 }}
                                                            value={generatePassword}
                                                            disabled
                                                        />
                                                        <MDButton
                                                            variant="outlined"
                                                            color="info"
                                                            size="small"
                                                            sx={{ mb: 2 }}
                                                            disabled={disabledBtn}
                                                            onClick={submitGeneratePassword}
                                                        >
                                                            Generate Password
                                                        </MDButton>
                                                    </Box>
                                                    {errGenerate ? <Typography variant='body3' sx={{ color: 'red', fontStyle: 'italic' }}>Please generate password</Typography> : ''}

                                                </Box>
                                            </DialogContent>
                                            <DialogActions>
                                                <MDButton variant="contained" color="primary" onClick={submitCreateUser} disabled={disabledBtnCreate}>
                                                    Create
                                                </MDButton>
                                                {/* <MDButton onClick={handleCloseCreateUser}>Cancel</MDButton> */}
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                                :
                                ""
                            }
                        </Grid>
                        {contact ?
                            <Grid item xs={12} md={5}>
                                <Box sx={{ width: '100%', typography: 'body1', mb: 2 }}>
                                    <TabContext value={valueTabsEmail} >
                                        <Box mb={1}>
                                            <TabList onChange={handleChangeTabsEmail} aria-label="Email Tabs" sx={{ p: 1 }}>
                                                <Tab label="Email" value="1" />
                                                <Tab label="Check Emails" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{ p: 0 }}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    borderRadius: '0.75rem',
                                                    boxShadow: 'unset'
                                                }}
                                            >

                                                <TextField
                                                    id="outlined-multiline-subject"
                                                    label="Subject"
                                                    value={subjectEmail}
                                                    onChange={(e) => setSubjectEmail(e.target.value)}
                                                    error={errSubjectEmail}
                                                    helperText={errTxtSubjectEmail}
                                                    type="text"
                                                    fullWidth
                                                    sx={{ mb: 2 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                {/* <TextField
                                                    id="outlined-multiline-message"
                                                    label="Message"
                                                    value={messageEmail}
                                                    onChange={(e) => setMessageEmail(e.target.value)}
                                                    error={errMessageEmail}
                                                    helperText={errTxtMessageEmail}
                                                    multiline
                                                    fullWidth
                                                    rows={5}
                                                    sx={{ my: 2 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                /> */}
                                                <ReactQuill modules={modules} theme="snow" placeholder="Message here..." value={messageEmail} onChange={setMessageEmail} />
                                                {errMessageEmail ? <FormHelperText error={true}>{errTxtMessageEmail}</FormHelperText> : ''}
                                                <MDButton variant="outlined" color="info" size="small" sx={{ mt: 2 }} onClick={sendEmail} disabled={disabledBtn}>Send Email</MDButton>
                                            </Paper>
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ p: 0 }}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    mt: 2,
                                                    borderRadius: '0.75rem',
                                                    boxShadow: 'unset'
                                                }}
                                            >
                                                {fetchEmail.map((data, index) => (
                                                    <div key={index}>
                                                        <Typography variant='body2' color="primary" sx={{ fontSize: '0.9rem', mb: 1, cursor: 'pointer' }}
                                                            onClick={() => { setViewEmail(data); setOpenViewEmail(true) }}>
                                                            {index + 1}, {data?.subject} ({moment(data.created_at).format('DD-MMM-YYYY')})
                                                        </Typography>

                                                    </div>
                                                ))}
                                                <Dialog
                                                    open={openViewEmail}
                                                    onClose={handleCloseEmail}
                                                    maxWidth="md"
                                                    fullWidth
                                                >
                                                    <DialogContent>
                                                        <Box mt={2}>
                                                            <Typography variant='h6' mb={2}>Subject: {viewEmail.subject}</Typography>
                                                            <Typography variant='body2'>{viewEmail.body}</Typography>
                                                        </Box>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <MDButton variant='contained' color='cancel' onClick={handleCloseEmail}>Close</MDButton>
                                                    </DialogActions>
                                                </Dialog>
                                            </Paper>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={valueTabs} >
                                        <Box mb={1}>
                                            <TabList onChange={handleChangeTabs} aria-label="Contact Tabs" sx={{ p: 1 }}>
                                                <Tab label="Notes" value="1" />
                                                <Tab label="Activity" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{ p: 0 }}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    borderRadius: '0.75rem',
                                                    boxShadow: 'unset'
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-multiline-note"
                                                    label="Note"
                                                    placeholder="Note here"
                                                    value={notesSave}
                                                    onChange={e => setNoteSave(e.target.value)}
                                                    multiline
                                                    fullWidth
                                                    rows={4}
                                                    error={errNote}
                                                    helperText={txtErrNote}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <MDButton variant="outlined" color="info" sx={{ mt: 2 }} size="small" onClick={saveContactNote} disabled={disabledBtn}>Save Note</MDButton>
                                            </Paper>
                                            {notes.length > 0
                                                ? <Paper
                                                    sx={{
                                                        p: 2,
                                                        mt: 2,
                                                        borderRadius: '0.75rem',
                                                        boxShadow: 'unset'
                                                    }}
                                                >
                                                    <ContentNotes></ContentNotes>
                                                </Paper>
                                                : ''
                                            }
                                        </TabPanel>
                                        <TabPanel value="2" sx={{ p: 0 }}>
                                            <Paper
                                                sx={{
                                                    p: 2,
                                                    mt: 2,
                                                    borderRadius: '0.75rem',
                                                    boxShadow: 'unset'
                                                }}
                                            >
                                                {logContact.total > 0
                                                    ?
                                                    <ContentLog></ContentLog>
                                                    :
                                                    <Typography variant='body2' align='center'>No Activities</Typography>
                                                }
                                            </Paper>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Grid>
                            :
                            ''}
                    </Grid>
                    <Grid container px={4} py={2} mt={1} justifyContent="flex-end" sx={{ backgroundColor: '#fff', boxShadow: '0 0px 5px -2px gray!important' }}>
                        {
                            contact
                                ?
                                <Grid item xs={12} md={3} lg={2} textAlign={'right'}>
                                    {
                                        disabledFld
                                            ?
                                            <>
                                                {/* <MDButton type="button" fullWidth variant="contained" color="info" sx={{ width: '50%' }} onClick={(e) => { setDisabledFld(false) }}>
                                                    Edit
                                                </MDButton> */}
                                                <LoadingButton
                                                    onClick={handleClickEdit}
                                                    loading={loading}
                                                    variant="contained"
                                                    sx={{ width: '50%', color: '#fff' }}
                                                // onClick={(e) => { setDisabledFld(false) }}
                                                >
                                                    Edit
                                                </LoadingButton>
                                            </>

                                            :
                                            <Stack direction="row" spacing={2}>
                                                <MDButton type="submit" fullWidth variant="contained" color="info" disabled={disabledBtn}>
                                                    Save
                                                </MDButton>
                                                <MDButton type="button" fullWidth variant="contained" color="cancel" onClick={handleClickCancel}>
                                                    Cancel
                                                </MDButton>
                                            </Stack>
                                    }
                                </Grid>
                                :
                                <Grid item xs={12} md={3} lg={2}>
                                    <MDButton type="submit" fullWidth variant="contained" color="info" disabled={disabledBtn}>
                                        Save
                                    </MDButton>
                                </Grid>
                        }
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default ModalDetail;