import * as api from 'modules/master_login/xhr';
import endPoint from "modules/master_login/xhr/endPoint";
import { getURL } from "modules/master_login/xhr/endPoint";

export const postAuthenticationWithCode = async data => {
    return await api.postBeforeLogin(getURL(endPoint.USER_LOGIN), data);
}

export const postAuthentication = async data => {
    return await api.postBeforeLogin(getURL(endPoint.USER_LOGIN_GET_CODE), data);
}

export const postSendMailForgotPassword = async data => {
    return await api.postBeforeLogin(getURL(endPoint.USER_FORGET_PASSWORD_SEND_MAIL), data);
}

export const getCheckToken = async data => {
    return await api.getCheckToken(getURL(endPoint.USER_FORGET_PASSWORD_CHECK_TOKEN), data);
}

export const putUpdatePassword = async data => {
    return await api.putUpdatePassword(getURL(endPoint.USER_FORGET_PASSWORD_UPDATE), data);
}

export const getUserLogout = async data => {
    return await api.getLogout(getURL(endPoint.USER_LOGOUT));
}