import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';
import { Chip, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Staff from 'modules/platform_user/repositories';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function ModalActive({ id, active, parentCallback }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [disabledBtn, setDisabledBtn] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setDisabledBtn(true);
            if (active === 1) {
                const dataRes = await Staff.putDeactiveStaff(id);
                if (dataRes?.data?.response) {
                    setDisabledBtn(false);
                    handleClose();
                    parentCallback(dataRes?.data?.meta?.status);
                    Swal.fire({
                        icon: 'success',
                        text: 'Deactive user success',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            } else {
                const dataRes = await Staff.putActiveStaff(id);
                if (dataRes?.data?.response) {
                    setDisabledBtn(false);
                    handleClose();
                    parentCallback(dataRes?.data?.meta?.status);
                    Swal.fire({
                        icon: 'success',
                        text: 'Active user success',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            }
        } catch (error) {
            setDisabledBtn(false);
            if (e?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    return (
        <div>
            {active === 1 ? <IconButton color="error" onClick={handleOpen}>
                <Chip label="Deactive" color="error" variant="outlined" sx={{ fontSize: '0.7rem' }} />
            </IconButton> :
                <IconButton color="success" onClick={handleOpen}>
                    <Chip label="Active" color="primary" variant="outlined" sx={{ fontSize: '0.7rem' }} />
                </IconButton>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} component="form" role="form" onSubmit={handleSubmit}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'}>
                        Are you sure you want to {active === 1 ? 'deactive' : 'active'} user?
                    </Typography>
                    <Grid container justifyContent={'center'} spacing={2} sx={{ mt: 2 }}>
                        <Grid item>
                            <MDButton type="submit" fullWidth variant="contained" color="info" sx={{ fontWeight: 500 }} disabled={disabledBtn}>
                                Yes
                            </MDButton>
                        </Grid>
                        <Grid item>
                            <MDButton fullWidth variant="contained" color="cancel" sx={{ fontWeight: 500 }} onClick={handleClose}>
                                Cancel
                            </MDButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default ModalActive;