import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createElement, useCallback, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import DropZone from "./dropZone";
import ModalDeleteMenu from "./modalDeleteMenu";
import ModalDetailMenu from "./modalDetailMenu";
import * as MuiIcons from "@mui/icons-material";

const TreeItemMenu = ({
  item,
  lever,
  currentPath,
  parentPath,
  index,
  handleRenderTree,
  parrentOptions,
  handleGetMenu,
  handleDrop,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(true);

  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const [{ isDragging }, drag] = useDrag({
    type: "menu",
    item: () => ({
      item,
      currentPath,
      parentPath,
      index,
      lever,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);
  return (
    <>
      <DropZone
        onDrop={handleDrop}
        dropZoneData={{
          lever: lever + 1,
          index: 0,
          parentPath: `${currentPath}.child`,
        }}
      >
        <Box
          ref={ref}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "move",
            marginLeft: 4 * lever,
            px: 1,
            ...(isDragging ? { opacity: 0.2 } : {}),
          }}
          onClick={handleClick}
        >
          {!!item.child.length && (
            <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Box>
          )}

          {MuiIcons[item.icon] && (
            <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
              {createElement(MuiIcons[item.icon], {})}
            </Box>
          )}
          <Typography sx={{ flexGrow: 1 }} variant="body2">
            {item.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ModalDetailMenu
              parrentOptions={parrentOptions}
              menu={item}
              parentCallback={handleGetMenu}
            />
            <ModalDeleteMenu
              parrentOptions={parrentOptions}
              id={item.id}
              parentCallback={handleGetMenu}
            />
          </Box>
        </Box>
      </DropZone>
      {!!item.child.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {handleRenderTree(item.child, lever + 1, `${currentPath}.child`)}
        </Collapse>
      )}
    </>
  );
};

export default TreeItemMenu;
