import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { Tooltip, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Master from 'modules/standard_template/repositories';
import PreviewIcon from '@mui/icons-material/Preview';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: { xs: '100%', md: '80%', lg: '65%' },
    p: 4,
};

function ModalPreview({ id, formPreview }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true); viewTemplate(); }
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [template, setTemplate] = React.useState('');

    const viewTemplate = async () => {
        try {
            console.log(formPreview);
            if (formPreview === 'master') {
                const dataRes = await Master.getViewMaster(id);
                if (dataRes?.data?.response) {
                    setTemplate(dataRes?.data?.response);
                }
            } else {
                const dataRes = await Master.getViewBody(id);
                if (dataRes?.data?.response) {
                    setTemplate(dataRes?.data?.response);
                }
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                return navigate('/login');
            }
        }
    };

    return (
        <div>
            <Tooltip
                title={<p style={{ fontSize: '0.7rem' }}>Preview</p>}
                placement="top"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
            >
                <IconButton color="info" onClick={handleOpen}>
                    <PreviewIcon />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {template ? <div dangerouslySetInnerHTML={{ __html: template }} /> : ''}
                </Box>
            </Modal>
        </div>
    );
}
export default ModalPreview;