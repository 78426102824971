import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';
import { Avatar, Checkbox, FormControlLabel, Grid, IconButton, Input, Paper, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import FolderIcon from '@mui/icons-material/Folder';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import * as Master from 'modules/standard_template/repositories';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

const style = {
    bgcolor: '#efecec',
    boxShadow: 24,
    height: '100%',
};

const cssBodyModal = {
    pr: 2,
    m: 0,
    height: 'calc(100vh - 9.5rem)',
    overflow: 'hidden',
    overflowY: 'scroll',
};

const URL_FILE = process.env.REACT_APP_AWS_URL;

function EditMaster({ dataMaster, header, footer, parentCallback }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const [company_image, setCompanyImage] = React.useState(header?.company_logo ? header?.company_logo : '');
    const [photoCover, setPhotoCover] = React.useState(header?.photo_cover ? header?.photo_cover : '');

    const uploadImage = async (e, sub) => {
        e.preventDefault();
        try {
            const data = {
                sub_folder: 'header-footer',
                image: e.target.files[0]
            }
            setDisabledBtn(true);
            const logoRes = await Master.postUploadImage(data);
            if (logoRes?.data?.response) {
                if (sub === 'comImage') {
                    setCompanyImage(logoRes?.data?.response);
                }
                if (sub === 'photoCover') {
                    setPhotoCover(logoRes?.data?.response);
                }
                setDisabledBtn(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validate = ({
        name,
        facebook_url,
        linkedin_url,
        twitter_url,
        instagram_url,
        youtube_url,
        google_plus_url,
        website,
        email
    }) => {
        const errors = {};
        if (!name) {
            errors.name = 'Please enter the name';
        }
        if (facebook_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(facebook_url)) {
            errors.facebook_url = 'Please enter a valid facebook url';
        }
        if (linkedin_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(linkedin_url)) {
            errors.linkedin_url = 'Please enter a valid linkedin url';
        }
        if (twitter_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(twitter_url)) {
            errors.twitter_url = 'Please enter a valid twitter url';
        }
        if (instagram_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(instagram_url)) {
            errors.instagram_url = 'Please enter a valid instagram url';
        }
        if (youtube_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(youtube_url)) {
            errors.youtube_url = 'Please enter a valid youtube url';
        }
        if (google_plus_url && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(google_plus_url)) {
            errors.google_plus_url = 'Please enter a valid google plus url';
        }
        if (website && !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i.test(website)) {
            errors.website = 'Please enter a valid website';
        }
        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Please enter a valid email address';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: dataMaster?.name || '',
            description: dataMaster?.url_name || '',
            view_in_browser_link: header?.view_in_browser_link,
            view_website_link: header?.view_website_link,
            phone: header?.phone || '',
            is_show_phone: header?.is_show_phone,
            company_logo: header?.company_logo || '',
            photo_cover: header?.photo_cover || '',
            join_us_online: footer?.join_us_online,
            facebook_url: footer?.social?.facebook_url || '',
            is_show_facebook: footer?.social?.is_show_facebook,
            instagram_url: footer?.social?.instagram_url || '',
            is_show_instagram: footer?.social?.is_show_instagram,
            linkedin_url: footer?.social?.linkedin_url || '',
            is_show_linkedin: footer?.social?.is_show_linkedin,
            youtube_url: footer?.social?.youtube_url || '',
            is_show_youtube: footer?.social?.is_show_youtube,
            twitter_url: footer?.social?.twitter_url || '',
            is_show_twitter: footer?.social?.is_show_twitter,
            google_plus_url: footer?.social?.google_plus_url || '',
            is_show_google_plus: footer?.social?.is_show_google_plus,
            companyName: footer?.company_detail?.companyName || '',
            is_show_name: footer?.company_detail?.is_show_name,
            address: footer?.company_detail?.address || '',
            is_show_address: footer?.company_detail?.is_show_address,
            phone_number: footer?.company_detail?.phone_number || '',
            is_show_phone_number: footer?.company_detail?.is_show_phone_number,
            website: footer?.company_detail?.website || '',
            is_show_website: footer?.company_detail?.is_show_website,
            email: footer?.company_detail?.email || '',
            is_show_email: footer?.company_detail?.is_show_email,
            is_show_terms_conditions_link: footer?.company_detail?.is_show_terms_conditions_link,
            is_show_privacy_policies: footer?.company_detail?.is_show_privacy_policies,
        },
        validate,
        onSubmit: async ({
            name,
            description,
            view_in_browser_link,
            view_website_link,
            phone,
            is_show_phone,
            join_us_online,
            facebook_url,
            is_show_facebook,
            instagram_url,
            is_show_instagram,
            linkedin_url,
            is_show_linkedin,
            youtube_url,
            is_show_youtube,
            twitter_url,
            is_show_twitter,
            google_plus_url,
            is_show_google_plus,
            companyName,
            is_show_name,
            address,
            is_show_address,
            phone_number,
            is_show_phone_number,
            website,
            is_show_website,
            email,
            is_show_email,
            is_show_terms_conditions_link,
            is_show_privacy_policies,
        }) => {
            const data = {
                name: name,
                description: description,
                header: {
                    view_in_browser_link: view_in_browser_link,
                    view_website_link: view_website_link,
                    phone: phone,
                    is_show_phone: is_show_phone,
                    company_logo: company_image,
                    photo_cover: photoCover,
                },
                footer: {
                    join_us_online: join_us_online,
                    social: {
                        facebook_url: facebook_url,
                        is_show_facebook: is_show_facebook,
                        instagram_url: instagram_url,
                        is_show_instagram: is_show_instagram,
                        linkedin_url: linkedin_url,
                        is_show_linkedin: is_show_linkedin,
                        youtube_url: youtube_url,
                        is_show_youtube: is_show_youtube,
                        twitter_url: twitter_url,
                        is_show_twitter: is_show_twitter,
                        google_plus_url: google_plus_url,
                        is_show_google_plus: is_show_google_plus,
                    },
                    company_detail: {
                        companyName: companyName,
                        is_show_name: is_show_name,
                        address: address,
                        is_show_address: is_show_address,
                        phone_number: phone_number,
                        is_show_phone_number: is_show_phone_number,
                        website: website,
                        is_show_website: is_show_website,
                        email: email,
                        is_show_email: is_show_email,
                        is_show_terms_conditions_link: is_show_terms_conditions_link,
                        is_show_privacy_policies: is_show_privacy_policies,
                    },
                }
            }
            try {
                setDisabledBtn(true);
                if (dataMaster) {
                    const dataRes = await Master.putUpdateMaster(dataMaster?.id, data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        parentCallback(dataRes?.data?.meta?.status);
                        Swal.fire({
                            icon: 'success',
                            text: `Update Header/Footer: "${data.name}" success`,
                        });
                    }
                } else {
                    const dataRes = await Master.postCreateMaster(data);
                    if (dataRes?.data?.response) {
                        setDisabledBtn(false);
                        handleClose();
                        parentCallback(dataRes?.data?.meta?.status);
                        Swal.fire({
                            icon: 'success',
                            text: 'Create Header/Footer success',
                        });
                    }
                }
            } catch (e) {
                setDisabledBtn(false);
                if (e?.response?.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    return navigate('/login');
                }
            }
        },
    });

    return (
        <div>
            {dataMaster ? (
                <Tooltip
                    title={<p style={{ fontSize: '0.7rem' }}>Edit</p>}
                    placement="top"
                    TransitionComponent={Zoom}
                    TransitionProps={{ timeout: 300 }}
                >
                    <IconButton color="info" onClick={handleOpen}>
                        <FolderIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <MDButton fullWidth variant="contained" color="info" sx={{ fontWeight: 500 }} onClick={handleOpen}>
                    New Header/Footer
                </MDButton>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ height: '100%', width: { xs: '100%', md: '80%', lg: '80%' }, left: 'unset' }}
            >
                <Box sx={style} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container px={3} py={2} bgcolor={'#3c8dbc'} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h6" color={'#fff'} component="h2">
                                    Email Header & Footer
                                </Typography>
                                <IconButton aria-label="close" onClick={handleClose}>
                                    <CloseIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={cssBodyModal} justifyContent="space-around">
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, borderRadius: '0.75rem', boxShadow: 'unset' }}>
                                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Name(*)"
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Header Section</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <Typography sx={{ fontSize: '0.75rem' }}>View in Browser Link</Typography>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="view_in_browser_link"
                                                    name="view_in_browser_link"
                                                    value={formik.values.view_in_browser_link}
                                                    checked={!!formik.values.view_in_browser_link}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <Typography sx={{ fontSize: '0.75rem' }}>View Website Link</Typography>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="view_website_link"
                                                    name="view_website_link"
                                                    value={formik.values.view_website_link}
                                                    checked={!!formik.values.view_website_link}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}></Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11} sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Phone"
                                                    id="phone"
                                                    name="phone"
                                                    value={formik.values.phone}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1} sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_phone"
                                                    name="is_show_phone"
                                                    value={formik.values.is_show_phone}
                                                    checked={!!formik.values.is_show_phone}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}></Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Company Logo"
                                                id="company_logo"
                                                name="company_logo"
                                                type="text"
                                                autoComplete="company_logo"
                                                value={company_image}
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ readOnly: true, }}
                                                sx={{ '& .MuiInputBase-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                                            />
                                            <label htmlFor="contained-button-file-company-logo" style={{ margin: 0 }}>
                                                <Input accept="image/*" id="contained-button-file-company-logo" type="file" onChange={(e) => uploadImage(e, 'comImage')} name="company_logo" sx={{ display: 'none' }} />
                                                <MDButton fullWidth type="button" variant="contained" color="success" component="span" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minHeight: '2.7rem' }}>
                                                    Browse
                                                </MDButton>
                                            </label>
                                        </Stack>
                                        {company_image ? <Avatar
                                            alt={URL_FILE + company_image}
                                            src={URL_FILE + company_image}
                                            sx={{ width: 100, height: 56, margin: 'auto', marginTop: '1rem', borderRadius: 0 }}
                                        /> : ''}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <TextField
                                                fullWidth
                                                label="Photo Cover"
                                                id="photo_cover"
                                                name="photo_cover"
                                                type="text"
                                                autoComplete="photo_cover"
                                                value={photoCover}
                                                InputLabelProps={{ shrink: true, }}
                                                inputProps={{ readOnly: true, }}
                                                sx={{ '& .MuiInputBase-root': { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                                            />
                                            <label htmlFor="contained-button-file-photo-cover" style={{ margin: 0 }}>
                                                <Input accept="image/*" id="contained-button-file-photo-cover" type="file" name="photo_cover" onChange={(e) => uploadImage(e, 'photoCover')} sx={{ display: 'none' }} />
                                                <MDButton fullWidth type="button" variant="contained" color="success" component="span" sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, minHeight: '2.7rem' }}>
                                                    Browse
                                                </MDButton>
                                            </label>
                                        </Stack>
                                        {photoCover ? <Avatar
                                            alt={URL_FILE + photoCover}
                                            src={URL_FILE + photoCover}
                                            sx={{ width: 100, height: 56, margin: 'auto', marginTop: '1rem', borderRadius: 0 }}
                                        /> : ''}
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb={4}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6'>Footer Section</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <Typography sx={{ fontSize: '0.75rem' }}>Join Us Online</Typography>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="join_us_online"
                                                    name="join_us_online"
                                                    value={formik.values.join_us_online}
                                                    checked={!!formik.values.join_us_online}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9}></Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Facebook"
                                                    id="facebook_url"
                                                    name="facebook_url"
                                                    value={formik.values.facebook_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.facebook_url && Boolean(formik.errors.facebook_url)}
                                                    helperText={formik.touched.facebook_url && formik.errors.facebook_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_facebook"
                                                    name="is_show_facebook"
                                                    value={formik.values.is_show_facebook}
                                                    checked={!!formik.values.is_show_facebook}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Linkedin"
                                                    id="linkedin_url"
                                                    name="linkedin_url"
                                                    value={formik.values.linkedin_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.linkedin_url && Boolean(formik.errors.linkedin_url)}
                                                    helperText={formik.touched.linkedin_url && formik.errors.linkedin_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_linkedin"
                                                    name="is_show_linkedin"
                                                    value={formik.values.is_show_linkedin}
                                                    checked={!!formik.values.is_show_linkedin}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Twitter"
                                                    id="twitter_url"
                                                    name="twitter_url"
                                                    value={formik.values.twitter_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.twitter_url && Boolean(formik.errors.twitter_url)}
                                                    helperText={formik.touched.twitter_url && formik.errors.twitter_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_twitter"
                                                    name="is_show_twitter"
                                                    value={formik.values.is_show_twitter}
                                                    checked={!!formik.values.is_show_twitter}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Instagram"
                                                    id="instagram_url"
                                                    name="instagram_url"
                                                    value={formik.values.instagram_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.instagram_url && Boolean(formik.errors.instagram_url)}
                                                    helperText={formik.touched.instagram_url && formik.errors.instagram_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_instagram"
                                                    name="is_show_instagram"
                                                    value={formik.values.is_show_instagram}
                                                    checked={!!formik.values.is_show_instagram}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Youtube"
                                                    id="youtube_url"
                                                    name="youtube_url"
                                                    value={formik.values.youtube_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.youtube_url && Boolean(formik.errors.youtube_url)}
                                                    helperText={formik.touched.youtube_url && formik.errors.youtube_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_youtube"
                                                    name="is_show_youtube"
                                                    value={formik.values.is_show_youtube}
                                                    checked={!!formik.values.is_show_youtube}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Google+"
                                                    id="google_plus_url"
                                                    name="google_plus_url"
                                                    value={formik.values.google_plus_url}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.google_plus_url && Boolean(formik.errors.google_plus_url)}
                                                    helperText={formik.touched.google_plus_url && formik.errors.google_plus_url}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_google_plus"
                                                    name="is_show_google_plus"
                                                    value={formik.values.is_show_google_plus}
                                                    checked={!!formik.values.is_show_google_plus}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Typography sx={{ fontSize: '0.75rem' }}>Company Details</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9}></Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Company Name"
                                                    id="companyName"
                                                    name="companyName"
                                                    value={formik.values.companyName}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_name"
                                                    name="is_show_name"
                                                    value={formik.values.is_show_name}
                                                    checked={!!formik.values.is_show_name}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Company Address"
                                                    id="address"
                                                    name="address"
                                                    value={formik.values.address}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_address"
                                                    name="is_show_address"
                                                    value={formik.values.is_show_address}
                                                    checked={!!formik.values.is_show_address}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Company Website"
                                                    id="website"
                                                    name="website"
                                                    value={formik.values.website}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.website && Boolean(formik.errors.website)}
                                                    helperText={formik.touched.website && formik.errors.website}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_website"
                                                    name="is_show_website"
                                                    value={formik.values.is_show_website}
                                                    checked={!!formik.values.is_show_website}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Company Email"
                                                    id="email"
                                                    name="email"
                                                    value={formik.values.email}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                    helperText={formik.touched.email && formik.errors.email}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_email"
                                                    name="is_show_email"
                                                    value={formik.values.is_show_email}
                                                    checked={!!formik.values.is_show_email}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <TextField
                                                    fullWidth
                                                    label="Company Phone Number"
                                                    id="phone_number"
                                                    name="phone_number"
                                                    value={formik.values.phone_number}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_phone_number"
                                                    name="is_show_phone_number"
                                                    value={formik.values.is_show_phone_number}
                                                    checked={!!formik.values.is_show_phone_number}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={4}></Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <Typography sx={{ fontSize: '0.75rem' }}>Terms and Conditions Link</Typography>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_terms_conditions_link"
                                                    name="is_show_terms_conditions_link"
                                                    value={formik.values.is_show_terms_conditions_link}
                                                    checked={!!formik.values.is_show_terms_conditions_link}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3}>
                                        <Grid container spacing={1}>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <Typography sx={{ fontSize: '0.75rem' }}>Privacy Policies Link</Typography>
                                            </Grid>
                                            <Grid item sx={{ alignSelf: 'center' }}>
                                                <FormControlLabel
                                                    control={<Checkbox />}
                                                    id="is_show_privacy_policies"
                                                    name="is_show_privacy_policies"
                                                    value={formik.values.is_show_privacy_policies}
                                                    checked={!!formik.values.is_show_privacy_policies}
                                                    onChange={formik.handleChange}
                                                    sx={{ '& .MuiSvgIcon-root': { width: '2rem !important', height: '2rem !important' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container px={4} py={2} mt={1} justifyContent="flex-end" sx={{ backgroundColor: '#fff', boxShadow: '0 0px 5px -2px gray!important' }}>
                        <Grid item xs={12} md={3} lg={2}>
                            <MDButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="info"
                                sx={{ fontWeight: 500 }}
                                disabled={disabledBtn}
                            >
                                Save
                            </MDButton>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default EditMaster;