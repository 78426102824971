import api from "../xhr";
import endPoint, { getURL } from "../xhr/endPoint";

export const getSolutions = async () => {
  return await api.getMethod(getURL(endPoint.SOLUTION_PLAN_SOLUTIONS));
};
export const getSolution = async (id) => {
  return await api.getMethod(
    getURL(endPoint.SOLUTION_PLAN_SOLUTION_DETAIL + id)
  );
};

export const createSolution = async (data) => {
  return await api.postMethod(
    getURL(endPoint.SOLUTION_PLAN_STORE_SOLUTION),
    data
  );
};

export const updateSolution = async (id, data) => {
  return await api.putMethod(
    getURL(endPoint.SOLUTION_PLAN_UPDATE_SOLUTION + id),
    data
  );
};

export const deleteSolution = async (id) => {
  return await api.deleteMethod(
    getURL(endPoint.SOLUTION_PLAN_DELETE_SOLUTION + id)
  );
};

export const createPlan = async (data) => {
  return await api.postMethod(getURL(endPoint.SOLUTION_PLAN_STORE_PLAN), data);
};

export const updatePlan = async (id, data) => {
  return await api.putMethod(
    getURL(endPoint.SOLUTION_PLAN_UPDATE_PLAN + id),
    data
  );
};

export const deletePlan = async (id) => {
  return await api.deleteMethod(
    getURL(endPoint.SOLUTION_PLAN_DELETE_PLAN + id)
  );
};
