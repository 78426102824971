import { TextField, Box, Grid, Typography, Stack, Link, InputAdornment } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { postAuthentication, postAuthenticationWithCode } from 'modules/master_login/repositories';
import MDButton from 'components/MDButton';
import Swal from "sweetalert2";
import ModalForgotPassword from 'modules/master_login/components/modalForgotPassword';
import OtpInput from 'react18-input-otp';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function FormLogin() {
    const navigate = useNavigate();
    React.useEffect(() => {
        if (localStorage.getItem('access_token')) {
            return navigate('/dashboard');
        }
    });

    const cssInput = {
        marginTop: 1,
        marginBottom: 1,
        borderRadius: '20px',
        '& > label': {
            top: 0,
            left: 0,
            color: 'gray',
            '&[data-shrink="false"]': {
                top: 5
            }
        },
        '& > div > input': {
            padding: '20px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    };

    const styleOTP = {
        width: '100%',
        padding: '20px',
        font: 'inherit',
        border: '1px solid #d2d6da',
        fontSize: '0.875rem',
        borderRadius: '0.375rem',
        margin: '10px 0',
    }

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errEmail, setErrEmail] = React.useState(false);
    const [txtErrEmail, setTxtErrEmail] = React.useState('');
    const [errPassword, setErrPassword] = React.useState(false);
    const [txtErrPassword, setTxtErrPassword] = React.useState('');
    const [otp, setOTP] = React.useState('');
    const [nextBtn, setNextBtn] = React.useState(true);
    const [disabledBtn, setDisabledBtn] = React.useState(false);
    const handleChangeOTP = (e) => setOTP(e);
    const clearOTP = () => setOTP('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const swalModal = (type, message) => {
        return Swal.fire({
            icon: type,
            text: message,
            showConfirmButton: false,
            timer: 2000
        });
    }

    const nextStep = async (event) => {
        event.preventDefault();
        if (email === '') {
            setErrEmail(true);
            setTxtErrEmail('Please enter the email');
        } else if (!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) {
            setErrEmail(true);
            setTxtErrEmail('Please use your email address');
        } else if (password === '') {
            setErrPassword(true);
            setTxtErrPassword('Please enter the password');
        }
        else {
            setErrEmail(false);
            setTxtErrEmail('');
            setErrPassword(false);
            setTxtErrPassword('');
            try {
                const data = {
                    email: email,
                    password: password
                }
                setDisabledBtn(true);
                const dataRes = await postAuthentication(data);
                if (dataRes?.data?.meta?.status === 200) {
                    setNextBtn(false);
                    setDisabledBtn(false);
                    setOpenAlert(false);
                    swalModal('success', 'Get verify code in your email');
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    setNextBtn(true);
                    setDisabledBtn(false);
                    setOpenAlert(true);
                    setErrEmail(true);
                    setErrPassword(true);
                }
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (otp.length === 5) {
            try {
                const data = {
                    email: email,
                    password: password,
                    verify_code: otp,
                }
                setDisabledBtn(true);
                const dataRes = await postAuthenticationWithCode(data);
                if (dataRes?.data?.response) {
                    setDisabledBtn(false);
                    localStorage.setItem('user', JSON.stringify(dataRes?.data?.response));
                    localStorage.setItem('access_token', dataRes?.data?.response?.access_token);
                    navigate('/dashboard');
                    return;
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    setDisabledBtn(false);
                    swalModal('error', 'The code you entered is incorrect. Please try again');
                }
            }
        } else {
            swalModal('error', 'Verification of code must be 5 characters');
        }
    };

    const getNewCode = async (e) => {
        e.preventDefault();
        try {
            const data = {
                email: email,
                password: password
            }
            const dataRes = await postAuthentication(data);
            if (dataRes?.data?.meta?.status === 200) {
                setNextBtn(false);
                return Swal.fire({
                    icon: 'success',
                    text: 'Reset code has been sent to your email. Please check your email for the new codes',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                setNextBtn(false);
                return Swal.fire({
                    icon: 'error',
                    text: 'Please try again after 30s',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }
    }

    return (
        <Box noValidate component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} width={"100%"}>
            <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, margin: 'auto' }} justifyContent="space-around" display={nextBtn ? '' : 'none'}>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#000'
                            }}
                            gutterBottom
                        >
                            Enter your email
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errEmail}
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={(event) => setEmail(event.target.value)}
                        sx={cssInput}
                        helperText={txtErrEmail}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#000'
                            }}
                            gutterBottom
                        >
                            Enter your password
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        error={errPassword}
                        margin="normal"
                        fullWidth
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="password"
                        onChange={(event) => setPassword(event.target.value)}
                        sx={cssInput}
                        helperText={txtErrPassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, margin: 'auto' }} justifyContent="space-around" display={!nextBtn ? '' : 'none'}>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                color: '#000'
                            }}
                            gutterBottom
                        >
                            Please enter the code
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <OtpInput
                        value={otp}
                        onChange={handleChangeOTP}
                        numInputs={5}
                        separator={<span>&nbsp;</span>}
                        inputStyle={styleOTP}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, justifyContent: { xs: 'space-around', md: 'space-around', lg: 'space-around' }, margin: 'auto', mt: 1, mb: 3 }}>
                <Grid item>
                    {nextBtn ? (
                        <MDButton variant="contained" color="success" sx={{ width: '7rem', mb: 1, mr: 2 }} onClick={nextStep} disabled={disabledBtn}>
                            Next
                        </MDButton>
                    ) : (
                        <MDButton type="submit" variant="contained" color="success" sx={{ width: '7rem', mb: 1, mr: 2 }} disabled={disabledBtn}>
                            Next
                        </MDButton>
                    )
                    }
                </Grid>
                <Grid item alignSelf={'center'}>
                    {nextBtn ? (
                        <ModalForgotPassword />
                    ) : (
                        <MDButton variant="contained" sx={{ mb: 1, fontSize: '14px', fontWeight: 500, color: '#344767' }} onClick={clearOTP} disabled={!otp ? true : false}>
                            Clear Code
                        </MDButton>
                    )
                    }
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={openAlert}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mt: 2, fontSize: 14 }}
                            variant="filled" severity="error"
                        >
                            Email or password is incorrect, please try again
                        </Alert>
                    </Collapse>
                </Grid>
            </Grid>
            <Grid container sx={{ width: { xs: '100%', md: '60%', lg: '60%' }, justifyContent: 'flex-start', margin: 'auto', mt: 1, mb: 3 }}>
                {!nextBtn ? (<div style={{ fontSize: '14px' }}>If you haven't received, <Link href="#" onClick={getNewCode} sx={{ color: 'blue', fontWeight: 500 }}> get new code </Link></div>) : ''}
            </Grid>
        </Box >
    );
}

export default FormLogin;