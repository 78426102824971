const PERMISSION_OPTIONS = [
  {
    id: 11,
    name: "Platform User",
    slug: "platform-user",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-22T05:04:53.000000Z",
    updated_at: "2022-12-23T02:10:19.000000Z",
  },
  {
    id: 20,
    name: "Invoice",
    slug: "invoice",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:12:03.000000Z",
    updated_at: null,
  },
  {
    id: 21,
    name: "CRM",
    slug: "crm",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:12:16.000000Z",
    updated_at: null,
  },
  {
    id: 22,
    name: "Booking Management",
    slug: "booking-management",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:12:34.000000Z",
    updated_at: null,
  },
  {
    id: 23,
    name: "Permission and Role",
    slug: "permission-and-role",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:13:05.000000Z",
    updated_at: null,
  },
  {
    id: 24,
    name: "Subscriber System Admininstrator",
    slug: "subscriber-system-admininstrator",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:16:05.000000Z",
    updated_at: null,
  },
  {
    id: 26,
    name: "Solution and Plan Access",
    slug: "solution-and-plan-access",
    url_used: '["platform_user"]',
    created_by: {
      id: 2,
      surname: "Carroll",
      firstname: "Thuy",
      email: "thuy@tweetworldtravel.com.au",
      roles: 1,
      send_verify_code_at: null,
      created_by: 1,
      verify_code: null,
      is_active: 1,
      forget_password_token: "8b8329d2-2e7d-4f3f-b044-e4aade3b3351",
      send_forget_password_at: "2022-12-21 05:47:51",
      created_at: "2022-12-19T06:35:53.000000Z",
      updated_at: "2022-12-23T06:11:48.000000Z",
    },
    created_at: "2022-12-23T02:19:40.000000Z",
    updated_at: null,
  },
];

export default PERMISSION_OPTIONS;
