import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropZone from "../components/dropZone";
import ModalDetailMenu from "../components/modalDetailMenu";
import TreeItemMenu from "../components/treeItemMenu";
import useAccessManage from "./hook";

const MenuManageLayout = ({
  menu,
  parrentOptions,
  handleGetMenu,
  handleMoveMenu,
  handleDrop,
}) => {
  const handleRenderTree = useCallback(
    (menu, lever = 0, parentPath = "") => {
      return (
        <>
          <DropZone
            onDrop={handleDrop}
            dropZoneData={{
              lever: lever,
              index: 0,
              parentPath: parentPath,
            }}
          />
          {menu.map((item, index) => (
            <Box key={item.id}>
              <TreeItemMenu
                item={item}
                lever={lever}
                index={index}
                currentPath={parentPath + `[${index}]`}
                parentPath={parentPath}
                handleRenderTree={handleRenderTree}
                parrentOptions={parrentOptions}
                handleGetMenu={handleGetMenu}
                handleMoveMenu={handleMoveMenu}
                handleDrop={handleDrop}
              />
              <DropZone
                key={"dropzone" + item.id}
                onDrop={handleDrop}
                dropZoneData={{
                  lever: lever,
                  index: index + 1,
                  parentPath: parentPath,
                }}
              />
            </Box>
          ))}
        </>
      );
    },
    [handleDrop, handleGetMenu, handleMoveMenu, parrentOptions]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                borderRadius: "0.75rem",
                boxShadow: "unset",
              }}
            >
              <ModalDetailMenu
                parrentOptions={parrentOptions}
                parentCallback={handleGetMenu}
              />
              <Box sx={{ mt: 2 }}>
                <DndProvider backend={HTML5Backend}>
                  {handleRenderTree(menu)}
                </DndProvider>
              </Box>
            </Paper>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

const AccessManage = (props) => (
  <MenuManageLayout {...useAccessManage(props)} />
);

export default AccessManage;
