import Axios from "axios";

const createAxiosInstance = (configHeader) => {
    return Axios.create({
        headers: { ...configHeader }
    });
}

export async function get(url, params = {}) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.get(url);
    return request;
}

export async function post(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.post(url, data);
    return request;
}

export async function put(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.put(url, data);
    return request;
}

export async function deleteData(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let request = await axios.delete(url, data);
    return request;
}

export async function postUpload(url, data) {
    const axios = createAxiosInstance({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    return await axios.post(url, data);
}