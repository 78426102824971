import { HelpOutlineOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import * as Roles from "modules/role_permissions/roles/repositories";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PERMISSION_OPTIONS from "../data/permissionOptions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
};

function ModalRole({ role, parentCallback }) {
  const [open, setOpen] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const swalModal = (type, message) => {
    return Swal.fire({
      icon: type,
      text: message,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Please enter the name";
    } else if (!/^[A-Za-z0-9 _]+$/i.test(values.name)) {
      errors.name = "Accept numbers and letters";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: role?.name || "",
      ...(role?.permission_access || []).reduce(
        (permissionAccess, { permission_id, access }) => {
          JSON.parse(access).forEach((accessItem) => {
            permissionAccess[`${permission_id}-${accessItem}`] = ["on"];
          });
          return permissionAccess;
        },
        {}
      ),
    },
    validate,
    onSubmit: async ({ name, ...accessList }) => {
      const data = {
        name: name,
        permission_access: Object.keys(accessList)
          .filter((accessKey) => accessList[accessKey].length)
          .map((accessKey) => {
            const [permissionId, permissionAccess] = accessKey.split("-");
            return {
              id: permissionId,
              access: [permissionAccess],
            };
          }),
      };

      try {
        setDisabledBtn(true);
        if (role) {
          const dataRes = await Roles.putUpdateRole(role?.id, data);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Update role success");
          }
        } else {
          const dataRes = await Roles.postCreateRole(data);
          if (dataRes?.data?.response) {
            setDisabledBtn(false);
            handleClose();
            parentCallback(dataRes?.data?.meta?.status);
            swalModal("success", "Create role success");
          }
        }
      } catch (e) {
        setDisabledBtn(false);
        if (e?.response?.status === 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("user");
          return navigate("/login");
        }
      }
    },
  });

  return (
    <div>
      {role ? (
        <IconButton color="info" onClick={handleOpen}>
          <FolderIcon />
        </IconButton>
      ) : (
        <MDButton
          fullWidth
          variant="contained"
          color="info"
          sx={{ fontWeight: 500, width: "max-content" }}
          onClick={handleOpen}
        >
          Add Role
        </MDButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          role="form"
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h4" component="h2">
              Role
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid
            justifyContent={"center"}
            sx={{
              mt: 2,
              mb: 2,
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Name(*)"
                id="name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid sx={{ flex: 1, overflow: "auto" }}>
              <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 500 }}
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 500 }}
                      >
                        <Typography variant="h6" component="h6" noWrap>
                          View
                          <Tooltip title="View description...">
                            <IconButton>
                              <HelpOutlineOutlined sx={{ color: "#344767" }} />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 500 }}
                      >
                        <Typography variant="h6" component="h6" noWrap>
                          Update
                          <Tooltip title="Update description...">
                            <IconButton>
                              <HelpOutlineOutlined sx={{ color: "#344767" }} />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontWeight: 500 }}
                      >
                        <Typography variant="h6" component="h6" noWrap>
                          Manage
                          <Tooltip title="Manage description...">
                            <IconButton>
                              <HelpOutlineOutlined sx={{ color: "#344767" }} />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {PERMISSION_OPTIONS.map(({ id, name, slug }) => (
                      <TableRow
                        key={id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: 500 }}
                        >
                          {name}
                        </TableCell>
                        <TableCell align="right">
                          <FormControlLabel
                            control={<Checkbox />}
                            labelPlacement="end"
                            name={`${id}-1`}
                            checked={!!formik.values[`${id}-1`]?.length}
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormControlLabel
                            control={<Checkbox />}
                            name={`${id}-2`}
                            checked={!!formik.values[`${id}-2`]?.length}
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormControlLabel
                            control={<Checkbox />}
                            name={`${id}-3`}
                            checked={!!formik.values[`${id}-3`]?.length}
                            onChange={formik.handleChange}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid container pt={3} justifyContent="center">
            <Grid item xs={12} md={3} lg={2}>
              <MDButton
                type="submit"
                fullWidth
                variant="contained"
                color="info"
                sx={{ fontWeight: 500 }}
                disabled={disabledBtn}
              >
                Save
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalRole;
