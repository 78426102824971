import React from 'react';
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
import 'components/NTTextEditor/style.css';

const NTTextEditor = () => {
  const theme = 'snow';
  // const theme = 'bubble';

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      // [{ align: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  const placeholder = 'Type here...';
  // IF USER FORMAT
  // const formats = ['bold', 'italic', 'underline', 'strike'];
  // const { quillRef } = useQuill({ theme, modules,formats, placeholder });
  const { quillRef } = useQuill({ theme, modules, placeholder });
  return (
    <>
      <div ref={quillRef} />
    </>
  )
}

export default NTTextEditor