import UrlPattern from "url-pattern";

const API_URL = process.env.REACT_APP_API_URL;

const endPoint = {
    'CONTACT_LIST': 'contact',
    'CONTACT_STORE': 'contact/store',
    'CONTACT_UPDATE': 'contact/update/',
    'CONTACT_SHOW': 'contact/show/',
    'CONTACT_DELETE': 'contact/delete/',
    'CONTACT_NOTE': 'contact/note/',
    'CONTACT_LOG': 'contact/logs/',
    'CONTACT_DATA_COMPANY_SELECT': 'company/pluck',
    'CONTACT_SEND_EMAIL': 'contact/send-email/',
    'CONTACT_FETCH_EMAIL': 'contact/fetch-email/',
    'CONTACT_CREATE_USER': 'user/store',
}

export const getURL = (name, routeParams = {}, queryParam = {}) => {
    const pattern = new UrlPattern(`${name}`);
    let url = new URL(`${API_URL}${pattern.stringify(routeParams)}`);
    let mappingParams = new URLSearchParams();
    return `${url.origin}${url.pathname}?${mappingParams}`;
}

export default endPoint;
